import { combineReducers } from '@reduxjs/toolkit'
import { authSlice } from '@/redux/ducks/authDuck'
import { accountSlice } from '@/redux/ducks/accountDuck'
import settingsSlice from '@/redux/ducks/settingsDuck'
import errorSlice from '@/redux/error/reducers'
import iconSlice from '@/redux/icon/iconReducers'
import uiSlice from '@/redux/ducks/uiDuck'
import paymentSlice from '@/redux/ducks/paymentDuck'
import serviceRequestSlice from './ducks/serviceRequestDuck'

const appReducer = combineReducers({
  accounts: accountSlice.reducer,
  authentication: authSlice.reducer,
  settings: settingsSlice.reducer,
  error: errorSlice.reducer,
  icon: iconSlice.reducer,
  ui: uiSlice.reducer,
  payment: paymentSlice.reducer,
  serviceRequest: serviceRequestSlice.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'authentication/logout') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const useAuthApi = authSlice.actions
export const useSettingsApi = settingsSlice.actions
export const useAccountApi = accountSlice.actions
export const useIconApi = iconSlice.actions
export const useUiApi = uiSlice.actions
export const usePaymentApi = paymentSlice.actions
export const serviceRequestActions = serviceRequestSlice.actions

export { rootReducer }
