import React from 'react'
import PropTypes from 'prop-types'
import { PayLinkComponent } from './PayLinkComponent'

const ScheduleLaterLinkComponent = ({ onClick }) => {
  return (
    <>
      <PayLinkComponent
        flavorText="Want to pay later?"
        linkText="Schedule a payment up to 30 days in advance."
        onClick={onClick}
      />
    </>
  )
}

ScheduleLaterLinkComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export { ScheduleLaterLinkComponent }
