import React from 'react'
import styled from 'styled-components'
import { WaterDroplet } from '@/components/animations/waterdroplet/WaterDroplet'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12em;
`

const LoadingText = styled.h2`
  text-align: center;
  font-family: 'Nunito';
  margin-top: 45px;
  color: #3250b3;
`

const LoadingAnimation = () => {
  return (
    <Wrapper>
      <WaterDroplet />
      <LoadingText>Loading</LoadingText>
    </Wrapper>
  )
}

export { LoadingAnimation }
