import React from 'react'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import './styles.scss'
import Skeleton from 'react-loading-skeleton'
import { OBJECT_VARS } from '@/utils/models'
import { useConsumptions } from '@/hooks/useConsumption.queries'
import { asArray } from '@/utils/utils'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Button } from '@progress/kendo-react-buttons'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { Input } from '@progress/kendo-react-inputs'
import { process } from '@progress/kendo-data-query'
import amiLogo from '@/images/AmiLogo.png'
import { useMultiplePayments } from '../../../hooks/usePayments.queries'

const TableView = () => {
  const paymentsQuery = useMultiplePayments()
  const isLoading = paymentsQuery.some((result) => result.isLoading)
  const accounts = useAccounts()
  const useBuildConsumptionTableData = (consumptions) => {
    if (accounts.isLoading || isLoading) return null
    const data = consumptions.reduce((result, usage) => {
      const key = usage[OBJECT_VARS.accountKey]
      const account = accounts.data.find((el) => el[OBJECT_VARS.accountKey] === key)
      const payments = paymentsQuery.filter((el) => el.isSuccess === true).map((el) => el.data[0])

      const payment = payments.find((el) => el[OBJECT_VARS.accountKey] === key)
      const lastRecord = usage.records.length > 0 ? usage.records[usage.records.length - 1] : []
      const usageGallons = usage.records.length > 0 ? lastRecord[OBJECT_VARS.usageGallons] : 0
      return {
        ...result,
        [key]: {
          id: key,
          [OBJECT_VARS.accountNumber]: account[OBJECT_VARS.accountNumber],
          [OBJECT_VARS.nickName]: account[OBJECT_VARS.nickName],
          [OBJECT_VARS.serviceAddress]: `${account[OBJECT_VARS.serviceAddress]} ${
            account[OBJECT_VARS.subDesignation]
          }`,
          [OBJECT_VARS.accountStatus]: payment[OBJECT_VARS.accountStatus],
          [OBJECT_VARS.lastmonth]: usageGallons,
          [OBJECT_VARS.isAmiEnabled]: account[OBJECT_VARS.isAmiEnabled] === 'True',
        },
      }
    }, {})
    return asArray(data)
  }

  const [filterValue, setFilterValue] = React.useState()

  const query = useConsumptions()
  const consumptionRecords = query.filter((el) => el.isSuccess === true).map((el) => el.data)
  const consumptionsLoading = query.some((result) => result.isLoading)

  const _export = React.useRef(null)

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save()
    }
  }
  const [filteredSampleProducts, setFilteredSampleProducts] = React.useState([])

  const [initialDataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
  })
  const [dataResult, setDataResult] = React.useState(
    process(filteredSampleProducts, initialDataState)
  )

  const [data, setData] = React.useState([])

  React.useEffect(() => {
    if (consumptionsLoading && isLoading && accounts.isLoading) return undefined
    const data = useBuildConsumptionTableData(consumptionRecords)
    if (data !== null) {
      setData(data)
      setFilteredSampleProducts(data)
      setDataResult(process(data, initialDataState))
    }
  }, [consumptionsLoading, isLoading, accounts.isLoading])

  const FormatAmi = (props) => {
    return (
      <td style={{ justifyContent: 'center' }}>
        {props.dataItem[OBJECT_VARS.isAmiEnabled] ? (
          <>
            <div className="col-12" style={{ display: 'flex' }}>
              <img
                src={amiLogo}
                alt="AMI account logo"
                style={{
                  height: '23px',
                  width: '50px',
                  top: '-5px',
                  right: '5px',
                  paddingLeft: '20px',
                }}
              />
            </div>
          </>
        ) : (
          ''
        )}
      </td>
    )
  }

  const FormatAddress = (props) => {
    return (
      <td>
        {props.dataItem[OBJECT_VARS.serviceAddress]} {props.dataItem[OBJECT_VARS.subDesignation]}
      </td>
    )
  }
  const FormatAccountStatus = (props) => {
    const status = props.dataItem[OBJECT_VARS.accountStatus]
    return (
      <td
        style={
          status === 'Past Due' || status === 'Delinquent' ? { color: 'red' } : { color: 'green' }
        }
      >
        {status}
      </td>
    )
  }

  const onFilterChange = (ev) => {
    const { value } = ev
    setFilterValue(ev.value)
    const newData = data.filter((item) => {
      let match = false
      for (const property in item) {
        if (
          item[property]?.toString().toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true
        }
        // ensure that toLocaleDateString matches with the displayed format in the Column
        // if not, modify the logic so that you can compare same string from the cell with the input
        if (
          item[property]?.toLocaleDateString &&
          item[property]?.toLocaleDateString().indexOf(value) >= 0
        ) {
          match = true
        }
      }
      return match
    })
    setFilteredSampleProducts(newData)
    const clearedPagerDataState = { ...initialDataState, take: 10, skip: 0 }
    const processedData = process(newData, clearedPagerDataState)
    setDataResult(processedData)
    setDataState(clearedPagerDataState)
  }
  const getHighlight = (value, filter) => {
    const index = value.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase())
    if (index >= 0) {
      const left = value.substr(0, index)
      const right = value.substring(index + filter.length, value.length)
      return (
        <>
          {left}
          <span className="highligth">{value.substr(index, filter.length)}</span>
          {getHighlight(right, filter)}
        </>
      )
    }
    return value
  }

  const dataStateChange = (event) => {
    setDataResult(process(filteredSampleProducts, event.dataState))
    setDataState(event.dataState)
  }
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded
    event.dataItem.expanded = !isExpanded

    setDataResult({ ...dataResult })
  }

  return isLoading ? (
    <div style={{ height: '200px', width: '600px' }}>
      <Skeleton count={10} />
    </div>
  ) : (
    <>
      <ExcelExport data={data} ref={_export}>
        <Grid
          data={dataResult}
          {...initialDataState}
          onDataStateChange={dataStateChange}
          pageable
          sortable
          onExpandChange={expandChange}
        >
          <GridToolbar>
            <div>
              <span>
                <Input
                  style={{ width: '250px' }}
                  value={filterValue}
                  onChange={onFilterChange}
                  placeholder="Search..."
                />
              </span>

              <Button
                title="Export Excel"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary ml-3"
                onClick={excelExport}
              >
                Export to Excel
              </Button>
            </div>
          </GridToolbar>
          <GridColumn field="nickName" title="Nickname" />
          <GridColumn field="serviceAddressLine" title="Address" cell={FormatAddress} />
          <GridColumn field="accountNumber" title="Account Number" />
          <GridColumn field="lastMonthUsage" title="Gallons Last Billed" />
          <GridColumn field="accountStatus" title=" Status" cell={FormatAccountStatus} />
          <GridColumn field="" width="150px" cell={FormatAmi} />
        </Grid>
      </ExcelExport>
    </>
  )
}

export { TableView }
