import React from 'react'
import styled from 'styled-components'
import { LoadingPageComponent } from '@/components/common/LoadingPageComponent'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { MoveOutRouter } from './MoveOutRouter'

const MoveOutPageWrapper = styled.div`
  display: block;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em 4em;
  width: 100%;
  height: 100%;
  min-height: 800px;
`

const MoveOutPageTitle = styled.h2`
  color: #4f4f4f;
  font-family: Nunito;
  font-weight: 700;
  font-size: 36px;
  align-self: flex-start;
`

const MoveOut = ({ history, location, match }) => {
  const { isLoading } = useAccounts()

  return (
    <>
      <MoveOutPageWrapper>
        <MoveOutPageTitle>Move Out</MoveOutPageTitle>
        {isLoading ? (
          <LoadingPageComponent />
        ) : (
          <MoveOutRouter routerProps={{ location, match, history }} />
        )}
      </MoveOutPageWrapper>
    </>
  )
}

export { MoveOut }
