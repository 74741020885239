/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import InputMask from 'react-input-mask'
import { IconComponent } from '@/components/common/IconComponent'
import { getFlexibleUnit } from '@/utils/flexUnit'

// Negative top margin accounts for icon size
const InputWrapper = styled.div`
  margin-top: ${({ icon }) => (icon ? '-22px' : '0')};
  margin-bottom: ${({ bottomGutter }) => getFlexibleUnit(bottomGutter)};
`

const StyledInput = styled(Form.Control)`
  color: inherit;
  padding: ${({ padding }) => padding};
  padding-left: ${({ icon }) => (icon ? '30px' : '15px')};

  &::placeholder {
    color: ${({ theme }) => theme.secondary};
  }

  &:focus {
    color: ${({ theme }) => theme.secondary};
    background: #fff;
  }

  &:disabled {
    cursor: not-allowed;
  }

  /* Chrome, Safari, Edge, Opera */
  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &&[type='number'] {
    -moz-appearance: textfield;
  }
`

const MaskInput = styled(InputMask)`
  color: #000;
  padding: 13px;
  font-size: 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  border-radius: 5px;
  width: 100%;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledLabel = styled(Form.Label)`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ theme, variant }) => theme[variant]};
`

const Label = ({ label, subtitle, variant, fontSize }) => {
  return (
    <LabelWrapper>
      <StyledLabel variant={variant} fontSize={fontSize} className="lbl-form">
        {label}
      </StyledLabel>
      {subtitle ? <StyledLabel subtitle>{subtitle}</StyledLabel> : null}
    </LabelWrapper>
  )
}

const Icon = styled(IconComponent)`
  position: relative;
  top: 31px;
  left: 10px;
  font-size: ${({ size }) => size};
  color: ${({ theme }) => theme.secondary};
`

const Input = ({ type, placeholder, icon, bottomGutter, padding, children, disabled, ...rest }) => {
  return (
    <InputWrapper icon={icon} bottomGutter={bottomGutter}>
      <Icon size="0.9em" icon={icon} />
      <StyledInput
        padding={padding}
        icon={icon}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
      {children}
    </InputWrapper>
  )
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  variant: PropTypes.string,
  fontSize: PropTypes.string,
}

Label.defaultProps = {
  subtitle: '',
  variant: 'primary',
  fontSize: '10px',
}

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  bottomGutter: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  padding: PropTypes.string,
  children: PropTypes.node,
}

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  icon: null,
  bottomGutter: 0,
  padding: 0,
  children: null,
}

export { Input, Label, MaskInput }
