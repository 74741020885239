import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import { TextareaAutosize } from '@material-ui/core'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { Input } from '@/components/common/Input'
import { MycButton } from '@/components/common/Button'
import emailBG from '@/images/who-we-are-sidebar.png'
import { ICONS } from '@/utils/icons'
import { IconComponent } from '@/components/common/IconComponent'
import { submitSalesforceContactUs } from '@/redux/api'
import { ContactInput } from './ContactInput'
import { InputTypes } from './InputTypes.ts'


const FormWrapper = styled.div`
  /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1)),
    url(${emailBG});*/
  background-size: cover;
  margin: 20px;
  text-align: center;
  color: white;
  padding-bottom: 10px;
`

const FormHeaderContainer = styled.div`
  background-color: rgba(36, 110, 185, 0.75);
  padding: 25px;
  font-weight: 600;
  font-size: 25px;
`

const FormHeaderText = styled.div``

const FormInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  padding: 15px;
`

const FormUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-right: 30px;
`

const LabelWrapper = styled.div`
  display: inline-block;
`

const LabelInt = styled.div`
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  color: ${({ theme }) => theme.primary};
  display: inline;
`

const Required = styled.div`
  color: #da690b;
  display: inline;
`

const FormHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

const MycInput = styled(Input)`
  width: 320px;
`

const StyledFormIcon = styled(IconComponent)`
  font-size: 60px;
  margin: 0 auto;
`

const StyledArrowFormIcon = styled(IconComponent)`
  font-size: 20px;
  margin: 0 10px;
  color: blue;
`

const FormIcon = () => {
  return <StyledFormIcon icon={ICONS.envelope} />
}

const ContactUsForm = () => {
  const { handleSubmit, errors, control, reset } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstname: '',
      lastname: '',
      mobilenumber: '',
      email: '',
      inforaccountnumber: '',
      serviceaddress: '',
    },
  })

  const [serviceAddressValue, setServiceAddressValue] = React.useState('')
  const [serviceAddressValue2, setServiceAddressValue2] = React.useState('')
  const [serviceAddressValue3, setServiceAddressValue3] = React.useState('')
  const [commentsValue, setCommentsValue] = React.useState('')
  const [callTypeValue, setCallTypeValue] = React.useState('')

  const formRef = React.useRef()

  const handleResetForm = () => {
    formRef.current.reset()
    setCommentsValue('')
    setCallTypeValue('')
    setServiceAddressValue('')
    setServiceAddressValue2('')
    setServiceAddressValue3('')
  }

  const postForm = useMutation(
    (formData) =>
      toast.promise(
        submitSalesforceContactUs(formData),
        {
          loading: 'Submitting your request',
          success: () => (
            <>
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <b style={{ color: 'blue' }}>Thank You!</b>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  If your question or comment requires additional follow-up, we will
                  contact you via email or phone during our scheduled support hours: Mon - Fri, 8
                  a.m. - 5 p.m. or Sat, 9 a.m. - 1 p.m.
                </div>
                <div style={{ padding: '25px', cursor: 'pointer' }} onClick={() => toast.dismiss()}><span><StyledArrowFormIcon icon={ICONS.arrowLeft}/></span>Back to portal</div>
              </div>
            </>
          ),
          error: 'Error when fetching',
        },
        {
          style: { border: 'blue', minWidth: '350px' },
          success: {
            style: { display: 'grid', gridTemplateRows: '1fr 5fr', background: 'white', color: '#000', minWidth: '350px' },
            duration: Infinity,
            iconTheme: {
              primary: 'green',
              secondary: 'grey',
            },
          },
        }
      ),
    {
      onSuccess: () => handleResetForm(),
    }
  )

  const onSubmit = (e) => {
    e.preventDefault()
    const form = formRef.current

    const contactForm = {
      firstname: form.firstname.value,
      lastname: form.lastname.value,
      mobilenumber: form.mobilenumber.value,
      email: form.email.value,
      address: serviceAddressValue3,
      accountnumber: serviceAddressValue2,
      comments: commentsValue,
      subject: callTypeValue,
      casetype: 'Inquiry',
      source: 'MYC',
    }

    postForm.mutate(contactForm)
  }
  return (
    <FormWrapper>
      <form onSubmit={(e) => onSubmit(e)} ref={formRef}>
        <FormHeaderContainer>
          <FormIcon />
          <FormHeaderText>Email Us</FormHeaderText>
        </FormHeaderContainer>
        <FormInputsContainer>
          <FormUserInfoContainer>
            <LabelWrapper>
              <LabelInt>First Name</LabelInt>
              <Required>* required</Required>
            </LabelWrapper>

            <MycInput
              type="text"
              name="firstname"
              required
              control={control}
              defaultValue=""
              placeholder="Type here..."
              style={{ color: 'black', width: '99%', height: '50px' }}
              className="inputField"
              isInvalid={errors.firstname && true}
            />
            <LabelWrapper>
              <LabelInt>Last Name</LabelInt>
              <Required>* required</Required>
            </LabelWrapper>

            <MycInput
              type="text"
              name="lastname"
              required
              defaultValue=""
              placeholder="Type here..."
              className="inputField"
              style={{ color: 'black', width: '99%', height: '50px' }}
              isInvalid={errors.lastname && true}
            />

            <LabelWrapper>
              <LabelInt>Contact Phone Number: 111-111-1111</LabelInt>
              <Required>* required</Required>
            </LabelWrapper>

            <MycInput
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              name="mobilenumber"
              required
              placeholder="Type here..."
              className="inputField form-control"
              style={{ backgroundColor: 'white', color: 'black', width: '99%', height: '50px' }}
              isInvalid={errors.mobilenumber && true}
              defaultValue=""
            />

            <LabelWrapper>
              <LabelInt>Your Email</LabelInt>
              <Required>* required</Required>
            </LabelWrapper>

            <MycInput
              type="email"
              required
              name="email"
              control={control}
              defaultValue=""
              placeholder="Type here..."
              className="inputField"
              style={{ color: 'black', width: '99%', height: '50px' }}
            />
            <ContactInput
              ContactInputForm={{
                label: 'Service Address',
                icon: ICONS.road,
                name: 'serviceaddress',
                type: InputTypes.CONTACTADDRESS,
                value: serviceAddressValue,
                setValue: setServiceAddressValue,
                setValue2: setServiceAddressValue2,
                setValue3: setServiceAddressValue3,
              }}
            />
          </FormUserInfoContainer>
          <FormHelpContainer>
            <ContactInput
              ContactInputForm={{
                label: 'How can we help you?',
                icon: ICONS.question,
                name: 'calltype',
                type: InputTypes.SELECT,
                value: callTypeValue,
                setValue: setCallTypeValue,
              }}
            />
            <LabelWrapper>
              <LabelInt>Your Comment/Concern</LabelInt>
              <Required>* required</Required>
            </LabelWrapper>
            <Controller
              render={() => (
                <TextareaAutosize
                  name="comments"
                  required
                  value={commentsValue}
                  onChange={(e) => setCommentsValue(e.target.value)}
                  rows="12"
                  rowsMax="40"
                />
              )}
              name="comments"
              control={control}
            />
          </FormHelpContainer>
        </FormInputsContainer>

        <MycButton
          gutterBottom={1}
          style={{
            padding: '10px 0',
            color: '#fff',
            width: '216px',
            height: '44px',
            marginTop: '10px',
            cursor: 'pointer',
            background: '#6d9f43',
          }}
          type="submit"
        >
          {postForm.isLoading ? 'SUBMITTING' : 'SUBMIT'}
        </MycButton>
      </form>
    </FormWrapper>
  )
}

export default ContactUsForm
