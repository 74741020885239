import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { OBJECT_VARS } from '@/utils/models'
import { asArray } from '@/utils/utils'
import { toast } from 'react-hot-toast'

const processServiceRequestDto = (formData, moveOutDate) =>
  asArray(formData).map((data) => ({
    moveOutDate,
    correlationId: '',
    transactionId: '',
    x52Id: data[OBJECT_VARS.userId],
    accountKey: data[OBJECT_VARS.accountKey],
    accountNumber: data[OBJECT_VARS.accountNumber],
    source: 'MYC',
    caseType: 'MoveOut',
    taxId: data[OBJECT_VARS.taxId],
    name: data.name,
    mobileNumber: data[OBJECT_VARS.mobile],
    jobTitle: data[OBJECT_VARS.jobTitle],
    phoneVerified: true,
    addressVerified: true,
    dobVerified: true,
    currentAddress: data.address.trim(),
    currentCity: data[OBJECT_VARS.city],
    currentState: data[OBJECT_VARS.state],
    currentZipCode: data[OBJECT_VARS.zip],
    currentCountry: data[OBJECT_VARS.country],
    transferringService: true,
    address: data.transferringToAddress.trim(),
    city: data.transferringToCity,
    state: data.transferringToState,
    zipCode: data.transferringToZipCode,
    country: data.transferringToCountry,
  }))

const SUBMIT_SALESFORCE_SERVICE_REQUEST = `${process.env.REACT_APP_API_BASE_URL}/servicerequest-public-api/public/api/salesforceservicerequest`

const submitSalesForceServiceRequest = (req) => {
  return fetchWrapper(SUBMIT_SALESFORCE_SERVICE_REQUEST, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(req),
    requiresInfor: true,
  })
}

const useSubmitServiceRequest = (options) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ addresses, moveOutDate }) =>
      toast.promise(
        submitSalesForceServiceRequest(processServiceRequestDto(addresses, moveOutDate)),
        {
          loading: 'Submitting your request. Please do not refresh the page.',
          success: () => (
            <>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <b>Thank You!</b>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  If necessary we will respond to you via email or phone on a case by case basis
                  during our support hours (Mon - Fri 8 a.m. - 5 p.m. and Sat 9 a.m. - 1 p.m.)
                </div>
              </div>
            </>
          ),
          error: 'Error when fetching',
        },
        {
          style: { border: 'green', minWidth: '350px' },
          success: {
            style: { background: 'green', color: '#ffffff', minWidth: '350px' },
            duration: 8000,
          },
        }
      ),
    {
      onSuccess: (res) => {
        res.map((account) => {
          return queryClient.invalidateQueries(['accountActivity', account[OBJECT_VARS.accountKey]])
        })
        options.onSuccess()
      },
    }
  )
}

export { useSubmitServiceRequest }
