import { createSlice } from '@reduxjs/toolkit'
import { takeLatest, put } from 'redux-saga/effects'
import {
  getActionName,
  isRequestAction,
  deselectAccounts,
  STATUS_FETCHING,
  STATUS_IDLE,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from './commonDuck'

const initialState = {
  shouldUpdateVisibleAccounts: false,
  visibleAccountIds: [],
  filter: [],
  visibleCount: 0,
  totalCount: 0,
  queryKey: null,
  payHistory: [],
  loaders: {},
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setShouldUpdateTotalCount: (state, action) => {
      state.shouldUpdateVisibleAccounts = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    resetLoaders: (state) => {
      state.loaders = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/logout', (state) => {
        state.loaders = {}
      })
      .addMatcher(isRequestAction, (state, action) => {
        const { type } = action
        const actionName = getActionName(type)
        if (!actionName) {
          return state
        }
        if (state.loaders[actionName] === undefined) {
          state.loaders[actionName] = {}
        }
        if (type.endsWith('Request')) {
          state.loaders[actionName].status = STATUS_FETCHING
        }

        if (type.endsWith('Success')) {
          state.loaders[actionName].status = STATUS_SUCCESS
          state.loaders[actionName].error = undefined
        }
        if (type.endsWith('Reset')) {
          state.loaders[actionName].status = STATUS_IDLE
          state.loaders[actionName].error = undefined
        }

        if (type.endsWith('Failure')) {
          state.loaders[actionName].status = STATUS_FAILURE
          state.loaders[actionName].error = {}
          state.loaders[actionName].error.hasError = true
          state.loaders[actionName].error.message = action.payload.message
          state.loaders[actionName].error.statusCode = action.payload.statusCode
        }
        return state
      })
  },
})

// SELECTORS //
const getIsFetching = (state, type) => {
  const actionName = getActionName(type)
  return state.ui.loaders[actionName]
    ? state.ui.loaders[actionName].status === STATUS_FETCHING
    : null
}
const getFetchSuccessful = (state, type) => {
  const actionName = getActionName(type)
  return state.ui.loaders[actionName]
    ? state.ui.loaders[actionName].status === STATUS_SUCCESS
    : null
}
const getError = (state, type) => {
  const actionName = getActionName(type)
  return state.ui.loaders[actionName] ? state.ui.loaders[actionName].error : null
}

function* workOnResetLoaders() {
  yield put({ type: deselectAccounts.type })
}

export function* watchResetLoaders() {
  yield takeLatest(uiSlice.actions.resetLoaders.type, workOnResetLoaders)
}

const uiSelectors = {
  getIsFetching,
  getFetchSuccessful,
  getError,
}

export { uiSelectors }
export default uiSlice
