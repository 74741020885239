import React from 'react'
import styled from 'styled-components'
import chatBG from '@/images/Background-3.jpg'
import { ICONS } from '@/utils/icons'
import { IconComponent } from '@/components/common/IconComponent'
import DropdownChat from './DropdownChat'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background-image: url(${chatBG});
  background-size: cover;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  min-height: 470px;
  position: relative;
  font-weight: 600;
  overflow: hidden;
  background-position: 50% 0;
  margin: 20px;
  min-height: 495px;
`

const ChatHeaderContainer = styled.div`
  background-color: rgba(36, 110, 185, 0.8);
  padding: 25px;
  font-weight: 600;
  font-size: 25px;
`

const ChatHeaderText = styled.div``

const ChatFooterButton = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  padding: 15px 0;
  cursor: pointer;
  z-index: 3;
  width: 100%;
  color: #fff;
  text-align: center;
  background: linear-gradient(to bottom, #0078ae, #0070a3);
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  text-transform: capitalize;
`

const StyledChatIcon = styled(IconComponent)`
  font-size: 60px;
  margin: 0 auto;
`

const ChatIcon = () => {
  return <StyledChatIcon icon={ICONS.chat} />
}
const ChatBox = () => {
  const [isOpenChat, setIsOpenChat] = React.useState(false)
  return (
    <Wrapper>
      <ChatHeaderContainer>
        <ChatIcon />
        <ChatHeaderText>Chat With Us</ChatHeaderText>
        <p style={{ fontSize: '15px' }}>
          Active: Mon - Fri, 8 a.m. - 5 p.m. or Sat, 9 a.m. - 1 p.m.
        </p>
      </ChatHeaderContainer>
      <DropdownChat open={isOpenChat} />
      <ChatFooterButton onClick={() => setIsOpenChat(!isOpenChat)}>
        START A CONVERSATION
      </ChatFooterButton>
    </Wrapper>
  )
}

export default ChatBox
