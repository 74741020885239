import React from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import { FaUniversity } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { usePaymentApi } from '@/redux/reducers'
import { OBJECT_VARS } from '@/utils/models'
import { getSelectedPaymentMethodId } from '@/redux/ducks/paymentDuck'
import { Input } from '@/components/common/Input'
import { LINKS } from '@/utils/sitemap'
import { usePaymentMethods } from '../Wallet/queries/usePaymentMethods.queries'

const TotalDivider = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 50%;
  border: 1px solid #000;
`

const AccountDivider = styled(TotalDivider)`
  margin: 10px 0 10px 0;
  width: 100%;
  border: 1px solid #000;
`

const InputWrapper = styled.div`
  margin-top: -22px; // To adjust for space left by relative position
  margin-bottom: 20px;

  & input {
    padding-left: 30px;
  }
`
const FormField = (fields) => {
  return (
    <>
      <Form.Label className="form-label">{fields.label}</Form.Label>
      <Input {...fields} disabled />
    </>
  )
}

const PaymentFormComponent = () => {
  const dispatch = useDispatch()
  const { data, isLoading } = usePaymentMethods()
  const selectedPaymentMethodId = useSelector(getSelectedPaymentMethodId)
  const paymentMethodsQuery = usePaymentMethods()
  const selectedPaymentMethod =
    paymentMethodsQuery.data &&
    paymentMethodsQuery.data.find((el) => el.id === selectedPaymentMethodId)
  React.useEffect(() => {
    if (!isLoading && data.length > 0) {
      dispatch(usePaymentApi.saveSelectedPaymentMethodId(data[0].id))
      dispatch(usePaymentApi.savePaymentMethod(data[0]))
    }
  }, [data, isLoading])

  const formData = [
    {
      name: OBJECT_VARS.nameOnAccount,
      label: 'name on account',
      defaultValue: selectedPaymentMethod ? selectedPaymentMethod[OBJECT_VARS.nameOnAccount] : '',
    },
    {
      name: OBJECT_VARS.accountNumber,
      label: 'bank account number',
      type: selectedPaymentMethod ? 'text' : 'number',
      defaultValue: selectedPaymentMethod
        ? `********${selectedPaymentMethod[OBJECT_VARS.last4digits]}`
        : '',
    },
    {
      name: OBJECT_VARS.routingNumber,
      label: 'routing number',
      type: 'number',
      defaultValue: selectedPaymentMethod ? selectedPaymentMethod[OBJECT_VARS.routingNumber] : '',
      rules: {
        maxLength: { value: 17, message: 'The routing number is too long' },
        required: { value: true, message: 'This field is required' },
      },
    },
    {
      name: OBJECT_VARS.accountType,
      label: 'Bank Account Type',
      defaultValue:
        (selectedPaymentMethod && selectedPaymentMethod[OBJECT_VARS.accountType]) || 'C',
      input: {
        select: {
          options: [
            { displayLabel: 'Checking', value: 'C', key: 'checkings' },
            { displayLabel: 'Saving', value: 'S', key: 'savings' },
          ],
        },
      },
    },
  ]

  return (
    <>
      <Form.Label className="form-label">Pay from account saved in wallet?</Form.Label>
      <InputWrapper>
        <FaUniversity className="bs-override-dropdown-input-icon" />
        <Form.Control
          as="select"
          size="lg"
          className="bs-override-dropdown"
          value={selectedPaymentMethodId}
          onChange={(e) => {
            dispatch(usePaymentApi.saveSelectedPaymentMethodId(e.target.value))
            const met =
              paymentMethodsQuery.data &&
              paymentMethodsQuery.data.find((el) => el.id === e.target.value)
            dispatch(usePaymentApi.savePaymentMethod(met))
          }}
        >
          {paymentMethodsQuery.data &&
            paymentMethodsQuery.data.map((method) => {
              return (
                <option style={{ color: '#495057' }} key={method.id} value={method[OBJECT_VARS.id]}>
                  {method[OBJECT_VARS.nickName] || method[OBJECT_VARS.issuingBank]}
                </option>
              )
            })}
        </Form.Control>
        <Link className="form-label" to={LINKS.wallet} style={{ color: '#3250B3' }}>
          Add a new payment method
        </Link>
      </InputWrapper>
      <Form.Label>Selected Payment Method Details</Form.Label>
      <AccountDivider />
      <Form>
        {formData.map((fields) => (
          // <FormField {...fields} />
          <div className="row">
            <div className="col-md-6 form-label">{fields.label}</div>
            <div className="col-md-6">{fields.defaultValue}</div>
          </div>
        ))}
      </Form>
    </>
  )
}

export { PaymentFormComponent }
