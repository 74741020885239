import React from 'react'
import { BankAccountDetailsComponent } from './BankAccountDetailsComponent'
import { PaymentMethodsList } from './PaymentMethodsList.component'
import { usePaymentMethods } from '../queries/usePaymentMethods.queries'

const PaymentMethodDetailsView = () => {
  const { data } = usePaymentMethods()
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState(null)

  React.useEffect(() => {
    if (!data) return undefined
    if (selectedPaymentMethodId === null && data.length > 0) {
      setSelectedPaymentMethodId(data[0].id)
    }
  }, [selectedPaymentMethodId, data])

  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6" style={{ 'max-height': '78vh', 'overflow-y': 'scroll' }}>
        <PaymentMethodsList setSelectedPaymentMethodId={setSelectedPaymentMethodId} />
      </div>
      <div className="col-xl-8 col-lg-6">
        {selectedPaymentMethodId !== null ? (
          <BankAccountDetailsComponent selectedPaymentMethodId={selectedPaymentMethodId} />
        ) : null}
      </div>
    </div>
  )
}

export { PaymentMethodDetailsView }
