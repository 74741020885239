import React from 'react'
import styled from 'styled-components'
import { Box } from '@/components/common/Box'
import BrokenPipe from '@/images/Broken_pipe.png'
import { MycButton } from '../../common/Button'

const NotFound = () => {
  const StyledContainer = styled(Box)`
        padding: 11em;
        background: '#fff'
        background-size: cover;
        min-height: 100%;
        display: flex;
        align-items: center;
    `
  const StyledImg = styled.img`
    height: 287px;
    width: 215px;
    left: -375px;
    top: 2528px;
    border-radius: 0px;
  `

  return (
    <StyledContainer>
      <div>
        <StyledImg alt="brokenpipe Logo" src={BrokenPipe} />
      </div>
      <div style={{ maxWidth: '400px', textAlign: 'center', position: 'relative', left: '58px' }}>
        <p style={{ fontSize: '40px', fontWeight: 'bold' }}>That Page Doesn't Exist!</p>
        <p style={{ fontSize: '13px' }}>
          We can’t find what you’re looking for. Try one of the links to the left or go{' '}
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Back to Home Page</span>
        </p>
        <MycButton
          variant="primary"
          gutterBottom={1}
          style={{ width: '216px', height: '44px', marginTop: '10px' }}
        >
          Back To Home Page
        </MycButton>
      </div>
    </StyledContainer>
  )
}

export { NotFound }
