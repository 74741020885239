import React from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { PropTypes } from 'prop-types'
import { serviceRequestActions } from '@/redux/reducers'
import { cleanUpServiceRequest } from '@/redux/ducks/commonDuck'
import { MoveOutSummaryTableComponent } from './MoveOutSummaryTableComponent'
import { MoveOutContactUsComponent } from './MoveOutContactUsComponent'

const Container = styled.div`
  height: inherit;
  width: fit-content;
`
const SummaryTitle = styled.h2`
  font-size: 30px;
`
const SubTitle = styled.h3`
  & span {
    font-weight: 800;
  }
`

const MoveOutSummaryPageComponent = ({ summary: { data, moveOutDate } }) => {
  const dispatch = useDispatch()
  const { saveSummary } = serviceRequestActions

  const headers = [
    { label: 'Account Number', key: 'accountNumber' },
    { label: 'Nickname', key: 'nickName' },
    { label: 'Current Balance', key: 'finalBalance' },
    { label: 'Physical Address', key: 'address' },
    { label: 'Final Billing Address', key: 'transferringToAddress' },
  ]

  React.useEffect(() => {
    dispatch(saveSummary({ data, moveOutDate }))

    return () => dispatch(cleanUpServiceRequest())
  }, [])

  return (
    <Container>
      <SummaryTitle>Summary</SummaryTitle>
      <SubTitle>
        Move out scheduled for <span>{dayjs(moveOutDate).format('MMMM D[,] YYYY [(]ddd[)]')}</span>
      </SubTitle>
      <CSVLink
        data={data}
        headers={headers}
        filename="my-commercial-moveout.csv"
        className="btn btn-primary"
      >
        Download CSV Summary
      </CSVLink>
      <MoveOutSummaryTableComponent summary={data} />
      <MoveOutContactUsComponent />
    </Container>
  )
}

MoveOutSummaryPageComponent.propTypes = {
  summary: PropTypes.shape({
    data: PropTypes.array,
    moveOutDate: PropTypes.instanceOf(Date),
  }).isRequired,
}

export { MoveOutSummaryPageComponent }
