import { useQuery, useQueries } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useGetAccountKeys } from './useAccounts.queries'

const GET_ACCOUNT_BALANCES = `${process.env.REACT_APP_API_BASE_URL}/payment-public-api/public/api/getbalances/`

const fetchAccountBalance = (accountKeys) => {
  const url = GET_ACCOUNT_BALANCES
  return fetchWrapper(url, {
    method: 'POST',
    mode: 'cors',
    requiresInfor: true,
    body: JSON.stringify(accountKeys),
  })
}

const usePayments = (keys, options) => {
  const accountKeys = Array.isArray(keys) ? keys : [keys]
  return useQuery(['payments', accountKeys], () => fetchAccountBalance(accountKeys), {
    staleTime: 1000 * 60 * 20,
    ...options,
  })
}

const useMultiplePayments = () => {
  const accountKeys = useGetAccountKeys() || []
  return useQueries(
    accountKeys.map((key) => {
      return {
        queryKey: ['payments', [key]],
        queryFn: () => fetchAccountBalance([key]),
        staleTime: 1000 * 60 * 20,
      }
    })
  )
}

export { usePayments, useMultiplePayments }
