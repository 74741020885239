import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useSelector } from 'react-redux'
import { getUserId } from '@/redux/ducks/commonDuck'

const GET_PAYMENT_METHODS = `${process.env.REACT_APP_API_BASE_URL}/wallet-public-api/public/api/getpaymentmethods`

const fetchPaymentMethods = (x52Id) => {
  const url = `${GET_PAYMENT_METHODS}/${x52Id}/2`
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const usePaymentMethods = (options) => {
  const userId = useSelector(getUserId)
  return useQuery(['paymentMethods'], () => fetchPaymentMethods(userId), {
    ...options,
  })
}

export { usePaymentMethods }
