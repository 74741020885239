import { accountSelectors } from '@/redux/ducks/accountDuck'
import { authSelectors } from '@/redux/ducks/authDuck'
import { uiSelectors } from '@/redux/ducks/uiDuck'
import { paymentSelectors } from '@/redux/ducks/paymentDuck'
import { iconSelectors } from './icon/iconSelectors'

const selectors = {
  account: accountSelectors,
  auth: authSelectors,
  icon: iconSelectors,
  ui: uiSelectors,
  payment: paymentSelectors,
}

export default selectors
