import React from 'react'
import { useQueries } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_BILLING_ADDRESS = `${process.env.REACT_APP_API_BASE_URL}/account-public-api/public/api/getbillingaddress/`

const getBillingAddress = (accountKey) => {
  const url = GET_BILLING_ADDRESS + accountKey
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const useBillingAddress = (accountKeys, options) => {
  return useQueries(
    accountKeys.map(
      (key) => {
        return {
          queryKey: ['billingAddress', key],
          queryFn: () => getBillingAddress(key),
        }
      },
      {
        staleTime: 1000 * 60 * 20,
        ...options,
      }
    )
  )
}

export default useBillingAddress
