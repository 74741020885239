import React from 'react'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import styled from 'styled-components'
import './styles.scss'

const ContentContainer = styled.div`
  color: #000;
  background-color: rgba(255, 255, 255, 0.9);
  height: 285px;
  text-align: left;
  padding: 25px;
  font-size: 18px;
`

const MailingAddressLabel = styled.div``
const MailingAddressTop = styled.div``
const MailingAddressBottom = styled.div`
  margin-bottom: 10px;
`
const WalkIns = styled.div``
const Locations = styled.div`
  margin-bottom: 10px;
`
const Phone = styled.div`
  margin-bottom: 10px;
`
const PhoneHours = styled.div`
  margin-bottom: 10px;
`

const RenderContent = () => {
  return (
    <>
      <ContentContainer>
        <MailingAddressLabel>
          <b>Mailing Address:</b>
        </MailingAddressLabel>
        <MailingAddressTop>2800 U.S. Hwy 281 North</MailingAddressTop>
        <MailingAddressBottom>San Antonio, TX 78212</MailingAddressBottom>
        <WalkIns>
          <b>SAWS Customer Center Hours:</b> Mon - Fri, 8am - 5pm
        </WalkIns>
        <Locations>
          <a
            alt="Saws Locations"
            href="https://www.google.com/maps/d/viewer?mid=1euCqqPJ21fhDRFJNQJ3WHVPvPM8&amp;usp=sharing"
            rel="noreferrer"
            target="_blank"
          >
            View our locations
          </a>
        </Locations>
        <Phone>
          <b>Phone:</b> 210-704-7297
        </Phone>
        <PhoneHours>
          <b>SAWS Call Center Hours</b>: Mon - Fri 8 a.m. to 5 p.m and Sat 9 a.m. to 1 p.m
        </PhoneHours>
      </ContentContainer>
    </>
  )
}

const DropdownContact = ({ open }) => {
  return (
    <SlideDown className="my-dropdown-slidedown" closed={!open}>
      {open && <RenderContent />}
    </SlideDown>
  )
}

export default DropdownContact
