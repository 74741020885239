import styled, { css } from 'styled-components'
import { getFlexibleUnit } from '@/utils/flexUnit'

// Use box for quick flex styling
const BaseBox = styled.div`
  padding: ${({ padding }) => getFlexibleUnit(padding, 'em')};
}
`

const Box = styled(BaseBox)`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${({ flexCenter, flexDirection, flexContent }) =>
    // A shortcut for centering flex-children
    (flexCenter || flexContent === 'center') &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: ${flexDirection || 'column'};
    `};

  ${({ flexTop, flexDirection, flexContent }) =>
    // Aligns children center axis, top of parent
    (flexTop || flexContent === 'top') &&
    css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: ${flexDirection || 'column'};
    `};
`

export { Box }
