import { createSlice, createSelector } from '@reduxjs/toolkit'
import { put, call, select, all, takeLatest } from 'redux-saga/effects'
import { asArray } from '@/utils/utils'
import { OBJECT_VARS } from '@/utils/models'
import { API } from '@/redux/api'
import { saveToLocalStorage } from '@/token'
import {
  selectAccount,
  cleanUpSchedulePayments,
  getUserId,
  getUsername,
  deselectAccounts,
  logout,
  cleanUpServiceRequest,
} from './commonDuck'

const initialState = {
  isAccountLoadingComplete: false,
  selectedAccounts: [],
}

const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    fetchUpdateAccountRequest: (state) => state,
    fetchUpdateAccountSuccess: (state) => state,
    fetchUpdateAccountFailure: (state) => state,
    fetchUpdateAccountReset: (state) => state,
    fetchAddAccountRequest: (state) => state,
    fetchAddAccountSuccess: (state) => state,
    fetchAddAccountFailure: (state) => state,
    fetchAddAccountReset: (state) => state,
    fetchUnlinkAccountRequest: (state) => state,
    fetchUnlinkAccountSuccess: (state) => state,
    fetchUnlinkAccountFailure: (state) => state,
    fetchUnlinkAccountReset: (state) => state,
  },
  extraReducers: (builder) => {
    builder.addCase(selectAccount, (state, action) => {
      const accountKey = action.payload

      if (state.selectedAccounts.includes(accountKey)) {
        state.selectedAccounts = state.selectedAccounts.filter((key) => key !== accountKey)
      } else {
        state.selectedAccounts = [...state.selectedAccounts, accountKey]
      }
    })
    builder.addCase(cleanUpSchedulePayments, (state) => {
      state.selectedAccounts = []
    })
    builder.addCase(cleanUpServiceRequest, (state) => {
      state.selectedAccounts = []
    })
    builder.addCase(deselectAccounts, (state) => {
      state.selectedAccounts = []
    })
    builder.addCase(logout, (state) => {
      state.selectedAccounts = []
    })
  },
})

// ---------------------------- Selectors ---------------------------- //
const getAllAccountsAsArray = createSelector(
  (state) => state.accounts.allAccounts,
  (allAccounts) => asArray(allAccounts)
)

const getSelectedAccounts = createSelector(
  [(state) => state.accounts.selectedAccounts, getAllAccountsAsArray],
  (selectedAccounts, allAccounts) => {
    return allAccounts.filter((account) =>
      selectedAccounts.includes(account[OBJECT_VARS.accountKey])
    )
  }
)

const getIsAccountSelected = (state, key) => {
  const selectedAccountIds = state.accounts.selectedAccounts
  return selectedAccountIds.includes(key)
}

// ---------------------- Saga Helper Functions -------------------------- //

function* updateAccount(action) {
  const { account, newNickname, newIcon } = action.payload

  const userName = yield select(getUsername)
  const today = new Date()
  const body = {
    id: account.id,
    [OBJECT_VARS.userId]: account.x52Id,
    [OBJECT_VARS.accountKey]: account.accountKey,
    [OBJECT_VARS.accountNumber]: account[OBJECT_VARS.accountNumber],
    [OBJECT_VARS.nickName]: newNickname || account[OBJECT_VARS.nickname],
    [OBJECT_VARS.icon]: newIcon || account[OBJECT_VARS.icon],
    [OBJECT_VARS.alteredBy]: userName,
    [OBJECT_VARS.alteredDate]: today,
  }
  try {
    yield call(API.saveAccount, body)
    yield put({ type: accountSlice.actions.fetchUpdateAccountSuccess.type })
    yield put({ type: accountSlice.actions.fetchUpdateAccountReset.type })
  } catch (e) {
    yield put({
      type: accountSlice.actions.fetchUpdateAccountFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
  }
}
function* workOnUnlinkAccount(action) {
  const accountKey = action.payload
  const userId = yield select(getUserId)
  try {
    yield call(API.unlinkAccount, userId, accountKey)
    yield put({ type: accountSlice.actions.fetchUnlinkAccountSuccess.type })
    yield put({ type: accountSlice.actions.fetchUnlinkAccountReset.type })
  } catch (e) {
    yield put({
      type: accountSlice.actions.fetchUnlinkAccountFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
  }
}

function* workOnAddAccount(action) {
  const account = action.payload
  try {
    const userId = yield select(getUserId)
    const username = yield select(getUsername)

    const body = {
      [OBJECT_VARS.userId]: userId,
      [OBJECT_VARS.accountKey]: account.accountKey,
      [OBJECT_VARS.accountNumber]: account.accountNumber,
      [OBJECT_VARS.emailNotify]: account.emailnotify,
      [OBJECT_VARS.alteredBy]: username,
      [OBJECT_VARS.nickName]: account.nickName,
      [OBJECT_VARS.icon]: account.icon,
    }

    const { accountKey } = yield call(API.saveAccount, body)
    const token = yield call(API.fetchRefreshToken)
    yield saveToLocalStorage('token', token)
    yield put({ type: accountSlice.actions.fetchAddAccountSuccess.type })
    yield put({ type: accountSlice.actions.fetchAddAccountReset.type })
  } catch (e) {
    yield put({
      type: accountSlice.actions.fetchAddAccountFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
  }
}

export function* watchAccounts() {
  yield all([
    takeLatest(accountSlice.actions.fetchAddAccountRequest.type, workOnAddAccount),
    takeLatest(accountSlice.actions.fetchUpdateAccountRequest.type, updateAccount),
    takeLatest(accountSlice.actions.fetchUnlinkAccountRequest.type, workOnUnlinkAccount),
  ])
}
// --------------------------------------------------------------- //

const accountSelectors = {
  getAllAccountsAsArray,
  getIsAccountSelected,
  getSelectedAccounts,
}

const accountDuck = {
  reducers: accountSlice.reducers,
  actions: accountSlice,
  selectors: accountSelectors,
}

export { accountDuck }
export { accountSlice, accountSelectors }
