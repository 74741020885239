import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { OBJECT_VARS } from '@/utils/models'
import { BaseFormComponent } from '@/components/common/BaseFormComponent'
import { Button } from '@progress/kendo-react-buttons'
import { useAddPaymentMethod } from '../queries/useAddPaymentMethod.queries'

const FormError = styled.p`
  display: block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
  color: ${({ theme }) => theme.error};
`

const AddBankForm = () => {
  const { mutate, isLoading, isSuccess, error } = useAddPaymentMethod()

  const defaultValues = {
    accountType: 'C',
    nameOnAccount: '',
    address: '',
    accountNumber: '',
    confirmban: '',
    routingNumber: '',
    nickName: '',
  }
  const { handleSubmit, errors, control, getValues } = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues,
  })

  const onSubmit = (formValues) => mutate(formValues)

  const formData = [
    {
      name: OBJECT_VARS.accountType,
      label: 'account type',
      defaultValue: 'C',
      input: {
        select: {
          options: [
            { displayLabel: 'Checking', value: 'C', key: 'checkings' },
            { displayLabel: 'Saving', value: 'S', key: 'savings' },
          ],
        },
      },
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
    {
      name: OBJECT_VARS.nameOnAccount,
      label: 'Name on Account',
      defaultValue: '',
      rules: { required: { value: true, message: 'This field is required' } },
    },
    {
      name: OBJECT_VARS.accountNumber,
      label: 'bank account number',
      defaultValue: '',
      type: 'number',
      rules: {
        required: { value: true, message: 'This field is required' },
        maxLength: { value: 17, message: 'The account number is too long' },
      },
    },
    {
      name: `confirm${OBJECT_VARS.accountNumber}`,
      label: 'Retype bank account number',
      defaultValue: '',
      type: 'number',
      rules: {
        required: { value: true, message: 'This field is required' },
        validate: (value) =>
          getValues(OBJECT_VARS.accountNumber) === value ? true : 'Account numbers must match',
      },
    },
    {
      name: OBJECT_VARS.routingNumber,
      label: 'routing number',
      type: 'number',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
    {
      name: 'nickName',
      label: 'Nickname for Bank Account',
      defaultValue: '',
      rules: { required: { value: false } },
    },
  ]

  return (
    <BaseFormComponent
      data={formData}
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      isSubmitSuccessful={isSuccess}
      onSubmit={onSubmit}
      variant="light"
      bottomGutter={1}
    >
      <div style={{ textAlign: 'center' }}>
        <FormError>
          {errors.general && errors.general.message}
          {error && error.message}
        </FormError>
        <Button
          className="btn-myc-col"
          style={{ width: '200px' }}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Saving' : 'Save'}
        </Button>
      </div>
    </BaseFormComponent>
  )
}

export { AddBankForm }
