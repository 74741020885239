export enum InputTypes {
  INPUT,
  SELECT,
  CONTACTADDRESS,
}

export interface ContactInputForm {
  name: string
  label: string
  icon: string
  type: InputTypes
  placeholder: any
  value: any
  setValue: any
  setValue2: any
}
