import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { OBJECT_VARS } from '@/utils/models'
import { BAIconComponent } from './BAIconComponent'

const BACardContainer = styled.div`
  background-color: white;
  padding: 15px;
  font-family: 'Nunito', sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 5px;
`

const BAType = styled.div``
const BANumDisplay = styled.div``

const ShowDetailsButton = styled.div`
  width: 80px;
  color: white;
  padding: 10px;
  background-color: #5691c8;
  border-radius: 5px;
  margin: 15px auto 0 auto;
  cursor: pointer;
`
const ButtonsWrapper = styled.div`
  margin: 0 auto;
`

const BankAccountCard = ({ method, setSelectedPaymentMethodId }) => {
  return (
    <BACardContainer>
      <BAIconComponent type={method[OBJECT_VARS.accountType]} />
      <BAType>
        (
        {!method[OBJECT_VARS.nickName]
          ? method[OBJECT_VARS.issuingBank]
          : method[OBJECT_VARS.nickName]}
        )
      </BAType>
      <BANumDisplay>(************{method[OBJECT_VARS.last4digits]})</BANumDisplay>
      <ButtonsWrapper>
        <ShowDetailsButton onClick={() => setSelectedPaymentMethodId(method.id)}>
          Details
        </ShowDetailsButton>
      </ButtonsWrapper>
    </BACardContainer>
  )
}

BankAccountCard.propTypes = {
  method: PropTypes.shape({
    [OBJECT_VARS.nickName]: PropTypes.string,
    [OBJECT_VARS.issuingBank]: PropTypes.string,
    [OBJECT_VARS.accountType]: PropTypes.string,
  }).isRequired,
  setSelectedPaymentMethodId: PropTypes.func.isRequired,
}

export { BankAccountCard }
