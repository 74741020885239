import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useConsumptionAmi } from '@/hooks/useConsumption.queries'
import { faLineChart, faTint } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import { Card } from '@progress/kendo-react-layout'

import 'hammerjs'
import { useParams } from 'react-router-dom'
import { UsageBarChart } from './UsageBarChart'

const UsageBarChartControls = ({
  meterId,
  x52id,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  currentDate,
  setCurrentDate,
  serviceAddress,
}) => {
  const [grouping, setGrouping] = useState('2')
  const [displayDate, setDisplayDate] = useState('')
  const [chartData, setChartData] = useState([])
  const [defaultGroupingUpdate, setDefaultGroupingUpdate] = useState(true)

  const { key } = useParams()
  const accountKey = Number(key)

  const updateChart = (val) => {
    const newDate = new Date(currentDate)

    switch (grouping) {
      case '2':
        newDate.setFullYear(newDate.getFullYear() + val)
        break
      case '3':
        newDate.setMonth(newDate.getMonth() + val)
        break
      case '4':
        newDate.setDate(newDate.getDate() + val)
        break
      default:
        newDate.setFullYear(newDate.getFullYear() + val)
    }

    setCurrentDate(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()))

    let startResult
    let endResult

    switch (grouping) {
      case '2':
        startResult = `1/1/${newDate.getFullYear()}`
        endResult = `1/1/${newDate.getFullYear() + 1}`
        break
      case '3':
        startResult = `${newDate.getMonth() + 1}/1/${newDate.getFullYear()}`
        {
          const endMonthDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1)
          endResult = `${endMonthDate.getMonth() + 1}/1/${endMonthDate.getFullYear()}`
        }
        break
      case '4':
        startResult = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
        {
          const nextDayDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 1
          )
          endResult = `${nextDayDate.getMonth() + 1}/${
            nextDayDate.getDate() + 1
          }/${nextDayDate.getFullYear()}`
        }
        break
      default:
        startResult = `1/1/${newDate.getFullYear()}`
        endResult = `1/1/${newDate.getFullYear() + 1}`
        break
    }

    return { startResult, endResult }
  }

  const [totalGallons, setTotalGallons] = useState(0)
  const [maxGallons, setMaxGallons] = useState(0)

  const consumptionAmiQuery = useConsumptionAmi(
    accountKey,
    meterId,
    startDate,
    endDate,
    grouping,
    x52id
  )

  const usageDataAmi = consumptionAmiQuery.data

  const getUsageDataForAccountAmi = (data) => {
    if (displayDate === '') {
      setDisplayDate(currentDate.getFullYear())
    }
    if (!data || !data.data || data.data.length === 0) {
      return [{ Date: dayjs(), Gallons: 0 }]
    }

    switch (grouping) {
      case '3':
        setDisplayDate(`${currentDate.getMonth() + 1}${'/'}${currentDate.getFullYear()}`)
        return data.data.flatMap((month) =>
          month.days.map((day) => ({ Date: day.category, Gallons: day.usage }))
        )
      case '4':
        setDisplayDate(
          `${
            currentDate.getMonth() + 1
          }${'/'}${currentDate.getDate()}${'/'}${currentDate.getFullYear()}`
        )
        return data.data.flatMap((month) =>
          month.days.flatMap((day) =>
            day.hourly.map((hour) => ({ Date: hour.category, Gallons: hour.usage }))
          )
        )
      default:
        setDisplayDate(currentDate.getFullYear())
        return data.data.map((item) => {
          return { Date: item.category, Gallons: item.usage }
        })
    }
  }

  useEffect(() => {
    switch (grouping) {
      case '2':
        setDisplayDate(`${currentDate.getFullYear()}`)
        break
      case '3':
        setDisplayDate(`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`)
        break
      case '4':
        setDisplayDate(
          `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`
        )
        break
      default:
        break
    }
  }, [currentDate])

  const handleGroupChange = (event) => {
    if (event) {
      const selectedGroup = event.target.value
      setGrouping(selectedGroup)
    }
  }

  const handleDateChange = (value) => {
    if (defaultGroupingUpdate) {
      const result = updateChart(value)
      const updatedStart = new Date(result.startResult)
      const updatedEnd = new Date(result.endResult)
      setStartDate(updatedStart)
      setEndDate(updatedEnd)
    }
    setDefaultGroupingUpdate(true)
  }

  useEffect(() => {
    handleDateChange(0)
  }, [grouping])

  useEffect(() => {
    let data
    if (usageDataAmi) {
      data = getUsageDataForAccountAmi(usageDataAmi)
      updateChart(0)
    } else {
      data = []
    }

    setChartData(data)
  }, [usageDataAmi])

  useEffect(() => {
    setTotalGallons(chartData.reduce((total, index) => total + index.Gallons, 0))
    setMaxGallons(Math.max(0, ...chartData.map((index) => index.Gallons)))
  }, [chartData])

  return (
    <>
      <div
        className="controls"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
          textAlign: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => handleDateChange(-1)}
          style={{
            display: 'inline',
            background: '#fff',
            margin: '10px',
            borderRadius: '40px',
            padding: '5px',
            color: '#5691c8',
            transition: '0.5s',
            cursor: 'pointer',
          }}
        />
        <div
          id="yearView"
          className="timeRange"
          grouping="2"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          {displayDate}
        </div>
        <div
          id="monthView"
          className="timeRange"
          grouping="3"
          style={{ textAlign: 'center', display: 'none' }}
        >
          {displayDate}
        </div>
        <div
          id="dayView"
          className="timeRange"
          grouping="4"
          style={{ textAlign: 'center', display: 'none' }}
        >
          {displayDate}
        </div>
        <FontAwesomeIcon
          icon={faArrowRight}
          onClick={() => handleDateChange(1)}
          style={{
            display: 'inline',
            background: '#fff',
            margin: '10px',
            borderRadius: '40px',
            padding: '5px',
            color: '#5691c8',
            transition: '0.5s',
            cursor: 'pointer',
          }}
        />
      </div>
      <div
        className="timeSelector"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
          textAlign: 'center',
        }}
      >
        <select
          id="timeSelector"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            margin: '0',
            width: '169px',
          }}
          value={grouping}
          onChange={(e) => handleGroupChange(e)}
        >
          <option value="2">Monthly View</option>
          <option value="3">Daily View</option>
          <option value="4">Hourly View</option>
        </select>
      </div>
      {chartData.length ? (
        <Card style={{ marginBottom: '20px' }}>
          <UsageBarChart
            data={chartData}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            grouping={grouping}
            setGrouping={setGrouping}
            dateInfo={currentDate}
            meterId={meterId}
            address={serviceAddress}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            defaultGroupingUpdate={defaultGroupingUpdate}
            setDefaultGroupingUpdate={setDefaultGroupingUpdate}
          />
        </Card>
      ) : (
        <p>Loading Usage Chart data...</p>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <Grid container>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <FontAwesomeIcon icon={faTint} size="4x" />
              </Grid>
              <Grid item xs={9}>
                <Typography style={{ color: '#0078ae' }} variant="h4">{`${parseFloat(
                  totalGallons.toFixed(1)
                )} gallons`}</Typography>
                <hr />
                <Typography variant="h6">Total Use in Period Selected</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <Grid container>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <FontAwesomeIcon icon={faLineChart} size="4x" />
              </Grid>
              <Grid item xs={9}>
                <Typography style={{ color: '#0078ae' }} variant="h4">{`${parseFloat(
                  maxGallons.toFixed(1)
                )} gallons`}</Typography>
                <hr />
                <Typography variant="h6">Highest Use in Period Selected</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

UsageBarChartControls.propTypes = {
  meterId: PropTypes.any.isRequired,
  x52id: PropTypes.any.isRequired,
}

export { UsageBarChartControls }
