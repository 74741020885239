import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ rightMargin }) => rightMargin};
  font-size: ${({ size }) => size};
  color: ${({ theme, variant }) => theme[variant]};
`

//* This function allows the icon components to be dynamically rendered
const IconComponent = ({ icon, className, size, rightMargin, style }) => {
  // If icon is null or undefined this function will return null and render nothing
  if (!icon) {
    return null
  }

  return (
    <StyledIcon
      icon={icon}
      className={`${className}`}
      size={size}
      rightMargin={rightMargin}
      style={style}
    />
  )
}

IconComponent.defaultProps = {
  icon: null,
  className: '',
  size: '1x',
  rightMargin: '0.5em',
}

IconComponent.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.string,
  rightMargin: PropTypes.string,
}

export { IconComponent }
