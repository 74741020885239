import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { InputTypes } from './InputTypes.ts'

import './test.css'
import { useAccounts } from '../../../hooks/useAccounts.queries'

const LabelWrapper = styled.div`
  display: inline-block;
`

const Label = styled.div`
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
  color: ${({ theme }) => theme.primary};
  display: inline;S
`

const Required = styled.div`
  color: #da690b;
  display: inline;
`

const InputWrapper = styled.div`
  display: inline-block;
`

const InputContainer = styled.div`
  width: 90%;
  display: inline-block;
  vertical-align: top;
`

const FormInput = ({ name, placeholder }) => {
  return (
    <>
      <input name={name} id={name} placeholder={placeholder} type="text" className="inputField" />
    </>
  )
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

FormInput.defaultProps = {
  placeholder: null,
}

const FormSelect = ({ name, value, setValue }) => {
  return (
    <>
      <select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        required
        id={name}
        className="ListStyle"
      >
        <option value="">Select</option>
        <option value="Bill Account Information">Account Information/Questions/Concerns</option>
        <option value="Compliments and Complaints">Compliments or Complaints</option>
        <option value="Conservation">Conservation Program and Services</option>
        <option value="Account Review Routing">Follow Ups</option>
        <option value="General Comment or Question">General Comments, Questions or Other</option>
        <option value="Letter of Credit">Letter of Credit Request</option>
        <option value="CS-Red Flag">New Account Documentation</option>
        <option value="EOC">Outages and Emergencies</option>
        <option value="Payment Arrangement">Payment Arrangements</option>
        <option value="Payment - Unable to Pay Kubra">Payment Issues</option>
        <option value="ONP">Service Interruption</option>
        <option value="Start/Stop Service">Start - Stop - Transfer Service</option>
        <option value="Affordability">Uplift Assistance Programs</option>
        <option value="SAWS MyAccount Issues">Website Technical Issues</option>
      </select>
    </>
  )
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
}

FormSelect.defaultProps = {
  value: null,
  setValue: null,
}

const AccountAddressDropDown = ({ name, value, setValue, setValue2, setValue3 }) => {
  const accountsQuery = useAccounts()
  const accounts = accountsQuery.data

  return (
    <>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          setValue2(e.target.selectedOptions[0].getAttribute('data-account'))
          setValue3(e.target.selectedOptions[0].getAttribute('data-address'))
        }}
        name={name}
        required
        id={name}
        className="ListStyle"
      >
        <option key="-1" value="">
          Select
        </option>
        {!accountsQuery.isLoading &&
          accounts.map(({ serviceAddressLine, accountNumber, subDesignation, accountKey }) => (
            <option
              key={accountKey}
              value={accountKey}
              data-address={`${serviceAddressLine.trim()} ${subDesignation}`}
              data-account={accountNumber}
            >
              {serviceAddressLine.trim()} {subDesignation}
            </option>
          ))}
      </select>
    </>
  )
}

AccountAddressDropDown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
  setValue2: PropTypes.func,
  setValue3: PropTypes.func,
}
AccountAddressDropDown.defaultProps = {
  value: null,
  setValue: null,
  setValue2: null,
  setValue3: null,
}

export { AccountAddressDropDown }

const ContactInput = ({ ContactInputForm: contactInput }) => {
  return (
    <>
      <LabelWrapper>
        <Label>{contactInput.label}</Label>
        <Required>* required</Required>
      </LabelWrapper>
      <InputWrapper className="inputWrapper">
        <InputContainer className="inputContainer">
          {(() => {
            switch (contactInput.type) {
              case InputTypes.INPUT:
                return <FormInput name={contactInput.name} placeholder={contactInput.placeholder} />
              case InputTypes.SELECT:
                return (
                  <FormSelect
                    name={contactInput.name}
                    placeholder={contactInput.placeholder}
                    value={contactInput.value}
                    setValue={contactInput.setValue}
                    required
                  />
                )
              case InputTypes.CONTACTADDRESS:
                return (
                  <AccountAddressDropDown
                    name={contactInput.name}
                    placeholder={contactInput.placeholder}
                    value={contactInput.value}
                    setValue={contactInput.setValue}
                    setValue2={contactInput.setValue2}
                    setValue3={contactInput.setValue3}
                    required
                  />
                )
              default:
                return undefined
            }
          })()}
        </InputContainer>
      </InputWrapper>
    </>
  )
}

export { ContactInput }
