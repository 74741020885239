import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SchedulePayment = styled.p`
  margin: 10px 0;
  font-size: 12px;
`

const SchedulePaymentLink = styled.a`
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`

const PayLinkComponent = ({ flavorText, linkText, onClick }) => {
  return (
    <>
      <SchedulePayment>
        {flavorText} <SchedulePaymentLink onClick={onClick}>{linkText}</SchedulePaymentLink>
      </SchedulePayment>
    </>
  )
}

PayLinkComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  flavorText: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
}

export { PayLinkComponent }
