import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MoveOutAlertModal } from './MoveOutAlertModal.component'
import { MoveOutAlertIcon } from './MoveOutAlertIcon.component'

const Menu = styled.div`
  text-align: right;
  color: rgba(31, 32, 65, 0.25);
  line-height: 0;
  cursor: ${({ hasAlert }) => hasAlert && 'pointer'};
  color: ${({ theme }) => theme.errorBright} !important;
  position: absolute;import { OBJECT_VARS } from '@/utils/models';
import { PropTypes } from 'prop-types';

  right: 10px;
  top: 8px;
`

export const MoveOutAlert = ({ accountKey, show, hide, status }) => {
  const { data, isLoading } = status
  if (!data) return null
  return (
    <>
      <MoveOutAlertModal accountKey={accountKey} show={show} hide={hide} data={data} />
      <Menu>
        <MoveOutAlertIcon isLoading={isLoading} hasAlert={!data.canMoveOut} />
      </Menu>
    </>
  )
}

MoveOutAlert.propTypes = {
  accountKey: PropTypes.string.isRequired,
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  status: PropTypes.shape({
    data: PropTypes.array,
    isLoading: PropTypes.bool,
  }).isRequired,
}
