// Table Accessor Values
export const OBJECT_VARS = {
  userId: 'x52Id',
  username: 'userName',
  firstName: 'firstName',
  lastName: 'lastName',
  title: 'title',
  mobile: 'mobileNumber',
  taxId: 'taxId',
  appId: 'appId',
  email: 'email',
  companyName: 'companyName',
  position: 'position',
  jobTitle: 'jobTitle',
  accountKey: 'accountKey',
  serviceAddress: 'serviceAddressLine',
  streetName: 'streetName',
  streetName2: 'streetName2',
  streetNumber: 'streetNumber',
  suffix: 'suffix',
  subDesignation: 'subDesignation',
  icon: 'icon',
  accountNumber: 'accountNumber',
  latitude: 'latitude',
  longitude: 'longitude',
  nickName: 'nickName',
  addedDate: 'addedDate',
  emailNotify: 'emailNotify',
  alteredBy: 'alteredBy',
  alteredDate: 'alteredDate',
  addedBy: 'addedBy',
  finalBalance: 'finalBalance',
  dueDate: 'dueDate',
  accountStatus: 'accountStatus',
  current: 'current',
  delinquent: 'delinquent',
  pastDue: 'past due',
  percentage: 'lastMonthComparisionPercentage',
  lastmonthyear: 'lastMonthPrevYearUsage',
  lastmonth: 'lastMonthUsage',
  readDate: 'readdate',
  galdiff: 'galDiff',
  usageGallons: 'usagegallons',
  streetNum: 'stno',
  prefix: 'predir',
  city: 'city',
  state: 'state',
  zip: 'zipCode',
  country: 'country',
  sub: 'stsub',
  date: 'date',
  amount: 'amount',
  confirmation: 'confirmation',
  displayName: 'displayName',
  createdDate: 'createdDate',
  createdBy: 'createdBy',
  synchronizationId: 'syncronizationId',
  scheduledDate: 'scheduledDate',
  paymentScheduledDate: 'paymentScheduledDate',
  scheduledPayment: 'scheduledPayment',
  scheduled: 'SCHEDULED',
  status: 'status',
  id: 'id',
  nickname: 'nickname',
  issuingBank: 'issuingBank',
  last4digits: 'last4Digits',
  address: 'address',
  nameOnAccount: 'nameOnAccount',
  routingNumber: 'routingNumber',
  accountType: 'accountType',
  messageId: 'messageID',
  sentDate: 'sentDate',
  preface: 'preface',
  severity: 'severity',
  expirationDate: 'expirationDate',
  acknowledgeDate: 'acknowledgeDate',
  billKey: 'billKey',
  chargeAmount: 'ChargeAmount',
  description: 'textLineItem',
  billAmount: 'billAmount',
  billDate: 'billDate',
  billNo: 'billNo',
  billPaid: 'billPaid',
  currentAmount: 'currentAmount',
  lastPaymentDate: 'lastPaymentDate',
  lastPaymentAmount: 'lastPaymentAmount',
  billTextLineItem: 'textlineitem',
  billTextAmount: 'lineamount',
  encryptedBillKey: 'billKeyEncrypted',
  encryptedAccountKey: 'accountKeyEncrypted',
  serviceAgreementFlag: 'serviceAgreementFlag',
  transferringToCountry: 'transferringToCountry',
  transferringToZipCode: 'transferringToZipCode',
  transferringToState: 'transferringToState',
  transferringToCity: 'transferringToCity',
  transferringToAddress: 'transferringToAddress',
  body: 'body',
  isAmiEnabled: 'isAmiEnabled',
}
