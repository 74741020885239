import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useSelector } from 'react-redux'
import { getUserId } from '@/redux/ducks/commonDuck'

const DELETE_PAYMENT_METHOD = `${process.env.REACT_APP_API_BASE_URL}/wallet-public-api/public/api/deletepaymentmethod`

const deletePaymentMethod = (x52Id, paymentMethodId) => {
  const url = `${DELETE_PAYMENT_METHOD}?x52Id=${x52Id}&paymentMethodId=${paymentMethodId}`
  return fetchWrapper(url, {
    method: 'DELETE',
    mode: 'cors',
    requiresInfor: true,
  })
}

const useDeletePaymentMethod = (options) => {
  const queryClient = useQueryClient()
  const x52Id = useSelector(getUserId)
  return useMutation((paymentMethodId) => deletePaymentMethod(x52Id, paymentMethodId), {
    onSettled: () => queryClient.invalidateQueries(['paymentMethods']),
    ...options,
  })
}

export { useDeletePaymentMethod }
