import React from 'react'
import styled from 'styled-components'
import bg from '@/images/LoginBG.png'
import { MycButton } from '@/components/common/Button'
import { Link } from 'react-router-dom'
import { LINKS } from '@/utils/sitemap'

const ContactUsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  background: linear-gradient(180deg, #0078ae 0%, rgba(121, 121, 121, 0.58) 100%), url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  margin-top: 20px;
  padding: 40px;
`

const ContactUsTextWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
`

const ContactUsText = styled.h1`
  color: #ffffff;
  font-family: Nunito;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  margin: 0;
`

const CenterButton = styled.div`
  text-align: center;
`

const ContactUsButton = styled(MycButton)`
  width: 251px;
  height: 60px;
  margin-top: 20px;
  margin-bottom: 88px;
  font-size: 20px;
`

const MoveOutContactUsComponent = () => {
  return (
    <ContactUsWrapper>
      <ContactUsTextWrapper>
        <ContactUsText>Your move out request(s) have been submitted.</ContactUsText>
      </ContactUsTextWrapper>
      <CenterButton>
        <Link to={LINKS.moveout} style={{ marginRight: '10px' }}>
          <ContactUsButton variant="warning">Move Out More Accounts</ContactUsButton>
        </Link>
        <Link to={LINKS.home}>
          <ContactUsButton variant="warning">Back to Home</ContactUsButton>
        </Link>
      </CenterButton>
    </ContactUsWrapper>
  )
}

export { MoveOutContactUsComponent }
