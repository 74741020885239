// Colors
const offWhite = '#f4f4f4'
const lightGrey = '#8f8f8f'
const lightBlue = 'rgba(86, 145, 200, 0.31)'
const blue = '#0078ae'
const midBlue = '#0070a3'
const darkBlue = '#3250b3'
const darkerBlue = '#2d489f'
const blueGradient = 'linear-gradient(180deg, #246eb9 0%, #5691c8 100%)'
const orange = '#FF8522'
const lightOrange = '#F5A200'
const darkOrange = '#DA690B;'
const green = '#279a2c'
const darkGreen = '#248f29'
const darkGrey = '#797979'
const red = '#d40000'
const darkRed = '#AE0000'
const white = '#fff'
const lightGray = '#ced4da'

const theme = {
  fonts: {
    main: 'Nunito, Sans-Serif',
  },
  colors: {
    blue,
    white,
    lightGrey,
    darkGrey,
    lightBlue,
    lightGray,
  },
  light: '#fff',
  dark: '#000',
  primary: blue,
  primaryDark: midBlue,
  secondary: lightGrey,
  success: green,
  successDark: darkGreen,
  warning: orange,
  warningBright: lightOrange,
  warningDark: darkOrange,
  error: darkRed,
  errorBright: red,
  info: darkBlue,
  infoBright: blue,
  infoDark: darkerBlue,
  text: darkGrey,
  blueGradient,
}

export { theme }
