import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { theme } from '@/styles/theme'
import { ICONS } from '@/utils/icons'
import { formatLongDate } from '@/utils/utils'
import { IconComponent } from '@/components/common/IconComponent'

// -------------------------- Custom Components ------------------------ //

const CustomHeader = ({
  title,
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const month = formatLongDate(date, true).split(' ')[0]
  const year = formatLongDate(date, true).split(' ')[2]
  return (
    <div style={{ color: '#000', fontWeight: '600', fontFamily: theme.fonts.main }}>
      <div style={{ fontSize: '22px', marginBottom: '15px' }}>{title}</div>
      <div style={{ display: 'flex', marginBottom: '2px' }}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'transparent',
            color: theme.primary,
            visibility: !prevMonthButtonDisabled ? 'visible' : 'hidden',
          }}
          onClick={decreaseMonth}
        >
          <IconComponent size="1.4em" icon={ICONS.arrowLeft} />
        </button>
        <div style={{ fontSize: '16px', flex: '100%', lineHeight: '15px' }}>
          {month} {year}
        </div>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'transparent',
            color: theme.primary,
            visibility: !nextMonthButtonDisabled ? 'visible' : 'hidden',
          }}
          onClick={increaseMonth}
        >
          <IconComponent size="1.4em" icon={ICONS.arrowRight} />
        </button>
      </div>
    </div>
  )
}

// --------------------------------------------------------------------------- //

const ScheduleComponent = ({
  title,
  selectedDay,
  setSelectedDay,
  popperPlacement,
  filterDate,
  excludeDates,
  minDate,
  maxDate,
  customInput,
  style,
}) => {
  const [calendarOpen, setCalendarOpen] = React.useState(false)
  const today = new Date()
  const fullMaxDate = new Date().setDate(today.getDate() + 30)

  return (
    <DatePicker
      style={style}
      dateFormat="M/d/yyyy"
      filterDate={filterDate}
      excludeDates={excludeDates}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <CustomHeader
          title={title}
          date={date}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
      calendarClassName="calendar-day"
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      selected={selectedDay}
      minDate={minDate || today}
      maxDate={maxDate || fullMaxDate}
      shouldCloseOnSelect
      onChange={(date) => setSelectedDay(date)}
      showPopperArrow
      popperPlacement={popperPlacement || 'right'}
      popperModifiers={{
        offset: {
          enabled: true,
          offset: '5px, 5px',
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
      onCalendarOpen={() => setCalendarOpen(true)}
      onCalendarClose={() => setCalendarOpen(false)}
      customInput={customInput}
    />
  )
}

ScheduleComponent.propTypes = {
  selectedDay: PropTypes.instanceOf(Date).isRequired,
  setSelectedDay: PropTypes.func.isRequired,
}

export { ScheduleComponent }
