import React from 'react'
import { OBJECT_VARS } from '@/utils/models'
import { PropTypes } from 'prop-types'
import { NotificationsListComponent } from './NotificationsListComponent'
import useNotifications from './useNotifications.queries'

const ExpiredNotificationsList = ({ select, selected, animate }) => {
  const { data } = useNotifications({
    select: (el) => el.filter((notification) => notification[OBJECT_VARS.expirationDate] !== null),
  })
  React.useEffect(() => {
    if (data && data.length > 0) {
      select(data[0][OBJECT_VARS.messageId])
    }
    return function cleanUp() {
      select(null)
    }
  }, [])

  return (
    <NotificationsListComponent
      notifications={data}
      selectNotification={select}
      selectedNotification={selected}
      animate={animate}
    />
  )
}

ExpiredNotificationsList.propTypes = {
  notifications: PropTypes.shape({}).isRequired,
  select: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
}
export { ExpiredNotificationsList }
