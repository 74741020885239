import { call, takeLatest, all } from 'redux-saga/effects'
import { authSlice } from '@/redux/ducks/authDuck'

export function* fetchInitData() {
  try {
  } catch (e) {
    yield console.log(e)
  }
}

function* watchCreateUserSuccess() {
  yield takeLatest(authSlice.actions.fetchCreateUserSuccess.type, fetchInitData)
}

function* watchUserLoginSuccessful() {
  yield takeLatest(authSlice.actions.fetchLogInSuccess.type, fetchInitData)
}

export function* watchInitialization() {
  yield all([call(watchCreateUserSuccess), call(watchUserLoginSuccessful)])
}
