import React from 'react'
import { usePrevious } from './usePrevious'

export const useResetFormHook = (value) => {
  const [shouldResetForm, setShouldResetForm] = React.useState(false)
  const prevValue = usePrevious(value)

  React.useEffect(() => {
    if (value === prevValue) return setShouldResetForm(false)
    return setShouldResetForm(true)
  }, [value, prevValue, shouldResetForm])

  return { shouldResetForm }
}
