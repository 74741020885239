import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import LogoImg from '@/images/Logo-25-white.png'
import { Box } from '@/components/common/Box'

const LogoContainer = styled(Box)`
  min-height: 160px;
  background: ${({ theme }) => theme.blueGradient};
  text-align: center;
  color: #fff;
  font-size: 26px;
  font-weight:700;
`
const StyledImg = styled.img`
    height: 80px;
    width: 150px;
`

const Logo = () => (
  <LogoContainer flexCenter>
    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible>
      <StyledImg alt="SAWS Logo" src={LogoImg} />
      BETA
    </Animated>
  </LogoContainer>
)

export default Logo
