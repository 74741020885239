import { css } from 'styled-components'

const intuitUnit = (property, defaultUnit = 'em') =>
  Number.isFinite(property) ? property + defaultUnit : property

const buildShorthand = (vals) => {
  const [top, right, bottom, left] = vals
  return css`
${intuitUnit(top)} ${intuitUnit(right)} 
${intuitUnit(bottom)} ${intuitUnit(left)}
`
}

const getFlexibleUnit = (property) => {
  if (Array.isArray(property)) {
    return buildShorthand(property)
  }
  return intuitUnit(property)
}

export { getFlexibleUnit }
