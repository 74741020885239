import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Slide,
} from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: 5,
    border: '2px solid #0078ae',
  },
  button: {
    borderRadius: 2,
    textTransform: 'none',
    padding: 5,
  },
  logout: {
    color: '#fff',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  countdown: {
    color: red[700],
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }} TransitionComponent={Transition}>
      <DialogTitle>Session Timeout</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          The current session is about to expire in{' '}
          <span className={classes.countdown}>{countdown}</span> seconds.
        </Typography>
        <Typography variant="body2">Would you like to continue the session?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogout} variant="contained" className={(classes.logout, classes.button)}>
          Logout
        </Button>
        <Button onClick={onContinue} color="primary" variant="contained" className={classes.button}>
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SessionTimeoutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  countdown: PropTypes.number.isRequired,
  onLogout: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
}

export default SessionTimeoutDialog
