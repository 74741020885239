import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useSelector } from 'react-redux'
import { getUserId } from '@/redux/ducks/commonDuck'

const GET_USER_ACCOUNTS_BY_ID = `${process.env.REACT_APP_API_BASE_URL}/account-public-api/public/api/getaccountsbyid?x52Id=`

const fetchUserAccounts = (x52id, includeClosed = true, closedDuration = 90) => {
  const url = `${GET_USER_ACCOUNTS_BY_ID}${x52id}&includeClosed=${includeClosed}&closedDuration=${closedDuration}`
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: false,
  })
}

const useAccounts = (options) => {
  const userId = useSelector(getUserId)
  return useQuery(['accounts'], () => fetchUserAccounts(userId), {
    ...options,
  })
}

const useGetAccountKeys = () => {
  const { data } = useAccounts({ select: (res) => res.map((el) => el.accountKey) })
  return data
}

export { useAccounts, useGetAccountKeys }
