import React from 'react'
import styled from 'styled-components'
import { FaCog } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { MycButton } from '@/components/common/Button'
import { useAuthApi } from '@/redux/reducers'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'
import { loadFromLocalStorage } from '@//token'

const MenuContainer = styled.div`
  position: absolute;
  width: 268px;
  height: 244px;
  background: #3250b3;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  z-index: 550;
  right: 43px;
  top: 65px;
`

const CogBg = styled(FaCog)`
    height: 85%;
    width: 85%;
    position: absolute;
    top: 8%;
    left: 7%;
    color: rgba(0,0,0,0.19);
}
`

const Menu = styled.div`
  position: absolute;
  margin-top: 26px;
  margin-left: 40px;
`

const MenuItem = styled(Link)`
  display: block;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  text-decoration-line: underline;
  text-transform: uppercase;
  margin-bottom: 13px;

  &:hover,
  &:active {
    color: rgba(255, 255, 255, 0.7);
  }
`

const CenterButton = styled.div`
  text-align: center;
`

const LogoutButton = styled(MycButton)`
  text-transform: uppercase;
  background: rgba(174, 0, 0, 0.5);
  width: 120px;
  height: 44px;
  margin-top: 20px;
  &:hover {
    background: rgba(174, 0, 0, 0.8);
    border-color: rgba(174, 0, 0, 0.8);
  }
`

const HeaderMenuComponent = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const handleClick = () => {
    dispatch(useAuthApi.logout())
    queryClient.removeQueries()
  }

  const [impersonate, setImpersonate] = React.useState(false)
  React.useEffect(() => {
    const { isImpersonating } = loadFromLocalStorage('token') || {}
    setImpersonate(isImpersonating)
  }, [])

  return (
    <MenuContainer>
      <CogBg />
      <Menu>
        <MenuItem to="/myprofile">My profile</MenuItem>
        <MenuItem to="/manage">Manage My Accounts</MenuItem>
        <MenuItem to="/messagecenter">message center</MenuItem>
        {impersonate ? null : <MenuItem to="/wallet">My Wallet</MenuItem>}
        <CenterButton>
          <LogoutButton onClick={handleClick}>Log Out</LogoutButton>
        </CenterButton>
      </Menu>
    </MenuContainer>
  )
}

export { HeaderMenuComponent }
