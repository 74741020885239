import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IconComponent } from '@/components/common/IconComponent'
import { Button } from '@progress/kendo-react-buttons'
import { ICONS } from '@/utils/icons'
import { useDeletePaymentMethod } from '../queries/useDeletePaymentMethod.queries'

const RemovePaymentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`

const DeletePaymentMethodButton = ({ id }) => {
  const [willDelete, setWillDelete] = React.useState(false)
  const { mutate, isLoading } = useDeletePaymentMethod()
  const handleDelete = () => {
    if (willDelete) {
      mutate(id)
      return setWillDelete(false)
    }
    return setWillDelete(true)
  }
  return (
    <RemovePaymentWrapper className="mt-3">
      {willDelete && (
        <Button className="btn-myc-col mr-2" onClick={() => setWillDelete(false)}>
          <IconComponent icon={ICONS.undo} rightMargin={0} />
        </Button>
      )}
      <Button className="btn-myc-red" onClick={handleDelete} disabled={isLoading}>
        {willDelete && 'Confirm'}
        {!isLoading && !willDelete && 'Remove This Payment Option'}
        {isLoading && 'Deleting...'}
      </Button>
    </RemovePaymentWrapper>
  )
}

DeletePaymentMethodButton.propTypes = {
  id: PropTypes.string.isRequired,
}

export { DeletePaymentMethodButton }
