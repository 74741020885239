import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Title } from '@/components/common/Typography'
import { Box } from '@/components/common/Box'
import { IconComponent } from '@/components/common/IconComponent'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountApi } from '@/redux/reducers'
import { useDispatch } from 'react-redux'
import { OBJECT_VARS } from '@/utils/models'
import { useAccounts } from '../../../hooks/useAccounts.queries'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 328px;
  height: 678px;
  overflow: auto;
  align-items: center;
`

const AccountBox = styled(Box)`
  position: relative;
  background: #ffffff;
  padding: 1.5em 1.5em 6px 1.5em;
  border-radius: 4px;
  height: 185;
  width: 210px;
  margin-bottom: 25px;
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const DeleteWrapper = styled.div`
  position: absolute;
  right: 3px;
  top: 4px;
  width: 25px;
  height: 25px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  &:hover svg,
  &:active svg,
  &:focus svg {
    fill: ${({ theme }) => theme.errorBright};
  }
  & svg {
    color: ${({ theme }) => theme.error};
  }
`

const DeleteButton = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.error};
`

const ExistingAccountsMessage = styled.div`
  text-align: left;
  font-size: 13px;
  color: #fff;
  margin-bottom: 20px;
`

const DeleteComponent = ({ onClick }) => {
  return (
    <DeleteWrapper onClick={onClick}>
      <DeleteButton icon={faTimes} />
    </DeleteWrapper>
  )
}
const Account = ({ icon, nickname, accountKey, accountNumber }) => {
  const dispatch = useDispatch()
  return (
    <Wrapper>
      <DeleteComponent
        onClick={() => dispatch(useAccountApi.fetchUnlinkAccountRequest(accountKey))}
      />
      <IconComponent
        icon={icon !== null && icon.file}
        style={{ margin: '0 auto', color: '#3250B3', height: '38px', width: '36px' }}
      />
      <div
        style={{
          fontSize: '1.3em',
          fontWeight: '800',
          margin: '10px 0',
        }}
      >
        {nickname || 'No nickname'}
      </div>
      <div style={{ marginBottom: 0, fontSize: '14px' }}>Account #:</div>
      <div style={{ fontWeight: '100', fontSize: '14px' }}>{accountNumber}</div>
    </Wrapper>
  )
}
const AddedAccounts = ({ newlyAddedAccounts }) => {
  const accountsQuery = useAccounts()
  const existingAccounts = accountsQuery.data
  return (
    <>
      <Title size="1.5em" fontWeight="300" bottomGutter={1}>
        Added Accounts
      </Title>
      <Container>
        {accountsQuery.isSuccess && existingAccounts.length === 0 && (
          <ExistingAccountsMessage>
            You can add water accounts using the form to the left. When finished, click Finished
            below.
          </ExistingAccountsMessage>
        )}

        {newlyAddedAccounts.map((account) => (
          <AccountBox key={account[OBJECT_VARS.accountNumber]} flexCenter flexDirection="column">
            <Account
              icon={account[OBJECT_VARS.icon]}
              nickname={account[OBJECT_VARS.nickName]}
              accountNumber={account[OBJECT_VARS.accountNumber]}
              accountKey={account[OBJECT_VARS.accountKey]}
            />
          </AccountBox>
        ))}
      </Container>
    </>
  )
}

Account.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  accountKey: PropTypes.number.isRequired,
  icon: PropTypes.func.isRequired,
  nickname: PropTypes.string.isRequired,
}

export { AddedAccounts }
