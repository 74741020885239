import React from 'react'
import { getBillPdf } from '@/redux/api'

const useBillPdfHook = (accountKey, billKey, shouldFetch) => {
  const [pdfUrl, setPdfUrl] = React.useState(null)

  React.useEffect(() => {
    const getBill = () => getBillPdf(accountKey, billKey)
    if (shouldFetch) {
      getBill()
        .then((res) => res.blob())
        .then((data) => {
          const newBlob = new Blob([data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(newBlob)
          setPdfUrl(url)
        })
    }
  }, [shouldFetch, accountKey, billKey])
  return { pdfUrl }
}

export { useBillPdfHook }
