import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthApi as useAuth } from '@/redux/reducers'
import { useForm, Controller } from 'react-hook-form'
import { ICONS } from '@/utils/icons'
import { Input, Label } from '@/components/common/Input'
import { PropTypes } from 'prop-types'
import { MycButton } from '@/components/common/Button'
import { Link } from 'react-router-dom'
import selectors from '@/redux/selectors'

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`

const FormError = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
  color: ${({ theme }) => theme.error};

  & p {
    margin: 0;
  }
`

const MycInput = styled(Input)`
  width: 320px;
`

const SideBySide = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const ForgotPasswordLink = styled(Link)`
  color: #000000;
  display: block;
  text-align: right;
  margin-top: 5px;
  font-size: 12px;
  text-decoration: underline;
`

const LoginForm = ({ className }) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    errors,
    control,
    formState: { dirtyFields },
  } = useForm({ mode: 'onTouched', defaultValues: { username: '', password: '' } })
  const error = useSelector((state) => selectors.ui.getError(state, useAuth.fetchLogInRequest.type))
  const onSubmit = ({ username, password }) => {
    dispatch(useAuth.fetchLogInRequest({ username, password }))
  }
  const isFetching = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAuth.fetchLogInRequest.type)
  )

  React.useEffect(() => {
    if (error?.hasError && dirtyFields.password === undefined) {
      dispatch(useAuth.fetchLogInReset())
    }
  })

  return (
    <StyledForm className={className} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1200" isVisible>
          <Label label="User name" variant="dark" />
          <Controller
            as={MycInput}
            name="username"
            control={control}
            defaultValue=""
            placeholder="Type here..."
            icon={ICONS.user}
            rules={{ required: true }}
            isInvalid={errors?.username && true}
            style={{ marginBottom: '0' }}
          >
            <FormError>{errors?.username && 'This field is required'}</FormError>
          </Controller>
        </Animated>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1300" isVisible>
          <Label label="Password" variant="dark" />
          <Controller
            as={MycInput}
            type="password"
            name="password"
            control={control}
            defaultValue=""
            placeholder="Type here..."
            icon={ICONS.user}
            rules={{ required: true }}
            isInvalid={errors?.username && true}
          >
            <SideBySide>
              <FormError>{errors?.password && 'This field is required'}</FormError>
              <ForgotPasswordLink to="/account/forgot">Forgot Password?</ForgotPasswordLink>
            </SideBySide>
          </Controller>
        </Animated>
      </div>
      <FormError style={{ marginBottom: '10px' }}>
        <p style={{ height: '16px' }}>
          {error && error.statusCode === 401 ? 'Usename or password is incorrect' : null}
        </p>
      </FormError>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1400" isVisible>
        <MycButton
          variant={isFetching ? 'primary' : 'warning'}
          gutterBottom={1}
          style={{ width: '216px', height: '44px', marginTop: '10px' }}
          disabled={isFetching}
          type="submit"
        >
          {isFetching ? 'SIGNING IN' : 'LOG IN'}
        </MycButton>
      </Animated>
    </StyledForm>
  )
}

LoginForm.propTypes = {
  className: PropTypes.string,
  handleLogin: PropTypes.shape({
    setUsername: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
  }).isRequired,
}

LoginForm.defaultProps = {
  className: '',
}

export { LoginForm }
