import { ACCOUNT_ICONS } from '@/utils/icons'
import { snakeToCamel } from '@/utils/utils'

const getIconFromList = (name) => ACCOUNT_ICONS[snakeToCamel(name)]

const getIconByAccountId = (state, id) => {
  const iconName = state.icon.byId[id].icon
  return getIconFromList(iconName)
}

export const iconSelectors = { getIconByAccountId, getIconFromList }
