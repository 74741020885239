import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_LAST_PAYMENT = `${process.env.REACT_APP_API_BASE_URL}/payment-public-api/public/api/lastpayment/`

const fetchLastPayment = (accountKey) => {
  const url = `${GET_LAST_PAYMENT + accountKey}/`
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const useLastPayment = (key, options) => {
  return useQuery(['lastpayment', key], () => fetchLastPayment(key), {
    staleTime: 1000 * 60 * 20,
    ...options,
  })
}

export { useLastPayment }
