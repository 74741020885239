import React from 'react'
import styled from 'styled-components'
import Alert from 'react-bootstrap/Alert'
import { MycButton } from '@/components/common/Button'

const AlertBtn = styled(MycButton)`
  height: 37px;
  width: 100px;
  padding: 0;
`

const MoveOutOverwriteAlert = ({ cancel, proceed }) => {
  return (
    <Alert key={1} variant="danger">
      <div style={{ textAlign: 'center' }}>This will overwrite your changes.</div>
      <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
        <AlertBtn style={{ marginRight: '5px' }} onClick={proceed}>
          Proceed
        </AlertBtn>
        <AlertBtn variant="warning" onClick={cancel}>
          Cancel
        </AlertBtn>
      </div>
    </Alert>
  )
}

export { MoveOutOverwriteAlert }
