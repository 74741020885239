import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import bg from '@/images/LoginBG.png'
import { Box } from '@/components/common/Box'
import { Title, Terms } from '@/components/common/Typography'
import { MycButton } from '@/components/common/Button'
import { AccountInfoForm } from './AccountInfoForm'
import { AddedAccounts } from './AddedAccounts'

const Container = styled.div`
  height: 100%;
  padding: 60px;
  background: linear-gradient(0deg, rgba(86, 145, 200, 0.6), rgba(86, 145, 200, 0.6)), url(${bg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AccountFormContainer = styled(Box)`
  background: #2d2d2d;
  position: relative;
`

const AddedAccountsContainer = styled(Box)`
  background: #3250b3;
`

const StyledTerms = styled(Terms)`
  color: #fff;
  margin: 47px auto;
  width: 320px;
  line-height: 14px;
`

const SubmitAllBtn = styled(MycButton)`
  margin-top: 21px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  background: #ff7200;
  width: 250px;
  border-radius: 4px;
  padding: 12px 0;
  border-color: transparent;
  text-align: center;
  cursor: pointer;
`

const AddWaterAccount = () => {
  const [newlyAddedAccounts, setNewlyAddedAccounts] = React.useState([])
  const addTempAccount = (account) => setNewlyAddedAccounts([...newlyAddedAccounts, account])

  return (
    <Container>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="500" isVisible>
        <Title fontSize={2} bottomGutter={1}>
          Add SAWS Account
        </Title>
      </Animated>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
        <Box flexDirection="row" marginBottom={2}>
          <AccountFormContainer style={{ width: '420px' }} padding={2} flexTop>
            <AccountInfoForm handleAddTempAccount={addTempAccount} />
          </AccountFormContainer>
          <AddedAccountsContainer style={{ width: '420px' }} padding={2} flexTop>
            <AddedAccounts newlyAddedAccounts={newlyAddedAccounts} />
          </AddedAccountsContainer>
        </Box>
      </Animated>
      <Link to="/manage" alt="Create new account">
        <SubmitAllBtn variant="warning">Finished</SubmitAllBtn>
      </Link>

      <StyledTerms>
        By clicking &quot;Submit&quot; you are agreeing to the{' '}
        <Link to="/tc" alt="Terms and conditions" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </Link>{' '}
        for using this site, last updated on 05/25/2018
      </StyledTerms>
    </Container>
  )
}

export default AddWaterAccount
