import React from 'react'
import styled from 'styled-components'
import selectors from '@/redux/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { MycButton } from '@/components/common/Button'
import { formatLongDate, parseDollars } from '@/utils/utils'
import { Link } from 'react-router-dom'
import { cleanUpSchedulePayments } from '@/redux/ducks/commonDuck'
import {
  getTotalAmountToBePaid,
  getPaymentDate,
  getScheduledPaymentsAsArray,
} from '@/redux/ducks/paymentDuck'
import { AccountRow } from './AccountRow'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
`

const Header = styled.h1`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #3250b3;
`

const SummaryCard = styled.div`
  background: #fff;
  padding: 50px;
  max-width: 100%;
`

const SummaryHeader = styled.h2`
  color: #797979;
  text-transform: uppercase;
  margin: 0 0 25px 0;
  font-weight: 100;
  font-family: Nunito;
  font-size: 32px;
  text-align: center;
`

const AccountWrapper = styled.div`
  min-height: 150px;
  overflow-y: auto;
  padding: 0 15px;
`

const TotalWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

const TotalTitle = styled.h2`
  color: #000;
  margin-bottom: 0;
  font-size: 42px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  font-family: Nunito;
  font-weight: 300;
`

const TotalAmount = styled.h1`
  margin: 0;
  font-size: 92px;
  font-weight: 300;
  margin-bottom: 10px;
  align-self: center;
  color: ${({ theme }) => theme.primary};
`

const Terms = styled.p`
  color: #ff7200;
  font-size: 16px;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  font-weight: 200;
`

const BackButton = styled(MycButton)`
  width: 350px;
  margin: 25px auto;
  font-weight: 100;
  font-size: 24px;
  padding: 15px;
`

const PaymentSummary = () => {
  const dispatch = useDispatch()
  const willSchedulePayment = useSelector((state) => selectors.payment.getWillSchedule(state))
  const scheduledDate = useSelector(getPaymentDate)
  const totalPaid = useSelector(getTotalAmountToBePaid)
  const parsedTotal = parseDollars(totalPaid)
  const payments = useSelector(getScheduledPaymentsAsArray)

  const scheduledPaymentText = `*You have scheduled a payment. All amounts will be charged on 
  ${formatLongDate(scheduledDate, true)}. If you need to alter your scheduled payment,
  please call SAWS at (210) 704-SAWS (704-7297)`

  React.useEffect(() => {
    return () => {
      dispatch(cleanUpSchedulePayments())
    }
  }, [])
  return (
    <>
      <Container>
        <Header>thanks for {willSchedulePayment && 'scheduling'} your payment!</Header>
        <SummaryCard>
          <SummaryHeader>
            summary of charges for {formatLongDate(scheduledDate, true)}{' '}
          </SummaryHeader>
          <AccountWrapper>
            {payments.map((summary) => (
              <AccountRow paymentSummary={summary} />
            ))}
          </AccountWrapper>

          <TotalWrapper>
            <TotalTitle>Amount Paid</TotalTitle>
            <TotalAmount>{parsedTotal}</TotalAmount>
          </TotalWrapper>
          <Terms>{willSchedulePayment ? scheduledPaymentText : null}</Terms>
          <div style={{ textAlign: 'center' }}>
            <Link to="/accounts">
              <BackButton variant="success">Back to Home</BackButton>
            </Link>
          </div>
        </SummaryCard>
      </Container>
    </>
  )
}

export { PaymentSummary }
