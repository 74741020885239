import { useMutation } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useSelector } from 'react-redux'
import { getUserId } from '@/redux/ducks/commonDuck'

const EXPIRE_MESSAGE = `${process.env.REACT_APP_API_BASE_URL}/notifications-public-api/public/api/expiremessage`

const expireNotification = (userId, messageId) => {
  const url = `${EXPIRE_MESSAGE}/${userId}/${messageId}`
  return fetchWrapper(url, {
    method: 'POST',
    mode: 'cors',
    requiresInfor: false,
  })
}

const useExpireNotification = (options) => {
  const userId = useSelector(getUserId)
  return useMutation((messageId) => expireNotification(userId, messageId), options)
}

export { useExpireNotification }
