import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { PropTypes } from 'prop-types'
import { useAuthApi as useAuth } from '@/redux/reducers'
import { MycButton } from '@/components/common/Button'
import selectors from '@/redux/selectors'
import { BaseFormComponent } from '@/components/common/BaseFormComponent'
import { getUserByUsername, getSecretQuestion } from '@/redux/api'
import { useResetFormHook } from '@/hooks/useResetFormHook'
import { FormError } from '@/components/common/FormError'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`

const SuccessMessage = styled.div`
  font-size: 12px;
  width: 250px;
  text-align: left;
` 

const RecoveryForm = () => {
  const dispatch = useDispatch()
  const [username, setUsername] = React.useState(null)
  const [x52id, setX52Id] = React.useState(null)
  const [secretQuestionId, setSecretQuestionId] = React.useState()
  const { shouldResetForm } = useResetFormHook(x52id)
  const [validatingUsername, setValidatingUsername] = React.useState(false)
  const {
    handleSubmit,
    errors,
    control,
    reset,
    register,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: 'onBlur',
  })
  const resetSuccessful = useSelector((state) =>
    selectors.ui.getFetchSuccessful(state, useAuth.fetchResetUserPasswordRequest.type)
  )
  const resetIsFetching = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAuth.fetchResetUserPasswordRequest.type)
  )

  const error = useSelector((state) =>
    selectors.ui.getError(state, useAuth.fetchResetUserPasswordRequest.type)
  )
  const onSubmit = (e) => {
    const req = {
      x52Id: x52id,
      secretQuestion: parseInt(e.secretQuestion, 10),
      secretAnswer: e.secretAnswer,
    }
    dispatch(useAuth.fetchResetUserPasswordRequest(req))
  }
  const validateUsername = async (value) => {
    setUsername(value)
    setValidatingUsername(true)
    const result = await getUserByUsername(value)
    if (result) {
      setValidatingUsername(false)
      return setX52Id(result)
    }
    setValidatingUsername(false)
    setX52Id(null)
    return 'User name not found'
  }

  const formData = [
    {
      name: 'username',
      label: 'user name',
      defaultValue: username || '',
      rules: {
        required: { value: true, message: 'This field is required' },
        validate: validateUsername,
      },
      readOnly: username !== null,
      showAsyncMessage: validatingUsername,
      asyncMessage: 'Locating user name',
    },
    {
      name: 'secretQuestion',
      label: 'password recovery question',
      defaultValue: secretQuestionId || '',
      bottomGutter: 2,
      readOnly: false,
      isHidden: !x52id,
      input: {
        select: {
          options: [
            { displayLabel: "What is your mother's maiden name?", value: '1', key: '1' },
            { displayLabel: "What is your pet's name? ", value: '2', key: '2' },
            { displayLabel: 'What city or town were you born in? ', value: '3', key: '3' },
            { displayLabel: "Who's your favorite superhero? ", value: '4', key: '4' },
            { displayLabel: "What's your favorite food?", value: '5', key: '5' },
          ],
        },
      },
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
    {
      name: 'secretAnswer',
      label: 'password recovery answer',
      type: 'password',
      defaultValue: '',
      isHidden: !x52id,
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
  ]

  React.useEffect(() => {
    if (!x52id) return undefined
    getSecretQuestion(username).then((res) => {
      // setSecretQuestionId(res.toString())
      setValue('secretQuestion', res, { shouldDirty: true })
    })
  }, [x52id, username])

  React.useEffect(() => {
    dispatch(useAuth.fetchResetUserPasswordReset())
  }, [])

  return (
    <FormContainer>
      <BaseFormComponent
        data={formData}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        isSubmitSuccessful={isSubmitSuccessful}
        onSubmit={onSubmit}
        variant="dark"
        bottomGutter={1}
        register={register}
        forceReset={shouldResetForm ? reset : null}
      >
        <div style={{ textAlign: 'center' }}>
          <SuccessMessage>
            {resetSuccessful &&
              'Your password was reset successfully. Please check the email associated with this account'}
          </SuccessMessage>
          <FormError>{error && error.statusCode === 500 ? 'Answer is incorrect' : null}</FormError>
          <FormError>
            {error && error.statusCode === 400
              ? 'An error occured. Please refresh and try again'
              : null}
          </FormError>
          {!x52id && (
            <MycButton type="button" gutterBottom={2} disabled={isSubmitting}>
              Find User Name
            </MycButton>
          )}
          {x52id && (
            <MycButton type="submit" gutterBottom={2} disabled={isSubmitting || resetIsFetching}>
              Recover Password
            </MycButton>
          )}
        </div>
      </BaseFormComponent>
    </FormContainer>
  )
}

RecoveryForm.propTypes = {
  className: PropTypes.string,
  handleLogin: PropTypes.shape({
    setUsername: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
  }).isRequired,
}

RecoveryForm.defaultProps = {
  className: '',
}

export { RecoveryForm }
