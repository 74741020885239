import React from 'react'
import styled from 'styled-components'
import { parseDollars } from '@/utils/utils'
import { OBJECT_VARS } from '@/utils/models'

const TableWrapper = styled.div`
  display: inline-block;
  float: left;
  width: 100%;
  margin-right: 40px;
`
const TableHeaderRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
`
const HeaderText = styled.h5`
  font-family: Nunito;
  font-weight: 200;
  font-size: 14px;
  color: #4f4f4f;
  width: 185px;
`

const HeaderDivider = styled.div`
  width: 100%;
  height: 21px;
  border-top: 2px solid #c4c4c4;
  background: #ebeaea;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 0.5px solid #c4c4c4;
  padding-top: 23px;
  padding-bottom: 18px;
`

const Cell = styled.div`
  font-family: Roboto;
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;
  width: 185px;
  & p {
    margin: 0;
  }
`
const SummaryRow = ({ accountNumber, address, newAddress, finalBalance, nickName }) => {
  return (
    <Row>
      <Cell>{accountNumber}</Cell>
      <Cell>{nickName}</Cell>
      <Cell>{finalBalance < 0 ? '$0.00' : parseDollars(finalBalance)}</Cell>
      <Cell>
        <p>{address}</p>
      </Cell>
      <Cell>
        <p>{newAddress}</p>
      </Cell>
    </Row>
  )
}

const MoveOutSummaryTableComponent = ({ summary }) => {
  return (
    <TableWrapper>
      <TableHeaderRow>
        <HeaderText>Account Number</HeaderText>
        <HeaderText>Nickname</HeaderText>
        <HeaderText>Current Balance</HeaderText>
        <HeaderText>Service Address</HeaderText>
        <HeaderText>Final Billing Address</HeaderText>
      </TableHeaderRow>
      <HeaderDivider />
      {summary &&
        summary.map((account) => (
          <SummaryRow
            key={account[OBJECT_VARS.accountKey]}
            accountNumber={account[OBJECT_VARS.accountNumber]}
            newAddress={account[OBJECT_VARS.transferringToAddress]}
            address={account.address}
            finalBalance={account[OBJECT_VARS.finalBalance]}
            nickName={account[OBJECT_VARS.nickName]}
          />
        ))}
    </TableWrapper>
  )
}

export { MoveOutSummaryTableComponent }
