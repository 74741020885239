import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { FaInfo } from 'react-icons/fa'
import { PropTypes } from 'prop-types'
import { Tooltip } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

// STYLE THEMES
const errorTheme = css`
  background: ${({ theme }) => theme.error};
  border-color: ${({ theme }) => theme.error};

  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.errorBright};
    border-color: ${({ theme }) => theme.errorBright};
  }

  &:hover svg,
  &:active svg,
  &:focus svg {
    fill: ${({ theme }) => theme.errorBright};
  }
  & svg {
    color: ${({ theme }) => theme.error};
  }
`

const warningTheme = css`
  background: ${({ theme }) => theme.warning};
  border-color: ${({ theme }) => theme.warning};

  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.warningBright};
    border-color: ${({ theme }) => theme.warningBright};
  }

  &:hover svg,
  &:active svg,
  &:focus svg {
    fill: ${({ theme }) => theme.warningBright};
  }
  & svg {
    color: ${({ theme }) => theme.warning};
  }
`

const primaryTheme = css`
  background: ${({ theme }) => theme.info};
  border-color: ${({ theme }) => theme.info};

  &:hover svg {
    fill: ${({ theme }) => theme.infoBright};
  }
`

const renderTooltip = (props) => <Tooltip {...props}>View account details</Tooltip>

const InfoButton = styled(Button)`
  margin-bottom: 5px;
  padding: 0.2rem 0.5rem;
  ${() => primaryTheme}
`

const GridCardInfoButton = ({ accountStatus, accountKey, showScheduled, dueDate }) => {
  return (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <Link to={`/history/${accountKey}`} onClick={(e) => e.stopPropagation()} className="mt-auto">
        <InfoButton accountStatus={accountStatus} showScheduled={showScheduled}>
          <FaInfo className="info-icon" size="1.2em" />
        </InfoButton>
      </Link>
    </OverlayTrigger>
  )
}

GridCardInfoButton.propTypes = {
  accountStatus: PropTypes.string.isRequired,
  accountKey: PropTypes.number.isRequired,
  showScheduled: PropTypes.bool,
}

GridCardInfoButton.defaultProps = {
  showScheduled: null,
}

export { GridCardInfoButton }
