import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead'
import { Link } from 'react-router-dom'
import { Label, Input } from '@/components/common/Input'
import { IconComponent } from '@/components/common/IconComponent'
import { ACCOUNT_ICONS, ACCOUNT_DISPLAY_ICONS } from '@/utils/icons'
import { asArray, kebabToCamel, snakeToCamel } from '@/utils/utils'
import selectors from '@/redux/selectors'
import { OBJECT_VARS } from '@/utils/models'
import { useAccountApi } from '@/redux/reducers'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { FormError } from '@/components/common/FormError'
import { useQueryClient } from 'react-query'
import { Card } from '@progress/kendo-react-layout'
import { Button } from '@progress/kendo-react-buttons'
import { Typography } from '@progress/kendo-react-common'
import { Tooltip } from '@material-ui/core'
import { ConfirmDeleteButtonComponent } from '../../common/ConfirmDeleteButtonComponent'
import { useAccounts } from '../../../hooks/useAccounts.queries'

const CenterButton = styled.div`
  text-align: center;
  padding: 0 40px;
`

const SetIconContainer = styled.div`
  background: #ffffff;
  padding: 8px;
`

const IconItemWrapper = styled.div`
  position: relative;
  display: inline-block;
  border: ${({ selected }) => (selected ? '3px solid #3250b3' : '3px solid #FFFFFF')};
  border-radius: 100%;
  height: 75px;
  width: 68px;
  padding: 6px;
  text-align: center;
`

const IconButton = styled(IconComponent)`
  background: none;
  height: 100%;
  width: 100%;
  color: ${({ selected }) => (selected ? 'rgba(50, 80, 179, 0.74)' : '#3250b3')};
  margin-right: 0;
`

const IconSelectedText = styled.h5`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
  position: absolute;
  top: 26px;
  color: #ffffff;
  margin: 0;
  font-size: 15px;
  text-shadow: -1px -1px 0 #3250b3, 1px -1px 0 #3250b3, -1px 1px 0 #3250b3, 1px 1px 0 #3250b3;
`

const SelectIconItem = ({ icon, size, selected, setSelected }) => {
  const isSelected = snakeToCamel(selected) === snakeToCamel(icon.iconName)
  return (
    <Tooltip title={ACCOUNT_DISPLAY_ICONS[kebabToCamel(icon.iconName)]?.description}>
      <IconItemWrapper selected={isSelected} onClick={() => setSelected(icon.iconName)}>
        <IconSelectedText selected={isSelected}>Selected</IconSelectedText>
        <IconButton icon={icon} size={size} selected={isSelected} />
      </IconItemWrapper>
    </Tooltip>
  )
}

const renderOptions = (account) => {
  const address = `${account[OBJECT_VARS.serviceAddress]} ${account[OBJECT_VARS.subDesignation]} `
  const nickname = account[OBJECT_VARS.nickName]
  return nickname || address
}

const AccountManagement = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const ref = React.createRef()
  const [selectedIcon, setSelectedIcon] = React.useState(null)
  const [selectedAccountKey, setSelectedAccountKey] = React.useState('')
  const [newNickname, setNewNickname] = React.useState('')

  const accountsQuery = useAccounts()
  const accounts = accountsQuery.data
  const iconArray = asArray(ACCOUNT_ICONS)
  const isUpdatingAccount = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAccountApi.fetchUpdateAccountRequest.type)
  )
  const updateError = useSelector((state) =>
    selectors.ui.getError(state, useAccountApi.fetchUpdateAccountRequest.type)
  )
  const deleteError = useSelector((state) =>
    selectors.ui.getError(state, useAccountApi.fetchUnlinkAccountRequest.type)
  )
  const updateSuccessful = useSelector((state) =>
    selectors.ui.getFetchSuccessful(state, useAccountApi.fetchUpdateAccountRequest.type)
  )
  const deleteSuccessful = useSelector((state) =>
    selectors.ui.getFetchSuccessful(state, useAccountApi.fetchUnlinkAccountRequest.type)
  )
  const unlinkAccountIsFetching = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAccountApi.fetchUnlinkAccountRequest.type)
  )
  const handleSelectAccount = (option) => {
    if (option.length > 0) {
      setSelectedAccountKey(option[0][OBJECT_VARS.accountKey])
      setNewNickname(option[0][OBJECT_VARS.nickName] || '')
      setSelectedIcon(option[0][OBJECT_VARS.icon])
    }
    return null
  }

  const clearFields = (onClear) => {
    if (onClear) {
      onClear()
    }
    ref.current.clear()
    setSelectedIcon(null)
    setNewNickname('')
    setSelectedAccountKey('')
  }
  const handleUpdateAccount = () => {
    const account = accounts.filter((a) => a.accountKey === selectedAccountKey)[0]
    dispatch(
      useAccountApi.fetchUpdateAccountRequest({
        account,
        newNickname,
        newIcon: selectedIcon,
      })
    )
  }

  React.useEffect(() => {
    if (updateSuccessful || deleteSuccessful) {
      clearFields()
      queryClient.refetchQueries('accounts')
    }
  })

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="500" isVisible>
            <Card className="m-4 p-4" style={{ 'max-width': '450px' }}>
              <Typography.h5 className="text-center">Select SAWS Account</Typography.h5>
              <Label label="choose account" variant="light" fontSize="12px" />
              <Typeahead
                id="type-ahead"
                ref={ref}
                onChange={handleSelectAccount}
                labelKey={renderOptions}
                options={accountsQuery.isLoading ? [] : accounts}
                placeholder={accountsQuery.isLoading ? 'Loading accounts...' : 'Select account..'}
              >
                {({ onClear, selected }) => (
                  <div className="rbt-aux">
                    {!!selected.length && <ClearButton onClick={() => clearFields(onClear)} />}
                    {accountsQuery.isLoading || !accounts ? (
                      <Spinner animation="grow" size="sm" />
                    ) : null}
                  </div>
                )}
              </Typeahead>
              <Label label="set account nickname" variant="light" fontSize="12px" />
              <Input
                type="text"
                placeholder="Type here..."
                value={newNickname}
                onChange={(e) => setNewNickname(e.target.value)}
              />

              <Label label="set icon for this account" variant="light" fontSize="12px" />
              <SetIconContainer>
                {iconArray.map((icon) => (
                  <SelectIconItem
                    key={icon.iconName}
                    label={icon.displayName}
                    icon={icon}
                    size="2.5em"
                    selected={selectedIcon}
                    setSelected={setSelectedIcon}
                  />
                ))}
              </SetIconContainer>
              <div>
                <hr />
              </div>
              <FormError>
                {deleteError && deleteError.message}
                {updateError && updateError.message}
              </FormError>
              <CenterButton>
                <Button
                  className="btn-myc-col"
                  onClick={handleUpdateAccount}
                  disabled={isUpdatingAccount || !selectedAccountKey}
                >
                  {isUpdatingAccount ? 'Updating' : 'Update This Account'}
                </Button>
              </CenterButton>
              <CenterButton>
                <ConfirmDeleteButtonComponent
                  disabled={!selectedAccountKey}
                  isFetching={unlinkAccountIsFetching}
                  onClick={() =>
                    dispatch(useAccountApi.fetchUnlinkAccountRequest(selectedAccountKey))
                  }
                />
              </CenterButton>
            </Card>
          </Animated>
        </div>
        <div className="col-lg-4">
          <div
            className="m-4"
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              borderLeft: '1px solid lightgray',
            }}
          >
            <Animated
              className="ml-3"
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDelay="500"
              isVisible
            >
              <Link className="p-2" to="/accounts/create" alt="Add new account">
                <Button className="btn-myc-col">Add New Account </Button>
              </Link>
            </Animated>
          </div>
        </div>
      </div>
    </>
  )
}

export { AccountManagement }
