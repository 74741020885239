import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const TecqAlertMessage = () => {
  return (
    <div>
      <p>Dear Customer:</p>
      <p>
        SAWS does not have the completed Customer Service Inspection Certification (CSIC) form and
        your request cannot be processed until received.
      </p>
      <p>Please submit yours via e-mail, or fax (210) 233-4749.</p>
      <p>
        The TCEQ forms are processed as soon as they are received, the information will be updated
        within 24-48 hour, and you will receive an e-mail.{' '}
      </p>
      <p>If you have any additional questions in regards to the TCEQ form call 210-233-2421.</p>
    </div>
  )
}

const MoveOutAlertModal = ({ data, children, show, hide }) => {
  const { hasTCEQAlert, hasCUSFSActivity, moveOutInProgress, canMoveOut } = data
  return (
    <>
      {children}
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>This account cannot be moved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {hasTCEQAlert && <TecqAlertMessage />}
          {(hasCUSFSActivity || moveOutInProgress) &&
            'This account has a service request in process. Please contact (210) 704-SAWS (704-7297) or wait for the request to be completed'}

          {!canMoveOut &&
            !hasTCEQAlert &&
            !(hasCUSFSActivity || moveOutInProgress) &&
            'Please contact (210) 704-SAWS (704-7297) to submit a moveout request.'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

MoveOutAlertModal.propTypes = {
  data: PropTypes.shape({
    hasTCEQAlert: PropTypes.bool,
    hasCUSFSActivity: PropTypes.bool,
    moveOutInProgress: PropTypes.bool,
    canMoveOut: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
}

export { MoveOutAlertModal }
