import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import bg from '@/images/Sidebar-bg.png'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Box } from '@/components/common/Box'
import { IconComponent } from '@/components/common/IconComponent'
import { Link, useLocation } from 'react-router-dom'
import { PayMyAccountsButtonComponent } from './PayMyAccountsButtonComponent'

const NavContainer = styled(Box)`
  flex: 1;
  background: url(${bg});
`

const Links = styled(Box)`
  align-items: center;
  height: 100%;
`

const NavLink = styled(Link)`
  font-style: normal;
  font-weight: ${({ activeRoute }) => (activeRoute ? 'bold' : '200')};
  font-size: 1.3em;
  line-height: 1.8em;
  color: #fff;
  text-decoration: none;
  width: 250px;
  &:hover {
    text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
    color: #fff;
    text-decoration: none;
  }
`

const BottomLink = styled(NavLink)`
  font-size: 1em;
  margin: auto 0 0 0;
  padding: 0;
  width: 71%;
  text-align: center;
`

//* This wraps each MenuItem child component inside of html to display on desktop view (side menu). *//
//* See the SITEMAP to add pages to the menu
const MenuItemsForDesktopView = ({ sitemap }) => {
  const location = useLocation()

  return (
    <>
      {sitemap.map((link) => {
        // Do not render My Account for Business link here
        if (link === sitemap[sitemap.length - 1] && sitemap.length > 3) {
          return null
        }
        return (
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
            <NavLink
              activeRoute={location.pathname === link.href}
              key={uuid()}
              to={link.href || ''}
              alt={link.alt || ''}
            >
              <IconComponent width="30px" icon={link.icon} />
              {link.page}
            </NavLink>
          </Animated>
        )
      })}
    </>
  )
}

MenuItemsForDesktopView.propTypes = {
  sitemap: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.string,
      icon: PropTypes.func,
      href: PropTypes.string,
      alt: PropTypes.string,
    })
  ).isRequired,
}

export const DesktopNav = (sitemap) => {
  const isAuth = useSelector((state) => state.authentication.isAuthenticated)
  return (
    <NavContainer padding={['20px', '0', '40px', '0']} flexTop>
      <Links flexDirection="column">
        <Box flexDirection="column">{MenuItemsForDesktopView(sitemap)}</Box>
        {isAuth && <PayMyAccountsButtonComponent />}
        {isAuth && <BottomLink key={uuid()}>My Account for Business BETA</BottomLink>}
      </Links>
    </NavContainer>
  )
}
