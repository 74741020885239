import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { parseDollars } from '@/utils/utils'
import { MycButton } from '@/components/common/Button'
import { getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { useMultiplePayments } from '@/hooks/usePayments.queries'
import { OBJECT_VARS } from '../../../utils/models'
import { loadFromLocalStorage } from '@//token'

// ---------------------- Styled Components ------------------------ //

const Container = styled.div`
  display: block;
  position: -webkit-sticky;
  position: fixed;
  bottom: 0;
  left: 45%;
  padding: 0 10px;
  background: #ffffff;
  border: 2px solid #0078ae;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  width: auto;
  height: 80px;
  margin-bottom: 70px;
`

const Button = styled(MycButton)`
  width: 135px;
  color: #fff;
  border: none;
  margin: 10px;
  padding: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  margin-bottom: ${({ bottomGutter }) => bottomGutter};
  background: ${({ variant, theme }) =>
    (variant === 'success' && theme.success) ||
    (variant === 'primary' && theme.primary) ||
    (variant === 'info' && theme.info)};

  & a:hover {
    text-decoration: none;
  }
`

const SuccessButton = styled(Button)`
  background: ${({ theme }) => theme.success};
  &:hover {
    background: ${({ theme }) => theme.successDark};
  }
`

const Total = styled.span`
  color: ${({ theme }) => theme.success};
  font-weight: 800;
`

const AccountsSelected = styled.span`
  float: right;
  margin: 20px 10px 20px 15px;
  padding: 0 0 0 15px;
  line-height: 40px;
  border-left: solid 1px;
  font-weight: bold;
`

// --------------------------------------------------------- //

const useGetTotalBalanceOfSelectedAccounts = (keys) => {
  const accountkeyarr = keys.length > 0 ? keys : []
  const paymentsQuery = useMultiplePayments()
  const payments = paymentsQuery.filter((el) => el.isSuccess === true).map((el) => el.data[0])
  const paymentDataForSelectedAccounts = payments.filter(
    (el) => accountkeyarr.indexOf(el.accountKey) >= 0
  )
  if (!paymentDataForSelectedAccounts) return 0

  return paymentDataForSelectedAccounts.reduce((init, account) => {
    let balance = account[OBJECT_VARS.finalBalance]
    if (balance < 0) {
      balance = 0
    }
    return Number(parseFloat(init) + parseFloat(balance)).toFixed(2)
  }, 0)
}

const CheckoutModal = () => {
  const selectedAccountKeys = useSelector(getSelectedAccountKeys)
  const total = useGetTotalBalanceOfSelectedAccounts(selectedAccountKeys)
  const parseTotal = total && parseDollars(total)
  const [impersonate, setImpersonate] = React.useState(false)
  React.useEffect(() => {
    const { isImpersonating } = loadFromLocalStorage('token') || {}
    setImpersonate(isImpersonating)
  }, [])

  return (
    <Container>
      <Link
        to="/payment"
        className="text-white"
        onClick={(e) => selectedAccountKeys.length === 0 && e.preventDefault()}
      >
        <SuccessButton
          disabled={selectedAccountKeys.length === 0 || impersonate === true}
          bottomGutter="13px"
        >
          PAY BILL
        </SuccessButton>
      </Link>
      <Total>Total Due {total === Number(0) ? '$0.00' : parseTotal}</Total>
      <AccountsSelected>{selectedAccountKeys.length} accounts selected</AccountsSelected>
    </Container>
  )
}

export { CheckoutModal }
