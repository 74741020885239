import React from 'react'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import { Transition, TransitionGroup } from 'react-transition-group'
import { NotificationListItemComponent } from './NotificationListItemComponent'

const Fade = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
`

const NotificationsListComponent = ({
  selectNotification,
  selectedNotification,
  expireNotification,
  notifications,
  animate,
}) => {
  const { inValue, setInValue } = animate
  return (
    <TransitionGroup>
      {notifications.map((notification) => {
        const selected =
          selectedNotification &&
          selectedNotification[OBJECT_VARS.messageId] === notification[OBJECT_VARS.messageId]
        return (
          <Transition key={notification[OBJECT_VARS.messageId]} timeout={500} in={inValue}>
            {(state) => (
              <Fade state={state}>
                <NotificationListItemComponent
                  id={notification[OBJECT_VARS.messageId]}
                  notification={notification}
                  onClick={selectNotification}
                  isSelected={selected}
                  expireNotification={expireNotification}
                  animateOut={setInValue}
                />
              </Fade>
            )}
          </Transition>
        )
      })}
    </TransitionGroup>
  )
}

export { NotificationsListComponent }
