import React from 'react'
import styled from 'styled-components'
import { getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { useSelector } from 'react-redux'
import { Animated } from 'react-animated-css'
import { MoveOutFormComponent } from './MoveOutFormComponent'
import { MoveOutScheduleComponent } from './MoveOutScheduleComponent'
import { MoveOutSelectedAddressesList } from './address-cards/MoveOutSelectedAddressesList.component'
import { useCheck } from './useCheck.hook'
import { MoveOutSameAddressCheckbox } from './MoveOutSameAddressCheckbox.component'
import { useCheckboxFormConnector } from './useCheckboxFormConnector.hook'
import { MoveOutNavButton } from '../common/MoveOutNavButton.component'
import { MoveOutPageWrapper } from '../common/MoveOutPageWrapper.component'

const Container = styled.div`
  margin-left: 10px;
  padding-right: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`

const Title = styled.h2`
  color: #4f4f4f;
  font-family: Nunito;
  font-weight: 700;
  font-size: 30px;
  align-self: flex-start;
`

const SawsImgWrapper = styled.div`
  margin-top: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-left: 15px;
`

const ScheduleWrapper = styled(SawsImgWrapper)`
  height: 120px;
  padding: 15px;
`

const LeftCol = styled.div`
  flex-basis: 40%;
`
const RightCol = styled.div`
  flex-basis: 60%;
`

const MoveOutEditPage = ({ getAddresses, moveOutDate, setMoveOutDate, serviceRequestMutation }) => {
  const { mutate, isFetching } = serviceRequestMutation
  const selectedAccountKeys = useSelector(getSelectedAccountKeys)
  const [selected, setSelected] = React.useState(
    selectedAccountKeys.length > 0 ? selectedAccountKeys[0] : null
  )
  const [disablesubtn, setdisablesubtn] = React.useState(false)
  const { addresses, applySameAddressToAllAccounts, isSuccess } = getAddresses
  const { checkState, isUsingSingleAddress, isDirty, checkActions } = useCheck(selectedAccountKeys)
  useCheckboxFormConnector(selected, getAddresses, {
    isUsingSingleAddress,
    checkActions,
    checkState,
  })

  React.useEffect(() => {
    const formValues =
      getAddresses.addresses !== null
        ? getAddresses.addresses[getAddresses.queries[0].data.accountKey]
        : []

    if (formValues === undefined) return

    if (
      formValues.name == '' ||
      formValues.taxId == '' ||
      formValues.transferringToAddress == '' ||
      formValues.transferringToCity == '' ||
      formValues.transferringToState == '' ||
      formValues.transferringZipCode == ''
    ) {
      setdisablesubtn(true)
    } else {
      setdisablesubtn(false)
    }
  }, [getAddresses])

  return (
    <>
      <MoveOutPageWrapper>
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible>
          <Container>
            <LeftCol>
              <MoveOutSameAddressCheckbox
                useCheck={{ checkActions, isDirty, isUsingSingleAddress }}
              />
              <hr />
              <Title>Individual Accounts</Title>
              <MoveOutSelectedAddressesList
                useCheck={{ checkState, checkActions }}
                onSelect={setSelected}
                selected={selected}
              />
            </LeftCol>
            <RightCol>
              <ScheduleWrapper className="card card-shadow">
                <MoveOutScheduleComponent
                  moveOutDate={moveOutDate}
                  setMoveOutDate={setMoveOutDate}
                />
              </ScheduleWrapper>
              <SawsImgWrapper className="card card-shadow">
                {selected &&
                  getAddresses.queries.map((query) => {
                    if (!query.isSuccess) return null
                    const { accountKey } = query.data
                    if (accountKey !== selected) return null
                    return (
                      <MoveOutFormComponent
                        isVisible={selected === accountKey}
                        key={accountKey}
                        getAddresses={getAddresses}
                        accountKey={accountKey}
                      />
                    )
                  })}
              </SawsImgWrapper>
            </RightCol>
          </Container>
        </Animated>
      </MoveOutPageWrapper>
      <MoveOutNavButton
        variant="warning"
        disabled={!isSuccess || isFetching || disablesubtn}
        onClick={async () =>
          isUsingSingleAddress
            ? mutate({ addresses: applySameAddressToAllAccounts(selected), moveOutDate })
            : mutate({ addresses, moveOutDate })
        }
        buttonText="submit"
      >
        {!isFetching ? 'Submit' : 'Submitting'}
      </MoveOutNavButton>
    </>
  )
}

export { MoveOutEditPage }
