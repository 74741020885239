import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FaUserAlt, FaChevronDown } from 'react-icons/fa'
import { OBJECT_VARS } from '@/utils/models'
import useNotifications from '../../pages/MessageCenter/useNotifications.queries'

const MenuText = styled.h6`
  position: relative;
  margin-left: 5px;
  margin-right: 20px;
  display: inline;
  user-select: none;
  color: #ffffff;
`

const UserIconWrapper = styled.div`
  position: relative;
`

const NotificationPip = styled.div`
  background: ${({ theme }) => theme.errorBright};
  position: absolute;
  color: #ffffff;
  border-radius: 100%;
  padding: 9px 1px;
  font-size: 10px;
  line-height: 2px;
  height: 19px;
  width: 21px;
  bottom: 16px;
  right: 6px;
  text-align: center;
`

const UserIconMenu = ({ username, isMenuOpen }) => {
  const { data } = useNotifications({
    select: (notifications) =>
      notifications.filter(
        (notification) =>
          notification[OBJECT_VARS.expirationDate] === null &&
          notification[OBJECT_VARS.acknowledgeDate] === null
      ).length,
  })

  return (
    <UserIconWrapper>
      {data !== null ? (
        <Link to="/messagecenter" onClick={(e) => e.stopPropagation()}>
          <NotificationPip>{data}</NotificationPip>
        </Link>
      ) : null}
      {isMenuOpen ? (
        <FaChevronDown style={{ height: '23px' }} />
      ) : (
        <FaUserAlt style={{ height: '23px' }} />
      )}
      <MenuText>Hello, {username}</MenuText>
    </UserIconWrapper>
  )
}

UserIconMenu.propTypes = {
  username: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
}

export { UserIconMenu }
