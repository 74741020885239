import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { IconComponent } from '@/components/common/IconComponent'
import { ICONS } from '@/utils/icons'

const GreenDot = styled.div`
  background: ${({ theme }) => theme.success};
  height: 10px;
  width: 10px;
  border-radius: 100%;
`

const MoveOutAlertIcon = ({ hasAlert, isLoading }) => {
  if (isLoading) {
    return <Skeleton width={50} />
  }
  if (hasAlert) {
    return <IconComponent icon={ICONS.ban} />
  }
  return <GreenDot />
}

MoveOutAlertIcon.propTypes = {
  hasAlert: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export { MoveOutAlertIcon }
