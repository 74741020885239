import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Animated } from 'react-animated-css'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getUserByEmail, getUserByUsername } from '@/redux/api'
import { BaseFormComponent } from '@/components/common/BaseFormComponent'
import selectors from '@/redux/selectors'
import { useAuthApi } from '@/redux/reducers'

const CreateAccountButton = styled(Button)`
  background: ${({ theme }) => theme.primary};
  color: fff;
  border-color: transparent;
  width: 75%;
  padding: 0.8em;
  &:hover,
  &:active,
  &:focus {
    background-color: #999999 !important;
    color: #fff

    border-color: transparent;
  }
  &:disabled {
    background: #a9a9a9;
    border: none;
    cursor: not-allowed;
  }
`

const CreateAccountForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [validatingEmail, setValidatingEmail] = React.useState(false)
  const [validatingUsername, setValidatingUsername] = React.useState(false)

  const {
    handleSubmit,
    errors,
    control,
    clearErrors,
    getValues,
    register,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
  } = useForm({
    mode: 'onBlur',
  })

  const verifyUniqueUsername = async (value) => {
    if (value === '') {
      return true
    }
    // Clear errors when user corrects input
    if (isDirty) {
      clearErrors('username')
    }
    setValidatingUsername(true)
    const result = await getUserByUsername(value)
    setValidatingUsername(false)
    if (result !== 0) {
      return 'User name is in use'
    }
    return true
  }

  const verifyUniqueEmail = async (value) => {
    if (value === '') {
      return true
    }
    // Clear errors when user corrects input
    if (isDirty) {
      clearErrors('email')
    }
    setValidatingEmail(true)
    const result = await getUserByEmail(value)
    setValidatingEmail(false)
    if (result !== 0) {
      return 'Email is in use'
    }
    return true
  }
  const isFetchingCreateNewUser = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAuthApi.fetchCreateUserRequest.type)
  )
  const createNewUserSuccess = useSelector((state) =>
    selectors.ui.getFetchSuccessful(state, useAuthApi.fetchCreateUserRequest.type)
  )
  const onSubmit = (data) => {
    clearErrors()
    dispatch(useAuthApi.fetchCreateUserRequest(data))
  }

  React.useEffect(() => {
    if (createNewUserSuccess) {
      dispatch(useAuthApi.fetchLogInSuccess())
      history.push('/')
    }
  }, [createNewUserSuccess])

  const formData = [
    {
      name: 'firstname',
      label: 'first name',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
        minLength: { value: 2, message: 'First name should have a minimum of 2 characters' },
      },
    },
    {
      name: 'lastname',
      label: 'last name',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
        minLength: { value: 2, message: 'Last name should have a minimum of 2 characters' },
      },
    },
    {
      name: 'mobilenumber',
      label: 'mobile number',
      type: 'number',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
        maxLength: { value: 12, message: 'Phone number should have a maximum of 12 characters' },

        pattern: {
          value: /^-?[0-9]\d*\.?\d*$/,
          message: 'only numbers allowed',
        },
      },
    },
    {
      name: 'jobTitle',
      label: 'job title',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
    {
      name: 'companyName',
      label: 'company name',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },

    {
      name: 'taxid',
      label: 'tax id',
      defaultValue: '',
      rules: {
        required: { value: false, message: '' },
        pattern: {
          value: /^[0-9]{2}-[0-9]{7}$/,
          message: 'Tax Id must be 9 digits',
        },
      },
      inputMode: 'numeric',
      isMask: true,
      maskPlaceholder: '99-9999999',
      mask: '99-9999999',
      legend:
        'Also known as Employer Identification Number assigned by the IRS to identify a business entity',
    },
    {
      name: 'username',
      label: 'user name',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
        maxLength: { value: 16, message: 'User name should have a maximum of 16 characters' },
        validate: verifyUniqueUsername,
        pattern: {
          value: /^\S+$/,
          message: 'space not allowed',
        },
        showAsyncMessage: validatingUsername,
        asyncMessage: 'Validating unique user name',
      },
    },
    {
      name: 'password',
      label: 'password',
      type: 'password',
      autoComplete: false,
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
        minLength: { value: 8, message: 'Password should have a minimum of 8 characters' },
        maxLength: { value: 16, message: 'Password should have a maximum of 16 characters' },
        pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
          message:
            'Password must have between 8 and 16 characters, include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character',
        },
      },
    },
    {
      name: 'retypepassword',
      label: 'retype password',
      type: 'password',
      autoComplete: false,
      defaultValue: '',
      rules: {
        validate: (value) => (getValues('password') === value ? true : 'Passwords must match'),
      },
    },
    {
      name: 'email',
      label: 'email',
      defaultValue: '',
      type: 'email',
      rules: {
        required: { value: true, message: 'This field is required' },
        validate: verifyUniqueEmail,
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: 'Email must be valid',
        },
      },
      showAsyncMessage: validatingEmail,
      asyncMessage: 'Verifying email is not in use',
    },
    {
      name: 'retypeemail',
      label: 'retype email',
      type: 'email',
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
        validate: (value) => (getValues('email') === value ? true : 'Email addresses must match'),
      },
    },
    {
      name: 'secretquestion',
      label: 'password recovery question',
      defaultValue: 1,
      bottomGutter: 2,
      input: {
        select: {
          options: [
            { displayLabel: "What is your mother's maiden name?", value: 1, key: '1' },
            { displayLabel: "What is your pet's name? ", value: 2, key: '2' },
            { displayLabel: 'What city or town were you born in? ', value: 3, key: '3' },
            { displayLabel: "Who's your favorite superhero? ", value: 4, key: '4' },
            { displayLabel: "What's your favorite food?", value: 5, key: '5' },
          ],
        },
      },
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
    {
      name: 'secretanswer',
      label: 'password recovery answer',
      autoComplete: false,
      defaultValue: '',
      rules: {
        required: { value: true, message: 'This field is required' },
      },
    },
  ]
  return (
    <>
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible />
      <BaseFormComponent
        data={formData}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        isSubmitSuccessful={isSubmitSuccessful}
        onSubmit={onSubmit}
        variant="dark"
        bottomGutter={1}
        register={register}
      >
        <div style={{ textAlign: 'center' }}>
          <CreateAccountButton
            type="submit"
            gutterBottom={2}
            disabled={isSubmitting || isFetchingCreateNewUser}
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </CreateAccountButton>
        </div>
      </BaseFormComponent>
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible />
    </>
  )
}

export { CreateAccountForm }
