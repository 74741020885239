import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { parseDollars } from '@/utils/utils'
import { usePaymentApi } from '@/redux/reducers'
import { AccountTableEditAmountComponent } from './AccountTableEditAmountComponent'

const Row = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.text}`};
  display: flex;
  justify-content: space-between;
  font-size: 15px;
`

const DeleteButton = styled.button`
  color: red;
  font-size: 15px;
  font-family: 'Nunito';
  background: none;
  border: none;
  &:active {
    outline: none;
  }
`

const Cell = styled.div`
  position: relative;
  text-align: left;
  margin-right: 10px;
  margin-left: 13px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 11px;
  padding-bottom: 9px;
  color: #4f4f4f;
  min-width: 150px;
  width: 100%;
  &:first-of-type {
    flex: 1 0 7%;
  }
  &:nth-of-type(2) {
    flex: 1;
  }
  &:nth-of-type(3) {
    flex: 1;
  }
`

const Value = styled.span`
  & input {
    &:nth-of-type(2) {
      text-align: right;
    }
  }
`

const AccountTableAccountRowComponent = ({ account }) => {
  const dispatch = useDispatch()
  const { owed, accountNumber, accountKey, nickName, serviceAddressLine, subDesignation } = account
  const accountIdentifier = nickName || `${serviceAddressLine} ${subDesignation}`

  const handleRemoveRow = () => {
    dispatch(usePaymentApi.removePayment({ key: accountKey }))
  }

  return (
    <Row key={accountNumber}>
      <DeleteButton type="button" onClick={handleRemoveRow}>
        X
      </DeleteButton>
      <Cell>
        <Value>{accountIdentifier}</Value>
      </Cell>
      <Cell>
        <Value>{parseDollars(owed) || '$0.00'}</Value>
      </Cell>
      <Cell>
        <Value>
          <AccountTableEditAmountComponent account={account} accountKey={accountKey} />
        </Value>
      </Cell>
    </Row>
  )
}

AccountTableAccountRowComponent.propTypes = {
  account: PropTypes.shape({}).isRequired,
}

export { AccountTableAccountRowComponent }
