import { normalize, schema } from 'normalizr'
import { OBJECT_VARS } from '@/utils/models'
import { ACCOUNT_ICONS } from '@/utils/icons'

const formatCurrency = (num) => {
  return new Intl.NumberFormat(undefined, {
    style: 'decimal',
    minimumFractionDigits: 2,
  }).format(num)
}

export const parseDollars = (x) => {
  if (Number.isNaN(x)) {
    return null
  }
  return `$${formatCurrency(x)}`
}

export const chunkArray = (array, size) => {
  const chunkedArr = []
  const copied = [...array]
  const numOfChild = Math.ceil(copied.length / size)
  for (let i = 0; i < numOfChild; i += 1) {
    chunkedArr.push(copied.splice(0, size))
  }
  return chunkedArr
}

export const initializeBatchInfo = (num) => {
  const batches = []
  for (let i = 1; i <= num; i += 1) {
    batches.push({ id: i, loadComplete: false, errors: null })
  }
  return batches
}

export const readNormalizedObjAsArray = (obj) => {
  const arr = []
  Object.values(obj).forEach((value) => {
    arr.push(value)
  })
  return arr
}

// Removed Ordinals for now, this is a placeholder if they want them back

function getOrdinalNum(n) {
  return n + (n > 0 ? ['', '', '', ''][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '')
}

export const formatDate = (date) => {
  if (!date) {
    return null
  }
  const d = new Date(date)
  const month = `${d.getMonth() + 1}`
  const day = `${d.getDate()}`
  const year = d.getFullYear()

  // if (month.length < 2) month = `0${month}`
  // if (day.length < 2) day = `0${day}`

  return [month, day, year].join('/')
}

export const formatLongDate = (date, long) => {
  const d = new Date(date)
  const month = d.toLocaleDateString('default', { month: long ? 'long' : 'short' })
  const day = `${getOrdinalNum(d.getDate())},`
  const year = d.getFullYear()

  return [month, day, year].join(' ')
}

export const extractValuesFromArray = (arr, key) => {
  return arr.map((el) => el[key])
}

export const dateFilter = (arr, start, end) => {
  const startDate = new Date(start).getTime()
  const endDate = new Date(end).getTime()
  const filtered = arr.filter((el) => {
    const dueDate = new Date(el[OBJECT_VARS.dueDate]).getTime()
    return dueDate >= startDate && dueDate <= endDate
  })
  const ids = extractValuesFromArray(filtered, OBJECT_VARS.accountKey)
  return ids
}

export const filterByVal = (arr, key, value) => {
  const filtered = arr.filter((el) => el[key] === value.toLowerCase())
  const ids = extractValuesFromArray(filtered, OBJECT_VARS.accountKey)
  return ids
}

export const filterValuesBetween = (arr, values) => {
  const min = values[0]
  const max = values[1]
  const filtered = arr.filter((el) => {
    const amountDue = el[OBJECT_VARS.finalBalance]
    return amountDue >= min && amountDue <= max
  })
  const ids = extractValuesFromArray(filtered, OBJECT_VARS.accountKey)
  return ids
}

export const generateIcon = () => {
  const icons = Object.keys(ACCOUNT_ICONS)
  const min = 0
  const max = icons.length - 1
  const randomIndex = Math.floor(Math.random() * (max - min + 1) + min)
  return icons[randomIndex]
}

// For transforming normalized objects
export const asArray = (obj) => {
  if (!obj) {
    return []
  }
  const arr = []
  Object.values(obj).forEach((value) => {
    arr.push(value)
  })
  return arr
}

export const normalizePayment = (data) => {
  const paymentSchema = new schema.Entity(
    'payment',
    {},
    {
      idAttribute: OBJECT_VARS.accountKey,
      processStrategy: (value) => {
        return {
          ...value,
          [OBJECT_VARS.accountStatus]: value[OBJECT_VARS.accountStatus].toLowerCase(),
          [OBJECT_VARS.dueDate]: formatDate(value[OBJECT_VARS.dueDate]),
        }
      },
    }
  )

  const paymentListSchema = [paymentSchema]
  return normalize(data, paymentListSchema)
}

// export const normalizeAccount = (data) => {
//   const accountSchema = new schema.Entity(
//     'accounts',
//     {},
//     {
//       idAttribute: OBJECT_VARS.accountKey,
//       processStrategy: (value) => {
//         return {
//           ...value,
//         }
//       },
//     }
//   )
//   const accountArraySchema = [accountSchema]
//   return normalize(data, accountArraySchema)
// }

export const normalizeConsumption = (data) => {
  const consumptionSchema = new schema.Entity('consumption', {}, { idAttribute: 'accountKey' })
  return normalize(data, consumptionSchema)
}

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ')
  const result = splitStr.reduce(
    (res, el) => res && `${res} ${el.charAt(0).toUpperCase()}${el.substring(1)}`,
    []
  )
  return result
}

export const snakeToCamel = (str) =>
  str ? str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase()) : null

export const kebabToCamel = (str) => (str ? str.replace(/-./g, (x) => x[1].toUpperCase()) : null)

export const normalizeArray = (key, arr) => {
  return arr.reduce((acc, el) => {
    return {
      ...acc,
      [el[key]]: el,
    }
  }, {})
}

export const mapDataToObjKey = (obj, data, keys) => {
  return keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: { ...obj[key], ...data[key] },
    }
  }, {})
}
