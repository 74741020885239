import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { getUserId, getUsername } from '@/redux/ducks/commonDuck'

const CANCEL_SCHEDULED_PAYMENT = `${process.env.REACT_APP_API_BASE_URL}/payment-processing-public-api/public/api/cancelscheduledpayment`

const cancelScheduledPayment = (req) => {
  return fetchWrapper(CANCEL_SCHEDULED_PAYMENT, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(req),
    requiresInfor: true,
  }).then((res) => {
    if (res.resultCode === 405) {
      throw res
    }
  })
}

const useCancelScheduledPayment = (key, options) => {
  const queryClient = useQueryClient()
  const x52Id = useSelector(getUserId)
  const username = useSelector(getUsername)

  return useMutation(
    (paymentId) =>
      toast.promise(
        cancelScheduledPayment({
          paymentId,
          x52Id,
          requestedBy: username,
        }),
        {
          loading: 'Canceling your scheduled payment. Please do not refresh the page.',
          success: () => (
            <>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <b>Your scheduled payment has been canceled.</b>
                </div>
              </div>
            </>
          ),
          error: (error) => error.resultDescription,
        },
        {
          style: { border: 'black', minWidth: '350px' },
          success: {
            style: { background: 'green', color: '#ffffff', minWidth: '350px' },
            duration: 8000,
          },
          error: {
            style: { background: 'red', color: '#ffffff', minWidth: '350px' },
            duration: 8000,
          },
        }
      ),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(['paymentHistory', key])
        queryClient.invalidateQueries(['payments', [key]])
      },
    }
  )
}

export { useCancelScheduledPayment }
