import React from 'react'
import styled, { css } from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import { formatDate } from '@/utils/utils'
import { PropTypes } from 'prop-types'
import { useQueryClient } from 'react-query'
import { useAcknowledgeNotification } from './useAcknowledgeNotification.queries'
import { useExpireNotification } from './useExpireNotification.queries'

const SEVERITY_LOW = 'Low'
const SEVERITY_MED = 'Med'
const SEVERITY_HIGH = 'High'

const SEVERITY_LOW_COLOR = '#3250b3'
const SEVERITY_MED_COLOR = '#ff9900'
const SEVERITY_HIGH_COLOR = '#ae0000'

const lowSeverityGradient = css`
  border-color: ${SEVERITY_LOW_COLOR};
`

const medSeverityGradient = css`
  border-color: ${SEVERITY_MED_COLOR};
`

const highSeverityGradient = css`
  border-color: ${SEVERITY_HIGH_COLOR};
`

const greyGradient = css`
  border-color: gray;
`
const fontbold = css`
  font-weight: bold;
`

const CardWrapper = styled.div`
  ${({ severity }) => severity === SEVERITY_LOW && lowSeverityGradient}
  ${({ severity }) => severity === SEVERITY_MED && medSeverityGradient}
${({ severity }) => severity === SEVERITY_HIGH && highSeverityGradient}
${({ isNew }) => !isNew && greyGradient}
${({ isSelected }) => isSelected && `box-shadow: 0px 0px 7px 5px lightblue;`}
`
const MCItemWrapper = styled.div`
  width: 100%;
  padding: 5px 20px;
  clear: both;
  margin: 10px 0;
  cursor: pointer;
  ${({ severity }) => severity === SEVERITY_LOW && lowSeverityGradient}
  ${({ severity }) => severity === SEVERITY_MED && medSeverityGradient}
  ${({ severity }) => severity === SEVERITY_HIGH && highSeverityGradient}
  ${({ isNew }) => isNew && greyGradient && fontbold}
`

const MCTileLeft = styled.div`
  float: left;
  width: 93%;
`
const MCTileRight = styled.div`
  float: left;
  width: 7%;
  cursor: pointer;
  text-align: center;
  margin-top: 7px;
`

const ClearFloat = styled.div`
  clear: both;
`

const NotificationListItemComponent = ({
  id,
  notification,
  onClick,
  isSelected,
  animateOut,
  ...rest
}) => {
  const queryClient = useQueryClient()
  const [isNew, setIsNew] = React.useState(
    notification && notification[OBJECT_VARS.acknowledgeDate] === null
  )
  const isExpired = notification && notification[OBJECT_VARS.expirationDate] !== null
  const useAcknowledge = useAcknowledgeNotification({
    onMutate: async () => {
      await queryClient.cancelQueries('notifications')
      const previousNotifications = queryClient.getQueryData('notifications')
      setIsNew(false)
      return { previousNotifications }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('notifications', previousValue)
      setIsNew(true)
    },
    onSettled: () => {
      queryClient.invalidateQueries('notifications')
    },
  })

  const useExpire = useExpireNotification({
    onMutate: async () => {
      await queryClient.cancelQueries('notifications')
      const previousNotifications = queryClient.getQueryData('notifications')
      queryClient.setQueryData(
        'notifications',
        previousNotifications.filter((el) => el[OBJECT_VARS.messageId] !== id)
      )
      animateOut(true)
      return { previousNotifications }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('notifications', previousValue)
      animateOut(false)
    },
    onSettled: () => {
      queryClient.invalidateQueries('notifications')
    },
  })

  React.useEffect(() => {
    if (isSelected && isNew && !useAcknowledge.isError) {
      useAcknowledge.mutate(id)
    }
  }, [isSelected])

  return (
    <CardWrapper
      className="card m-2"
      key={id}
      onClick={() => onClick(id)}
      isSelected={isSelected}
      severity={notification[OBJECT_VARS.severity]}
      isNew={isNew}
      {...rest}
    >
      <MCItemWrapper severity={notification[OBJECT_VARS.severity]} isNew={isNew}>
        <MCTileLeft>
          <div>{formatDate(notification[OBJECT_VARS.createdDate])}</div>
          <div style={{ fontSize: '16px' }}>{notification[OBJECT_VARS.title]}</div>
        </MCTileLeft>
        <MCTileRight
          onClick={() => {
            useExpire.mutate(id)
          }}
        >
          {!isExpired ? (
            <span title="Delete" className="k-icon k-i-delete" style={{ fontSize: '28px' }} />
          ) : null}
        </MCTileRight>
        <ClearFloat />
      </MCItemWrapper>
    </CardWrapper>
  )
}

NotificationListItemComponent.propTypes = {
  id: PropTypes.string.isRequired,
  notification: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  animateOut: PropTypes.func.isRequired,
}

export { NotificationListItemComponent }
