import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: {},
}

const errorSlice = createSlice({
  name: 'error',
  initialState: initialState.error,
  reducers: {
    saveDataLoadError: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export default errorSlice
