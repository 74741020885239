import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import bg from '@/images/LoginBG.png'
import { Box } from '@/components/common/Box'
import { Title, Terms } from '@/components/common/Typography'
import { Link } from 'react-router-dom'
import { LINKS } from '@/utils/sitemap'
import { LoginForm } from './LoginForm'

const StyledContainer = styled(Box)`
  padding: 4em;
  background: linear-gradient(0deg, rgba(86, 145, 200, 0.6), rgba(86, 145, 200, 0.6)), url(${bg});
  background-size: cover;
  min-height: 100%;
  display: flex;
`

const LoginContainer = styled(Box)`
  width: 422px;
  height: 382px;
  background: #fff;
  border-radius: 4px;
`

const CreateAccountLink = styled(Link)`
  color: #000000;
  margin-top: 17px;
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin: 10px auto;
  display: block;
`

const Login = () => {
  return (
    <StyledContainer flexCenter fluid>
      <Animated animationIn="fadeInDown" animationOut="fadeOut" animationInDelay="1000" isVisible>
        <Title fontWeight={300} bottomGutter={0.8}>
          My Account for Business
          <br />
          BETA
        </Title>
      </Animated>
      <LoginContainer flexCenter padding={3}>
        <LoginForm />
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
          <Terms>
            By clicking &quot;Login In&quot; you are agreeing to the{' '}
            <Link to="/tc" alt="Terms and conditions" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </Link>{' '}
            for using this site, last updated on 05/25/2018
          </Terms>
          <CreateAccountLink to={LINKS.signup}>Create User Account</CreateAccountLink>
        </Animated>
      </LoginContainer>
    </StyledContainer>
  )
}

export { Login }
