import React from 'react'
import styled from 'styled-components'
import { ICONS } from '@/utils/icons'
import { PropTypes } from 'prop-types'
import { Input } from '@/components/common/Input'

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0px 0 10px 0;
`

const Search = styled.div``

const SearchBarComponent = ({ value, setValue }) => {
  return (
    <FlexWrapper>
      <Search>
        <Input
          type="text"
          placeholder="Search for Street Name, Account Number, Nickname, etc..."
          icon={ICONS.search}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Search>
    </FlexWrapper>
  )
}

SearchBarComponent.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

export { SearchBarComponent }
