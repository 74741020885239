import styled, { css } from 'styled-components'
import Button from 'react-bootstrap/Button'

const getTheme = (theme, variant) => (variant ? theme[variant] : theme.primary)

const MycButton = styled(Button)`
  ${({ theme, variant, gutterBottom }) => css`
    color: #fff;
    background: ${getTheme(theme, variant)};
    width: 100%;
    margin-bottom: ${gutterBottom}em;
    border-radius: 4px;
    padding: 12px 0;
    border-color: transparent;
    &:hover,
    .btn-warning,
    &:active,
    &:focus {
      cursor: ${(disabled) => disabled && 'not-allowed'};
      background: ${({ disabled }) =>
        disabled ? getTheme(theme, variant) : getTheme(theme, `${variant}Dark`)} !important;
      color: #fff;
    }
  `}
`

export { MycButton }
