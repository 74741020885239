import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { formatLongDate } from '@/utils/utils'
import { MycButton } from '@/components/common/Button'
import { useHistory } from 'react-router-dom'
import { ScheduleLaterLinkComponent } from './ScheduleLaterLinkComponent'
import { PayLinkComponent } from './PayLinkComponent'

const CenterContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const PayBtn = styled(MycButton)`
  display: block;
  width: 50%;
  margin: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 300;
`

const PayOnBtn = styled(MycButton)`
  display: block;
  margin: 20px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 300;
`

const SubmitPaymentPayButtons = ({ selectedDay, willSchedule, isDisabled, onClick, onSubmit }) => {
  const history = useHistory()

  const [didConfirm, setDidConfirm] = React.useState(false)
  if (!willSchedule) {
    return (
      <CenterContent>
        <p style={{ 'font-size': '14px' }}>
          Your financial institution will process the amount designated for each SAWS account as a
          separate transaction. If there are insufficient funds available, you will be charged a
          separate returned item fee for each of the transactions that cannot be paid in full.
        </p>
        <PayBtn
          className="btn-myc-green"
          onClick={() => {
            if (!didConfirm) {
              return setDidConfirm(true)
            }
            if (didConfirm) {
              return onSubmit()
            }
          }}
          type="button"
          disabled={isDisabled}
        >
          {!didConfirm ? 'Pay Now' : 'Confirm?'}
        </PayBtn>

        <PayBtn className="btn-myc-col" onClick={() => history.push('/accounts')}>
          Go back
        </PayBtn>

        <ScheduleLaterLinkComponent onClick={onClick} />
      </CenterContent>
    )
  }
  return (
    <CenterContent>
      <PayOnBtn
        className="btn-myc-col"
        variant="primary"
        type="button"
        disabled={isDisabled}
        style={{ whiteSpace: 'nowrap', fontSize: '15px' }}
        onClick={() => {
          return onSubmit()
        }}
      >
        {`Pay on ${formatLongDate(selectedDay, true)} - CONFIRM`}
      </PayOnBtn>
      <PayLinkComponent
        flavorText="I changed my mind."
        linkText="I'll pay today."
        onClick={onClick}
      />
    </CenterContent>
  )
}

SubmitPaymentPayButtons.propTypes = {
  selectedDay: PropTypes.string.isRequired,
  willSchedule: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export { SubmitPaymentPayButtons }
