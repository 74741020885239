import React from 'react'
import { OBJECT_VARS } from '@/utils/models'
import { PropTypes } from 'prop-types'
import { NotificationsListComponent } from './NotificationsListComponent'
import { useExpireNotification } from './useExpireNotification.queries'

const InboxNotificationsList = ({ notifications, select, selected, animate }) => {
  const expireNotification = useExpireNotification()

  React.useEffect(() => {
    if (notifications && notifications.length > 0) {
      select(notifications[0][OBJECT_VARS.messageId])
    }
    return function cleanUp() {
      select(null)
    }
  }, [])

  return (
    <NotificationsListComponent
      notifications={notifications}
      selectNotification={select}
      selectedNotification={selected}
      expireNotification={expireNotification}
      animate={animate}
    />
  )
}

InboxNotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  animate: PropTypes.shape({
    inValue: PropTypes.bool,
    setInValue: PropTypes.func,
  }).isRequired,
}
export { InboxNotificationsList }
