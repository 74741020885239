import React from 'react'
import { getPaymentFormData } from '@/redux/ducks/paymentDuck'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { AccountTableAccountRowComponent } from './AccountTableAccountRowComponent'

const AccountTableComponent = ({ errors, control, numRows }) => {
  const accounts = useSelector(getPaymentFormData)

  if (!accounts) return <Skeleton count={numRows} />
  return accounts.map((account) => {
    return (
      <AccountTableAccountRowComponent
        key={account?.accountKey}
        account={account}
        control={control}
        errors={errors}
      />
    )
  })
}

export { AccountTableComponent }
