import React from 'react'
import styled from 'styled-components'
import { useForm, useWatch } from 'react-hook-form'
import { getUserByEmail } from '@/redux/api'
import { useSelector, useDispatch } from 'react-redux'
import selectors from '@/redux/selectors'
import { OBJECT_VARS } from '@/utils/models'
import { getUserEmail } from '@/redux/ducks/commonDuck'
import { useAuthApi } from '@/redux/reducers'
import { Animated } from 'react-animated-css'
import { Card } from '@progress/kendo-react-layout'
import { Button } from '@progress/kendo-react-buttons'
import { Typography } from '@progress/kendo-react-common'
import { BaseFormComponent } from '../../common/BaseFormComponent'

const CenterButton = styled.div`
  text-align: center;
`
const UserProfileForm = () => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    errors,
    control,
    getValues,
    clearErrors,
    formState: { isDirty, dirtyFields, isSubmitSuccessful },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
  })
  const userEmail = useSelector(getUserEmail)
  const [isUpdatingPassword, setIsUpdatingPassword] = React.useState(false)
  const [isUpdatingEmail, setIsUpdatingEmail] = React.useState(false)
  const [validatingEmail, setValidatingEmail] = React.useState(false)
  const onSubmit = (data) => {
    const formReq = {
      ...data,
      updatePassword: isUpdatingPassword,
    }
    return dispatch(useAuthApi.fetchUpdateUserRequest(formReq))
  }
  const verifyUniqueEmailAsync = async (value, setState) => {
    if (value === '') {
      return true
    }
    // Clear errors when user corrects input
    if (isDirty) {
      clearErrors('email')
    }
    setState(true)
    const result = await getUserByEmail(value)
    setState(false)
    if (result !== 0) {
      return 'Email is already in use'
    }
    return true
  }
  const user = useSelector((state) => selectors.auth.getUser(state))
  const isSaving = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAuthApi.fetchUpdateUserRequest.type)
  )
  const formData = [
    {
      name: 'firstName',
      label: 'first name',
      defaultValue: user[OBJECT_VARS.firstName] || '',
      rules: {
        required: { value: true, message: 'This field is required' },
        minLength: { value: 4, message: 'Password should have a minimum of 4 characters' },
      },
    },
    {
      name: 'lastName',
      label: 'last name',
      defaultValue: user[OBJECT_VARS.lastName] || '',
      rules: { required: { value: true, message: 'This field is required' } },
    },
    {
      name: 'mobileNumber',
      label: 'mobile number',
      type: 'tel',
      defaultValue: user[OBJECT_VARS.mobile] || '',
      rules: { required: { value: true, message: 'This field is required' } },
    },
    {
      name: 'jobTitle',
      label: 'job title',
      defaultValue: user[OBJECT_VARS.jobTitle] || '',
      rules: { required: { value: false, message: 'This field is required' } },
    },
    {
      name: 'companyName',
      label: 'company name',
      defaultValue: user[OBJECT_VARS.companyName] || '',
      rules: { required: { value: false, message: 'This field is required' } },
    },
    {
      name: 'taxId',
      label: 'tax id',
      defaultValue: user[OBJECT_VARS.taxId] || '',
      rules: {
        required: { value: false, message: '' },
        pattern: {
          value: /^[0-9]{2}-[0-9]{7}$/,
          message: 'Tax Id must be 9 digits',
        },
      },
      inputMode: 'numeric',
      isMask: true,
      maskPlaceholder: '99-9999999',
      mask: '99-9999999',
      legend:
        'Also known as Employer Identification Number assigned by the IRS to identify a business entity',
    },
    {
      name: 'newPassword',
      label: 'change password',
      defaultValue: '',
      type: 'password',
      rules: {
        required: { value: false, message: 'This field is required' },
        minLength: { value: 8, message: 'Password should have a minimum of 8 characters' },
        maxLength: { value: 16, message: 'Password should have a maximum of 16 characters' },
        pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
          message:
            'Password must have between 8 and 16 characters, including 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.',
        },
      },
    },
    {
      name: 'confirmPassword',
      label: 'retype password',
      defaultValue: '',
      type: 'password',
      rules: {
        required: { value: false, message: 'This field is required' },
        minLength: { value: 8, message: 'Password should have a minimum of 8 characters' },
        maxLength: { value: 16, message: 'Password should have a maximum of 16 characters' },
        validate: (value) => (getValues('newPassword') === value ? true : 'Passwords must match'),
      },
    },
    {
      name: 'email',
      label: 'email address',
      type: 'email',
      defaultValue: user[OBJECT_VARS.email],
      rules: {
        required: { value: isUpdatingEmail, message: 'This field is required' },
        validate: (value) =>
          isUpdatingEmail ? verifyUniqueEmailAsync(value, setValidatingEmail) : true,
      },
      showAsyncMessage: validatingEmail,
      asyncMessage: 'Verifying email is unique...',
    },
  ]

  const email = useWatch({
    control,
    name: 'email',
    defaultValue: userEmail,
  })
  const newPassword = useWatch({
    control,
    name: 'newPassword',
    defaultValue: '',
  })

  React.useEffect(() => {
    if (dirtyFields.email) {
      setIsUpdatingEmail(true)
    } else {
      setIsUpdatingEmail(false)
    }
  }, [email])

  React.useEffect(() => {
    if (dirtyFields.newPassword) {
      setIsUpdatingPassword(true)
    } else {
      setIsUpdatingPassword(false)
    }
  }, [newPassword])

  return (
    <>
      <div className="row">
        <div className="col-4">
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
            <Typography.h5 className="ml-2 mt-2">My Profile</Typography.h5>
            <Card className="mb-4 p-4" style={{ width: '450px' }}>
              <>
                <BaseFormComponent
                  data={formData}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                  isSubmitSuccessful={isSubmitSuccessful}
                  onSubmit={onSubmit}
                  style={{ width: 'auto' }}
                  variant="light"
                >
                  <CenterButton>
                    <Button
                      className="btn-myc-col mt-3"
                      style={{ width: '200px' }}
                      type="submit"
                      disabled={isSaving}
                    >
                      {isSaving ? 'Saving' : 'Save'}
                    </Button>
                  </CenterButton>
                </BaseFormComponent>
              </>
            </Card>
          </Animated>
        </div>
      </div>
    </>
  )
}

export { UserProfileForm }
