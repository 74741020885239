import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { useForm, Controller } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import ArrowVector from '@/images/ArrowVector.png'
import { Input, Label, MaskInput } from '@/components/common/Input'
import { ACCOUNT_DISPLAY_ICONS } from '@/utils/icons'
import { IconComponent } from '@/components/common/IconComponent'
import { FormError } from '@/components/common/FormError'
import { asArray } from '@/utils/utils'
import { useAccountApi } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { validateAccount } from '@/redux/api'
import { Title } from '@/components/common/Typography'

const Arrow = styled.img`
  position: absolute;
  top: 50%;
  left: 95%;
`

const ClickableWrapper = styled.button`
  position: relative;
  display: inline-block;
  border: none;
  height: 57px;
  width: 75px;
  padding: 8px;
  text-align: center;
  background: none;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const IconSelectedText = styled.h5`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
  position: absolute;
  top: 26px;
  color: #ffffff;
  margin: 0;
  font-size: 15px;
  text-shadow: -1px -1px 0 #585858, 1px -1px 0 #585858, -1px 1px 0 #585858, 1px 1px 0 #585858;
`

const Divider = styled.hr`
  background: #fff;
  width: 100%;
  margin-top: 0;
`
const SubTitle = styled.p`
  color: #fff;
  font-size: 1em;
`

const StyledIcon = styled(IconComponent)`
  text-align: center;
  cursor: pointer;
  font-size: 40px;
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#585858')};
  margin-right: 0;
  margin-bottom: 5px;
`

const IconSquare = ({ icon, label, onClick, selectedIcon }) => {
  const selected = icon.displayName === selectedIcon.displayName
  return (
    <ClickableWrapper type="button" selected={selected} onClick={() => onClick(icon)}>
      <IconSelectedText selected={selected}>Selected</IconSelectedText>
      <StyledIcon selected={selected} icon={icon.file} />
      <IconLabel selected={selected}>{label}</IconLabel>
    </ClickableWrapper>
  )
}
const IconLabel = styled.p`
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#585858')};
  font-size: 0.6em;
  text-align: center;
  text-transform: uppercase;
`

const IconSetWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 10px 0;
`

const CenterContent = styled.div`
  text-align: center;
`

const IconSet = ({ selectedIcon, setSelectedIcon }) => {
  return (
    <IconSetWrapper>
      {asArray(ACCOUNT_DISPLAY_ICONS).map((icon) => {
        return (
          <IconSquare
            icon={icon}
            selectedIcon={selectedIcon}
            // label={icon.displayName}
            onClick={() => setSelectedIcon(icon)}
          />
        )
      })}
    </IconSetWrapper>
  )
}
const AccountInfoForm = ({ handleAddTempAccount }) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    reset,
    getValues,
    formState: { isDirty, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      accountNumber: '',
      streetnumber: '',
      nickName: '',
    },
  })
  const [newAccount, setNewAccount] = React.useState()
  const [selectedIcon, setSelectedIcon] = React.useState({})
  const isAddingAccount = useSelector((state) =>
    selectors.ui.getIsFetching(state, useAccountApi.fetchAddAccountRequest.type)
  )
  const isAddAccountSuccess = useSelector((state) =>
    selectors.ui.getFetchSuccessful(state, useAccountApi.fetchAddAccountRequest.type)
  )
  const addAccountError = useSelector((state) =>
    selectors.ui.getError(state, useAccountApi.fetchAddAccountRequest.type)
  )
  const onSubmit = (formData) => {
    clearErrors('account')
    setNewAccount({ ...newAccount, ...formData, icon: selectedIcon })
    dispatch(
      useAccountApi.fetchAddAccountRequest({
        ...newAccount,
        ...formData,
        icon: selectedIcon.file.iconName,
        accountKey: newAccount.accountKey,
      })
    )
  }

  React.useEffect(() => {
    setSelectedIcon(asArray(ACCOUNT_DISPLAY_ICONS)[0])
  }, [])

  React.useEffect(() => {
    if (isAddAccountSuccess) {
      reset()
      handleAddTempAccount({ ...newAccount, icon: selectedIcon })
    }
  }, [isAddAccountSuccess])

  React.useEffect(() => {
    if (addAccountError) {
      if (addAccountError.statusCode === 500) {
        setError('account', {
          type: 'account',
          message: 'This water account has already been added',
        })
      } else {
        setError('account', {
          type: 'account',
          message: addAccountError.message,
        })
      }
    }
  }, [addAccountError, setError])

  React.useEffect(() => {
    // Clear errors when user corrects input
    if (isDirty) {
      clearErrors('account')
    }
  }, [isDirty, clearErrors])

  const [validatingAccount, setValidatingAccount] = React.useState(false)
  const verifyAccountExists = async (accountNumber, streetnumber) => {
    if (streetnumber === '' || accountNumber === '') {
      return true
    }
    setValidatingAccount(true)
    const result = await validateAccount(accountNumber, streetnumber)
    setNewAccount(result)
    setValidatingAccount(false)
    if (!result) {
      return 'Account was not found'
    }
    return true
  }
  return (
    <>
      <Arrow src={ArrowVector} alt="decorative arrow pointing to next panel" />
      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible>
        <Title size="1.5em" fontWeight="300" bottomGutter={1}>
          Add SAWS Account Info
        </Title>
      </Animated>
      <Animated animationIn="fadeInLeft" animationOut="fadeOut" animationInDelay="500" isVisible>
        <Form className="form-acct-add" style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formGroupAccount">
            <Label label="Account Number" fontSize="14px" variant="light" />
            <Controller
              as={MaskInput}
              mask="999999999-9999999-9999"
              maskPlaceholder=""
              name="accountNumber"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: 'This field is required' },
                minLength: { value: 20, message: 'Account number must be valid' },
              }}
              isInvalid={errors.accountNumber && true}
              type="text"
              placeholder="Type here..."
              bottomGutter={0}
              padding="1.4em"
            />
            <div style={{ height: '12px' }}>
              <FormError>{errors.accountNumber && errors.accountNumber.message}</FormError>
            </div>
          </Form.Group>
          <Form.Group controlId="formGroupServiceAddressNumber">
            <Label label="service address number" fontSize="14px" variant="light" />
            <Controller
              as={Input}
              name="streetnumber"
              control={control}
              defaultValue=""
              rules={{
                required: { value: true, message: 'This field is required' },
                validate: (value) => verifyAccountExists(getValues('accountNumber'), value),
              }}
              isInvalid={errors.streetnumber && true}
              type="text"
              placeholder="1234..."
              bottomGutter={0}
              padding="1.4em"
            />
            <div style={{ height: '12px' }}>
              {errors.streetnumber && <FormError>{errors.streetnumber.message}</FormError>}
            </div>
          </Form.Group>
          <Form.Group controlId="formGroupServiceNickname">
            <Label label="nickname for this account? (optional)" fontSize="14px" variant="light" />
            <Controller
              as={Input}
              name="nickName"
              control={control}
              defaultValue=""
              type="text"
              placeholder="Type here..."
              bottomGutter={1}
              padding="1.4em"
            />
          </Form.Group>
          <Divider />
          <SubTitle>PICK AN ICON FOR THIS ACCOUNT</SubTitle>
          <IconSet selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
          <CenterContent>
            <FormError style={{ color: '#fff' }}>
              {validatingAccount && 'Searching for account...'}
            </FormError>
            <FormError>{errors.account?.message}</FormError>
            <Button
              type="submit"
              variant="primary"
              gutterBottom={2}
              disabled={isSubmitting || isAddingAccount || !isValid}
              style={{ width: '75%', padding: '0.8em', marginTop: '46px' }}
            >
              ADD SAWS ACCOUNT
            </Button>
          </CenterContent>
        </Form>
      </Animated>
    </>
  )
}

IconSquare.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export { AccountInfoForm }
