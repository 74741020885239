import React from 'react'
import { SITEMAP } from '@/utils/sitemap'
import { PropTypes } from 'prop-types'
import { MobileNav } from './MobileNav'
import { DesktopNav } from './DesktopNav'

const buildMenu = ({ isAuth, type }) => {
  const sitemap = isAuth ? SITEMAP.auth : SITEMAP.unauth
  if (type === 'desktop') {
    return <DesktopNav sitemap={sitemap} />
  }

  return <MobileNav sitemap={sitemap} />
}

const Menu = (props) => buildMenu(props)

buildMenu.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}

export default Menu
