import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuthApi as useAuth } from '@/redux/reducers'
import { useQueryClient } from 'react-query'
import { Spinner } from 'react-bootstrap'

const ImpersonateUser = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [accountKey, SetAccountKey] = React.useState(null)

  React.useEffect(() => {
    dispatch(useAuth.logout())
    queryClient.removeQueries()
    queryClient.clear()

    setTimeout(() => {
      const pathName = history.location.pathname
      const pathSplitData = pathName.split('/')
      SetAccountKey(Number(pathSplitData[2]))
      if (pathSplitData.length === 4) {
        dispatch(useAuth.fetchImpersonateRequest(pathSplitData[3]))
        SetAccountKey(Number(pathSplitData[2]))
      }
    }, 4000)
  }, [])

  React.useEffect(() => {
    if (accountKey == null) return undefined
    if (accountKey > 0) {
      setTimeout(() => {
        history.push(`/history/${accountKey}`)
      }, 500)
    } else {
      history.push('/accounts')
    }
  }, [accountKey])

  return (
    <div style={{ height: '500px', position: 'absolute', top: '50%', left: '50%' }}>
      <Spinner animation="border" />
    </div>
  )
}

export { ImpersonateUser }
