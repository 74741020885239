import { createSelector, createSlice } from '@reduxjs/toolkit'
import { cleanUpServiceRequest } from '@/redux/ducks/commonDuck'

const initialState = {
  moveOutDate: null,
  summary: {
    moveOutDate: null,
    data: [],
  },
  canViewSummary: false,
}

const serviceRequestSlice = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    saveSummary: (state, action) => {
      const { data, moveOutDate } = action.payload
      state.summary = { data, moveOutDate }
    },
    saveCanViewSummary: (state, action) => {
      state.canViewSummary = action.payload
    },
    setMoveOutDate: (state, action) => {
      state.moveOutDate = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cleanUpServiceRequest, (state) => {
      state.summary = { data: [], moveOutDate: null }
      state.canViewSummary = false
    })
  },
})

const getState = (state) => state.serviceRequest

export const getSummary = createSelector(getState, (state) =>
  state.summary?.data.length === 0 ? null : state.summary
)

export const getCanViewSummary = createSelector(getState, (state) => state.canViewSummary)

export default serviceRequestSlice
