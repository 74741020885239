import React from 'react'
import styled from 'styled-components'
import bg from '@/images/LoginBG.png'
import { Box } from '@/components/common/Box'
import { Title } from '@/components/common/Typography'
import { Link } from 'react-router-dom'
import { RecoveryForm } from './RecoveryForm'

const StyledContainer = styled(Box)`
  padding: 4em;
  background: linear-gradient(0deg, rgba(86, 145, 200, 0.6), rgba(86, 145, 200, 0.6)), url(${bg});
  background-size: cover;
  min-height: 100%;
  display: flex;
`

const LoginContainer = styled(Box)`
  background: #fff;
  border-radius: 4px;
  padding: 20px 30px;
`

const BackToLoginLink = styled(Link)`
  color: #000000;
  margin-top: 17px;
  font-size: 14px;
`

const PasswordRecovery = () => {
  return (
    <StyledContainer flexCenter fluid>
      <Title fontWeight={300} bottomGutter={0.8}>
        Recover Your Password
      </Title>
      <LoginContainer flexCenter padding={3}>
        <RecoveryForm />
        <BackToLoginLink to="/login">Back to Login</BackToLoginLink>
      </LoginContainer>
    </StyledContainer>
  )
}

export default PasswordRecovery
