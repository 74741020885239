import React, { useState, useEffect } from 'react'
import { OBJECT_VARS } from '@/utils/models'
import { getUserId } from '@/redux/ducks/commonDuck'
import { TabStrip, TabStripTab, Card } from '@progress/kendo-react-layout'
import { formatDate, parseDollars } from '@/utils/utils'
import { getBillSummary } from '@/redux/api'
import { useConsumption } from '@/hooks/useConsumption.queries'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { usePayments } from '@/hooks/usePayments.queries'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import 'hammerjs'
import { Typography } from '@progress/kendo-react-common'
import { Button } from '@progress/kendo-react-buttons'
import { Tab, Tabs } from '@material-ui/core'
import { Link, useHistory, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { BillHighlightsComponent } from './BillHighlightsComponent'
import { PayBillButton } from './PayBillButton'
import { ViewBillComponent } from './ViewBillComponent'
import { useCancelScheduledPayment } from './useCancelScheduledPayment.queries'
import { usePaymentHistory } from './usePaymentHistory.queries'
import { PaymentHistoryComponent } from './PaymentHistoryComponent'
import { BillHistoryComponent } from './BillHistoryComponent'
import EBillComponent from './EBillComponent'
import { useLastPayment } from '../../../hooks/useLastPayment.queries'

const AccountTitle = ({ nickName, address }) => {
  return (
    <>
      <p style={{ marginBottom: 0, lineHeight: '35px' }}>{nickName}</p>
      <p style={{ marginBottom: 0, fontSize: '20px' }}>{address}</p>
    </>
  )
}

const HistoryContainer = styled.div`
  width: 100%;
  margin: auto;

  @media (min-width: 1500px) {
    width: 80%;
  }

  @media (max-width: 1499px) {
    width: 100%;
  }
`

const container = {
  show: {
    transition: {
      staggerChildren: 0.3,
    },
  },
}
const item = {
  hidden: { opacity: 0, y: 50 },
  show: { opacity: 1, y: 0 },
}

const SimpleBarChart = ({ data }) => {
  return (
    <BarChart
      width={900}
      height={500}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      style={{ width: `100%`, justifyContent: 'center', display: 'flex' }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Gallons" fill="#0078ae" />
    </BarChart>
  )
}

const History = () => {
  const { key } = useParams()
  const accountKey = Number(key)
  const cancelScheduledPaymentQuery = useCancelScheduledPayment(accountKey)
  const [meterId, setMeterId] = useState('')
  const [isAmiMeter, setIsAmiMeter] = useState(false)
  const history = useHistory()

  const x52id = useSelector(getUserId)

  const consumptionQuery = useConsumption(accountKey)
  const usageData = consumptionQuery.data
  const [noUsageRecords, setNoUsageRecords] = React.useState([])

  const [billFromDate, setBillFromDate] = useState(new Date())
  const [billToDate, setBillToDate] = useState(new Date())

  const [tabValue, setTabValue] = useState('myBill')

  const accountQuery = useAccounts({
    select: (res) => res.find((el) => el[OBJECT_VARS.accountKey] === accountKey),
  })

  const account = accountQuery.data

  const paymentQuery = usePayments(accountKey, {
    select: (res) => res.find((el) => el[OBJECT_VARS.accountKey] === accountKey),
  })
  const payment = paymentQuery.isSuccess && paymentQuery.data

  const accountQuerySuccess = accountQuery.isSuccess
  const accountNickname = accountQuerySuccess
    ? OBJECT_VARS?.nickname ?? account?.[OBJECT_VARS?.nickname]
    : ''

  const getUsageDataForAccount = () => {
    if (!usageData) return undefined
    if (usageData.records.length === 0) {
      return [{ Date: dayjs(), Gallons: 0 }]
    }
    return usageData.records.map((item) => {
      const date = formatDate(item[OBJECT_VARS.readDate])
      return { Date: date, Gallons: item[OBJECT_VARS.usageGallons] }
    })
  }

  const address =
    accountQuery.isSuccess &&
    account &&
    `${account[OBJECT_VARS.serviceAddress]} ${account[OBJECT_VARS.subDesignation]}`

  const serviceAddress =
    account?.streetNumber && account?.streetName
      ? `${account.streetNumber} ${account.streetName}`
      : ''

  const lastPaymentQuery = useLastPayment(accountKey)
  const [lastBillKey, SetLastBillKey] = useState(null)

  useEffect(() => {
    if (accountKey === null) return undefined
    getBillSummary(accountKey)
      .then((res) => {
        setBillFromDate(new Date(res.billperfrdate))
        setBillToDate(new Date(res.billpertodate))
        SetLastBillKey(res.billkey)
        setMeterId(res.meterid)
        setIsAmiMeter(res.amimeterflag)
      })
      .catch((e) => {
        console.warn(e)
        SetLastBillKey(0)
      })
  }, [accountKey])

  const scheduledPaymentQuery = usePaymentHistory(accountKey, {
    select: (res) => res.find((el) => el.status === 'SCHEDULED'),
  })
  const scheduledPayment = scheduledPaymentQuery.data

  React.useEffect(() => {
    if (!usageData) return undefined
    if (usageData.records.length === 0) {
      return setNoUsageRecords(true)
    }
    return setNoUsageRecords(false)
  }, [usageData])

  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected)
  }

  const handleUsageTabSelect = (e, value) => {
    setTabValue(value)
    if (value === 'myUsage') {
      history.push('/myUsagePage', {
        accountKey,
        meterId,
        x52id,
        billFromDate,
        billToDate,
        accountQuerySuccess,
        accountNickname,
        serviceAddress,
      })
    }
  }

  const MainPage = () => {
    return (
      <>
        <div className="row">
          <div className="col-lg-4">
            <motion.div initial="hidden" animate="show" variants={container}>
              <Card className="text-center p-4">
                <p>Account #: {accountQuery.isSuccess && account[OBJECT_VARS.accountNumber]}</p>
                <div>
                  <Typography.h5 className="text-black">Balance Due</Typography.h5>
                  <p className="line-black" />
                  <Typography.h3 className="text-blue-col">
                    {parseDollars(payment[OBJECT_VARS.finalBalance])}
                  </Typography.h3>
                  <Typography.p className="text-orange-col font-bold">
                    Current Charges Due:
                    {formatDate(payment[OBJECT_VARS.dueDate])}
                  </Typography.p>

                  {lastPaymentQuery.isLoading ? (
                    <Skeleton width="200px" />
                  ) : (
                    <>
                      {lastPaymentQuery.data === null ? (
                        ''
                      ) : (
                        <Typography.p>
                          Last Payment:&nbsp;
                          <b>{parseDollars(lastPaymentQuery.data?.amount)}</b> paid on&nbsp;
                          {formatDate(lastPaymentQuery.data?.date)}
                        </Typography.p>
                      )}
                    </>
                  )}
                  <PayBillButton accountKey={accountKey}>Pay This Bill</PayBillButton>

                  {scheduledPayment !== null && scheduledPayment !== undefined ? (
                    <>
                      <Typography.p className="mt-3">
                        This Account has a Scheduled Payment for{' '}
                        <b>{parseDollars(scheduledPayment.amount)}</b> on{' '}
                        {formatDate(scheduledPayment.date)}
                      </Typography.p>
                      <Button
                        onClick={() => cancelScheduledPaymentQuery.mutate(scheduledPayment.id)}
                        className="btn-myc-orange"
                      >
                        {cancelScheduledPaymentQuery.isLoading
                          ? 'Canceling'
                          : 'Cancel Scheduled Payment'}
                      </Button>
                    </>
                  ) : (
                    ''
                  )}

                  <BillHighlightsComponent accountKey={accountKey} billKey={lastBillKey} />
                  <ViewBillComponent accountKey={accountKey} billKey={lastBillKey}>
                    View Current Bill
                  </ViewBillComponent>
                  <EBillComponent accountKey={accountKey} />
                </div>
              </Card>
            </motion.div>
          </div>
          <div className="col-lg-8">
            <motion.div initial="hidden" animate="show" variants={container}>
              <motion.div variants={item}>
                <Typography.h5 className="text-black">Monthly Usage</Typography.h5>
                <hr />
                <div className="">{noUsageRecords && 'No Records'}</div>
                <SimpleBarChart data={getUsageDataForAccount()} />
              </motion.div>
              <motion.div variants={item}>
                <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                  <TabStripTab title="Bill History">
                    <BillHistoryComponent accountKey={accountKey} />
                  </TabStripTab>
                  <TabStripTab title="Payment History">
                    <PaymentHistoryComponent accountKey={accountKey} />
                  </TabStripTab>
                </TabStrip>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <HistoryContainer>
        <div className="row mt-3">
          <div className="col-12">
            <Typography.h4>
              <AccountTitle
                nickName={accountQuery.isSuccess && account[OBJECT_VARS.nickname]}
                address={accountQuery.isSuccess && address}
              />
            </Typography.h4>
          </div>
          <div className="row" style={{ paddingLeft: '12px' }}>
            {isAmiMeter === 'True' && (
              <Tabs
                style={{ marginBottom: '5px' }}
                TabIndicatorProps={{ style: { background: 'blue' } }}
                value={tabValue}
                variant="full-width"
                onChange={handleUsageTabSelect}
              >
                <Tab
                  style={{
                    backgroundColor: 'rgba(103, 183, 220, 0.2)',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    marginRight: '4px',
                  }}
                  value="myBill"
                  label={<Link to={`/history/${accountKey}`}>My Bill</Link>}
                />
                <Tab
                  style={{
                    backgroundColor: 'rgba(103, 183, 220, 0.2)',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                  }}
                  value="myUsage"
                  label={<Link to={`/history/${accountKey}`}>My Water Use</Link>}
                />
              </Tabs>
            )}
          </div>
          <MainPage />
        </div>
      </HistoryContainer>
    </>
  )
}

export { History }
