import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { v4 as uuid } from 'uuid'
import { IconComponent } from '@/components/common/IconComponent'
import { PropTypes } from 'prop-types'
import { PayMyAccountsButtonComponent } from './PayMyAccountsButtonComponent'

//* This wraps each MenuItem inside a Nav.Link component. This is necessary for React-Bootstraps onClick methods to close the top nav menu *//
const renderMenuItemsForMobile = (sitemap) =>
  sitemap.map((item) => {
    return (
      <Nav.Link key={uuid()} href={item.href}>
        <IconComponent icon={item.icon} />
        {item.page}
      </Nav.Link>
    )
  })

//* Renders a react-bootstrap top nav bar
const MobileNav = ({ sitemap }) => (
  <Navbar bg="light" expand="lg" collapseOnSelect="true" className="justify-content-end d-md-none">
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        {renderMenuItemsForMobile(sitemap)}
        <PayMyAccountsButtonComponent />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
MobileNav.propTypes = {
  sitemap: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.string,
      icon: PropTypes.func,
      href: PropTypes.string,
      alt: PropTypes.string,
    })
  ).isRequired,
}

export { MobileNav }
