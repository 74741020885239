import React from 'react'
import { useSelector } from 'react-redux'
import { getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { MoveOutSelectedAddressCard } from './MoveOutSelectedAddressCard.component'

const MoveOutSelectedAddressesList = ({ useCheck, onSelect, selected }) => {
  const selectedAccountKeys = useSelector(getSelectedAccountKeys)
  const { data } = useAccounts({
    select: (res) => res.filter((el) => selectedAccountKeys.includes(el.accountKey)),
  })
  const selectedAccountAddressList =
    data.length > 0
      ? data.reduce(
          (res, { accountKey, subDesignation, serviceAddressLine }) => ({
            ...res,
            [accountKey]: {
              accountKey,
              address: serviceAddressLine + subDesignation,
            },
          }),
          {}
        )
      : []
  const { checkState, checkActions } = useCheck

  return selectedAccountKeys.map((key) => {
    return (
      <div style={{display:'flex'}}>      
        <MoveOutSelectedAddressCard
        key={key}
        address={selectedAccountAddressList[key]?.address}
        checkState={checkState[key]}
        checkActions={checkActions}
        accountKey={key}
        onSelect={onSelect}
        isSelected={selected === key}
      />
      </div>
    )
  })
}

export { MoveOutSelectedAddressesList }
