import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_SERVICE_REQUEST_COUNT = `${process.env.REACT_APP_API_BASE_URL}/servicerequest-public-api/public/api/getservicerequestcount`

const fetServiceRequestCount = () => {
  return fetchWrapper(GET_SERVICE_REQUEST_COUNT, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const useServiceRequestCount = () => {
  return useQuery(['serviceRequestCount'], () => fetServiceRequestCount())
}

export { useServiceRequestCount }
