import React from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '@/redux/ducks/commonDuck'
import { OBJECT_VARS } from '@/utils/models'
import { asArray } from '@/utils/utils'
import useBillingAddress from './useBillingAddress.queries'

const initFormData = (user, queries) => {
  return queries.reduce((result, { data, isSuccess }) => {
    if (isSuccess) {
      return {
        ...result,
        [data.accountKey]: {
          x52Id: user[OBJECT_VARS.userId],
          accountNumber: data[OBJECT_VARS.accountNumber],
          accountKey: data[OBJECT_VARS.accountKey],
          name: `${user[OBJECT_VARS.firstName] || ''} ${user[OBJECT_VARS.lastName] || ''}`,
          mobileNumber: user[OBJECT_VARS.mobile] || '',
          taxId: user[OBJECT_VARS.taxId] || '',
          jobTitle: user[OBJECT_VARS.jobTitle] || '',
          address:
            `${data[OBJECT_VARS.streetNumber]} ${data[OBJECT_VARS.streetName]} ${
              data[OBJECT_VARS.suffix]
            } ${data[OBJECT_VARS.subDesignation]}` || '',
          city: data[OBJECT_VARS.city] || '',
          state: data[OBJECT_VARS.state] || '',
          zipCode: data.zip || '',
          country: data.country || '',
          transferringToAddress: `${data[OBJECT_VARS.streetNumber]} ${
            data[OBJECT_VARS.streetName]
          } ${data[OBJECT_VARS.suffix]} ${data[OBJECT_VARS.subDesignation]}`,
          transferringToCity: data[OBJECT_VARS.city],
          transferringToState: data[OBJECT_VARS.state],
          transferringToZipCode: data.zip,
          transferringToCountry: data.country,
        },
      }
    }
  }, {})
}

const useFinalBilling = (selectedAccounts) => {
  const user = useSelector(getUser)
  const queries = useBillingAddress([...selectedAccounts])
  const [defaultValues, setDefaultValues] = React.useState(null)
  const [addresses, setAddress] = React.useState(null)
  const resetToDefaultAddress = (key) => {
    return setAddress({
      ...addresses,
      [key]: defaultValues[key],
    })
  }
  const updateAddressField = (key, field, value) => {
    setAddress({
      ...addresses,
      [key]: {
        ...addresses[key],
        [field]: value,
      },
    })
  }
  const applySameAddressToAllAccounts = (selected) => {
    const output = asArray(addresses).reduce((res, el) => {
      return {
        ...res,
        [el[OBJECT_VARS.accountKey]]: {
          ...el,
          [OBJECT_VARS.transferringToAddress]:
            addresses[selected][OBJECT_VARS.transferringToAddress],
          [OBJECT_VARS.transferringToCity]: addresses[selected][OBJECT_VARS.transferringToCity],
          [OBJECT_VARS.transferringToState]: addresses[selected][OBJECT_VARS.transferringToState],
          [OBJECT_VARS.transferringToZipCode]:
            addresses[selected][OBJECT_VARS.transferringToZipCode],
          [OBJECT_VARS.transferringToCountry]:
            addresses[selected][OBJECT_VARS.transferringToCountry],
        },
      }
    }, {})
    setAddress(output)
    return output
  }
  const cleanUp = () => {
    setAddress(null)
    setDefaultValues(null)
  }
  const allQueriesLoaded = queries.length > 0 && queries.every((query) => query.isSuccess === true)

  React.useEffect(() => {
    if (allQueriesLoaded && defaultValues === null) {
      setDefaultValues(initFormData(user, queries))
      setAddress(initFormData(user, queries))
    }
  })

  return {
    queries,
    defaultValues,
    updateAddressField,
    resetToDefaultAddress,
    applySameAddressToAllAccounts,
    addresses,
    cleanUp,
    isSuccess: allQueriesLoaded,
  }
}

export { useFinalBilling }
