import { createGlobalStyle } from 'styled-components'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'

// Typography
const mainFont = "'Nunito', sans-serif"

const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${mainFont};
    height: 100vh;
    width: 100vw;
  }
`

export { GlobalStyles }
