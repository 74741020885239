import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectAccount } from '@/redux/ducks/commonDuck'
import { Button } from '@progress/kendo-react-buttons'
import { loadFromLocalStorage } from '@//token'

const PayBillButton = ({ accountKey }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleClick = () => {
    dispatch(selectAccount(accountKey))
    history.push('/payment')
  }
  const [impersonate, setImpersonate] = React.useState(false)
  React.useEffect(() => {
    const { isImpersonating } = loadFromLocalStorage('token') || {}
    setImpersonate(isImpersonating)
  }, [])
  return (
    <Button
      disabled={impersonate === true}
      onClick={handleClick}
      className="btn-myc-green"
      style={{ width: '162px' }}
    >
      Pay My Bill
    </Button>
  )
}

export { PayBillButton }
