import React from 'react'

import { OBJECT_VARS } from '@/utils/models'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import useNotifications from './useNotifications.queries'
import { NotificationContentComponent } from './NotificationContentComponent'
import { NotificationsView } from './NotificationsView.component'

const getNotificationById = (notifications, id) =>
  notifications.find((el) => el[OBJECT_VARS.messageId] === id)

const MessageCenter = () => {
  const { isLoading, isError, data } = useNotifications()
  const PageTitle = 'Message Center'
  const [inValue, setInValue] = React.useState(true)
  const [selectedNotification, setSelectedNotification] = React.useState(null)
  const selectNotification = React.useCallback(
    (id) => {
      if (!id) return setSelectedNotification(null)
      setSelectedNotification(getNotificationById(data, id))
    },
    [data]
  )

  const [selected, setSelected] = React.useState(0)
  const handleSelect = (e) => {
    setSelected(e.selected)
  }

  React.useEffect(() => {
    if (data?.length > 0 && selectedNotification === null) {
      setSelectedNotification(
        data?.filter((notification) => notification[OBJECT_VARS.expirationDate] === null)[0]
      )
    }
  }, [selectedNotification])

  return (
    <>
      <div className="container-fluid ml-20">
        <div className="row">
          <div className="col-12">
            <h2>{PageTitle}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab title="INBOX">
                <NotificationsView
                  view="INBOX"
                  select={selectNotification}
                  selected={selectedNotification}
                  data={data}
                  isLoading={isLoading}
                  isError={isError}
                  animate={{ inValue, setInValue }}
                />
              </TabStripTab>
              <TabStripTab title="DELETED">
                <NotificationsView
                  view="DELETED"
                  select={selectNotification}
                  selected={selectedNotification}
                  data={data}
                  isLoading={isLoading}
                  isError={isError}
                  animate={{ inValue, setInValue }}
                />
              </TabStripTab>
            </TabStrip>
          </div>
          <div className="col-xl-8 col-lg-6">
            <NotificationContentComponent
              isLoading={isLoading}
              notification={selectedNotification}
              animateOut={setInValue}
              selectNotification={selectNotification}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageCenter
