import { createAction } from '@reduxjs/toolkit'
import { OBJECT_VARS } from '@/utils/models'

// Constants
export const GRID_VIEW = 'GRID'
export const TABLE_VIEW = 'TABLE'
export const STATUS_IDLE = 'IDLE'
export const STATUS_FETCHING = 'LOADING'
export const STATUS_SUCCESS = 'SUCCESS'
export const STATUS_FAILURE = 'FAILURE'

// Common actions shared between DUCK files
export const selectAccount = createAction('SELECT_ACCOUNT')
export const deselectAccounts = createAction('accounts/deselectAccounts')
export const cleanUpSchedulePayments = createAction('payment/cleanup')
export const logout = createAction('authentication/logout')
export const resetLoaders = createAction('ui/resetLoaders')
export const cleanUpServiceRequest = createAction('serviceRequest/cleanUp')
// Common selectors
export const getUser = (state) => state.authentication.user
export const getUserId = (state) => state.authentication.user?.x52Id
export const getUserEmail = (state) => state.authentication.user?.email
export const getUsername = (state) => state.authentication.user[OBJECT_VARS.username]
export const getToken = (state) => state.authentication.token
export const getAccountTitleById = (state, accountId) => {
  const account = state.accounts.allAccounts[accountId]
  return (
    account[OBJECT_VARS.nickName] ||
    `${account[OBJECT_VARS.serviceAddress]} ${account[OBJECT_VARS.subDesignation]}`
  )
}
export const getAllAccounts = (state) => state.accounts.allAccounts
export const getSelectedAccountKeys = (state) => state.accounts.selectedAccounts
export const getSelectedAccounts = (state) => {
  const { selectedAccounts } = state.accounts
  return selectedAccounts.map((key) => state.accounts.allAccounts[key])
}
// For handling async flags uniformly
export function getActionName(actionType) {
  if (typeof actionType !== 'string') {
    return null
  }

  return actionType
    .split(/(?=[A-Z])/)
    .slice(0, -1)
    .join('')
}

export const isRequestAction = (action) => action.type?.includes('fetch')

// Saga Helpers

// export function* refreshToken() {
//   const token = yield call(API.refreshToken)
//   yield call(saveToLocalStorage, 'token', token)
// }

const useCommonApi = { selectAccount }

export { useCommonApi }
