import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAccounts } from '@/hooks/useAccounts.queries'
import { useMultiplePayments } from '@/hooks/usePayments.queries'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid'
import Skeleton from 'react-loading-skeleton'
import { OBJECT_VARS } from '@/utils/models'
import moment from 'moment'
import { Button } from '@progress/kendo-react-buttons'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { getter } from '@progress/kendo-react-common'
import { useCommonApi, deselectAccounts } from '@/redux/ducks/commonDuck'
import { Input } from '@progress/kendo-react-inputs'
import { process } from '@progress/kendo-data-query'
import amiLogo from '@/images/AmiLogo.png'
import { ViewBillComponent } from '../History/ViewBillComponent'

const combineData = (accounts, payments) => {
  if (!payments || !accounts) return []

  return accounts.map((account) => {
    const paymentDataForAccount = payments.find((el) => el.accountKey === account.accountKey)

    return { selected: false, ...account, ...paymentDataForAccount }
  })
}

const DATA_ITEM_KEY = 'accountKey'
const SELECTED_FIELD = 'selected'
const idGetter = getter(DATA_ITEM_KEY)

const ListGrid = () => {
  const [filterValue, setFilterValue] = React.useState()
  const [initialDataState, setDataState] = React.useState({ skip: 0, take: 10 })

  const accountsQuery = useAccounts()
  const accounts = accountsQuery.data
  const paymentsQuery = useMultiplePayments()
  const isLoading = paymentsQuery.some((result) => result.isLoading)
  const payments = paymentsQuery.filter((el) => el.isSuccess === true).map((el) => el.data[0])
  const data = combineData(accounts, payments)

  const [filteredSampleProducts, setFilteredSampleProducts] = React.useState([])
  const [dataResult, setDataResult] = React.useState(
    process(filteredSampleProducts, initialDataState)
  )

  React.useEffect(() => {
    if (isLoading && accountsQuery.isLoading) {
      console.log('inside isloading true')
    } else {
      setFilteredSampleProducts(data)
      setDataResult(process(data, initialDataState))
    }
  }, [isLoading, accountsQuery.isLoading])

  const dispatch = useDispatch()
  const { selectedAccounts } = useSelector((state) => state.accounts)
  const selectAccount = (accountKey) => dispatch(useCommonApi.selectAccount(accountKey))

  const [selectedState, setSelectedState] = React.useState({})

  // restore already selected
  useEffect(() => {
    const state = selectedAccounts.reduce((obj, item) => Object.assign(obj, { [item]: true }), {})
    setSelectedState(state)
  }, [selectedAccounts])

  const onSelectionChange = (event) => {
    selectAccount(event.dataItem.accountKey) // selection change
  }

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target
    const { checked } = checkboxElement
    if (checked) {
      dispatch(deselectAccounts())
      data.forEach((element) => {
        selectAccount(element[OBJECT_VARS.accountKey])
      })
    } else {
      dispatch(deselectAccounts())
    }
  }

  const dataStateChange = (event) => {
    setDataResult(process(filteredSampleProducts, event.dataState))
    setDataState(event.dataState)
  }

  const _export = React.useRef(null)

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save()
    }
  }

  const FormatAddress = (props) => {
    return (
      <td>
        {props.dataItem[OBJECT_VARS.serviceAddress]} {props.dataItem[OBJECT_VARS.subDesignation]}
      </td>
    )
  }

  const FormatDueDate = (props) => {
    const dueDate = props.dataItem[OBJECT_VARS.dueDate]

    return dueDate === undefined ? (
      <td>
        {' '}
        <Skeleton count={1} />
      </td>
    ) : (
      <td>{moment(props.dataItem[OBJECT_VARS.dueDate]).format('MMMM Do, YYYY')}</td>
    )
  }
  const onFilterChange = (ev) => {
    const { value } = ev
    setFilterValue(ev.value)
    const newData = data.filter((item) => {
      let match = false
      for (const property in item) {
        if (
          item[property]?.toString().toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true
        } else {
        }
        // ensure that toLocaleDateString matches with the displayed format in the Column
        // if not, modify the logic so that you can compare same string from the cell with the input
        if (
          item[property]?.toLocaleDateString &&
          item[property]?.toLocaleDateString().indexOf(value) >= 0
        ) {
          match = true
        }
      }
      return match
    })
    setFilteredSampleProducts(newData)
    const clearedPagerDataState = { ...initialDataState, take: initialDataState.take, skip: 0 }
    const processedData = process(newData, clearedPagerDataState)

    setDataResult(processedData)
    setDataState(clearedPagerDataState)
  }

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded
    event.dataItem.expanded = !isExpanded

    setDataResult({ ...dataResult })
  }

  const FormatAmi = (props) => {
    return (
      <td style={{ justifyContent: 'center' }}>
        {props.dataItem[OBJECT_VARS.isAmiEnabled] === 'True' ? (
          <>
            <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={amiLogo}
                alt="ConnectH2O account logo"
                style={{
                  height: '23px',
                  width: '50px',
                  top: '-5px',
                  right: '5px',
                  paddingLeft: '20px',
                }}
              />
            </div>
          </>
        ) : (
          ''
        )}
      </td>
    )
  }

  const FormatAccountStatus = (props) => {
    const status = props.dataItem[OBJECT_VARS.accountStatus]

    return status === undefined ? (
      <td>
        <Skeleton count={1} />
      </td>
    ) : (
      <td
        style={
          status === 'Past Due' || status === 'Delinquent' ? { color: 'red' } : { color: 'green' }
        }
      >
        {status}
      </td>
    )
  }
  const FormatAmountDue = (props) => {
    const amountDue = props.dataItem[OBJECT_VARS.finalBalance]

    return amountDue === undefined ? (
      <td>
        <Skeleton count={1} />
      </td>
    ) : (
      <td>${amountDue}</td>
    )
  }

  const ViewBillButton = (props) => {
    return (
      <td>
        <ViewBillComponent accountKey={props.dataItem.accountKey} billKey={props.dataItem.billKey}>
          View
        </ViewBillComponent>
      </td>
    )
  }

  return payments.isLoading ? (
    <div style={{ height: '200px', width: '600px' }}>
      <Skeleton count={10} />
    </div>
  ) : (
    <ExcelExport data={data} ref={_export}>
      <Grid
        data={dataResult.data.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        {...initialDataState}
        pageable
        sortable
        reorderable
        onDataStateChange={dataStateChange}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple',
        }}
        total={dataResult.total}
        expandField="expanded"
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        onExpandChange={expandChange}
      >
        <GridToolbar>
          <div>
            <span>
              <Input
                style={{ width: '250px' }}
                placeholder="Search..."
                value={filterValue}
                onChange={onFilterChange}
              />
            </span>

            <Button
              title="Export Excel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary ml-3"
              onClick={excelExport}
            >
              Export to Excel
            </Button>
          </div>
        </GridToolbar>
        <GridColumn
          field={SELECTED_FIELD}
          width="70px"
          headerSelectionValue={
            dataResult.data.findIndex((item) => !selectedState[idGetter(item)]) === -1
          }
        />
        <GridColumn field="nickName" title="Nickname" />
        <GridColumn field="billNo" title="View Bill" cell={ViewBillButton} />
        <GridColumn field="serviceAddressLine" title="Address" cell={FormatAddress} />
        <GridColumn
          field="finalBalance"
          title="Amount Due"
          format="{0:c2}"
          cell={FormatAmountDue}
        />
        <GridColumn field="dueDate" title="Date" cell={FormatDueDate} />
        <GridColumn field="accountNumber" title="Account Number" />
        <GridColumn field="accountStatus" title="Account Status" cell={FormatAccountStatus} />
        <GridColumn field="" width="150px" cell={FormatAmi} />
      </Grid>
    </ExcelExport>
  )
}

export { ListGrid }
