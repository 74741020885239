import React from 'react'
import { Animated } from 'react-animated-css'
import FilterResults from 'react-filter-search'
import { useDispatch } from 'react-redux'
import { OBJECT_VARS } from '@/utils/models'
import { useCommonApi } from '@/redux/ducks/commonDuck'
import { SearchBarComponent } from '@/components/common/SearchBarComponent'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { useMultiplePayments } from '@/hooks/usePayments.queries'
import { Spin } from 'antd'
import { GridCard } from './GridCard'
import { formatDate } from '../../../utils/utils'

// ------------------ Styled Componets ------------------- //

const combineData = (accounts, payments) => {
  if (!payments || !accounts) return []
  return accounts.map((account) => {
    const paymentDataForAccount = payments.find((el) => el.accountKey === account.accountKey)

    const formattedDueDate = paymentDataForAccount
      ? formatDate(paymentDataForAccount.dueDate)
      : null
    return { ...account, ...paymentDataForAccount, dueDate: formattedDueDate }
  })
}
const GridView = () => {
  const dispatch = useDispatch()
  const accountsQuery = useAccounts()
  const accounts = accountsQuery.data
  const paymentsQuery = useMultiplePayments()
  const payments = paymentsQuery.filter((el) => el.isSuccess === true).map((el) => el.data[0])
  const combinedAccountPaymentData = combineData(accounts, payments)
  const selectAccount = (accountKey) => dispatch(useCommonApi.selectAccount(accountKey))
  const [searchValue, setSearchValue] = React.useState('')

  return (
    <>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="100" isVisible>
        <SearchBarComponent value={searchValue} setValue={setSearchValue} />
      </Animated>
      <div className="grid-container mt-3" style={{ height: 'calc(100vh - (300px))' }}>
        {combinedAccountPaymentData.length > 0 || accounts > 0 ? (
          <FilterResults
            placeholder="Search for Street Name, Account Number, Nickname, etc..."
            value={searchValue}
            data={combinedAccountPaymentData || accounts} // A workaround to display skeleton rows. TODO: use only combinedAccountPaymentData after backend handles query search
            renderResults={(results) =>
              results.map((account) => (
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  animationInDelay="800"
                  isVisible
                >
                  <GridCard
                    key={account[OBJECT_VARS.accountKey]}
                    account={account}
                    selectAccount={selectAccount}
                  />
                </Animated>
              ))
            }
          />
        ) : (
          <Spin size="large" />
        )}
      </div>
    </>
  )
}

export { GridView }
