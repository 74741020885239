import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { getCanViewSummary } from '@/redux/ducks/serviceRequestDuck'
import { serviceRequestActions } from '@/redux/reducers'
import { MoveOutEditPage } from './edit/MoveOutEditPage.component'
import { MoveOutSummaryPageComponent } from './summary/MoveOutSummaryPageComponent'
import { MoveOutBasePage } from './base/MoveOutBasePage.component'
import { useFinalBilling } from './useFinalBilling.hook'
import { useSubmitServiceRequest } from './useSubmitServiceRequest.queries'
import { useMoveOutSummary } from './useMoveOutSummary.hook'

const MoveOutRouter = ({ routerProps }) => {
  const dispatch = useDispatch()
  const { saveCanViewSummary } = serviceRequestActions
  const selectedAccounts = useSelector(getSelectedAccountKeys)
  const canViewSummary = useSelector(getCanViewSummary)
  const addressQueries = useFinalBilling(selectedAccounts)
  const { addresses, cleanUp } = addressQueries
  const [moveOutDate, setMoveOutDate] = React.useState(null)
  const { summary } = useMoveOutSummary(addresses, moveOutDate)
  const { history, match } = routerProps
  const pageUrls = {
    base: match.url,
    edit: `${match.url}/edit`,
    summary: `${match.url}/summary`,
  }
  const serviceRequestMutation = useSubmitServiceRequest({
    onSuccess: () => {
      dispatch(saveCanViewSummary(true))
      history.push(pageUrls.summary)
    },
  })

  // let child write its own execution path
  // pass down the listener
  const unlisten = (cb) => history.listen((props) => cb(props))

  return (
    <Switch>
      <Route exact path={pageUrls.base}>
        <MoveOutBasePage
          isDisabled={selectedAccounts.length === 0}
          resetAddressState={cleanUp}
          onContinue={() => history.push(pageUrls.edit)}
        />
      </Route>
      <Route exact path={pageUrls.edit}>
        {selectedAccounts.length > 0 ? (
          <MoveOutEditPage
            getAddresses={addressQueries}
            moveOutDate={moveOutDate}
            setMoveOutDate={setMoveOutDate}
            serviceRequestMutation={serviceRequestMutation}
          />
        ) : (
          <Redirect to={pageUrls.base} />
        )}
      </Route>
      <Route exact path={pageUrls.summary}>
        {canViewSummary ? (
          <MoveOutSummaryPageComponent summary={summary} listener={unlisten} />
        ) : (
          <Redirect to={pageUrls.base} />
        )}
      </Route>
    </Switch>
  )
}

export { MoveOutRouter }
