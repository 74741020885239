import React from 'react'
import bg from '@/images/LoginBG.png'
import { Box } from '@/components/common/Box'
import { Title, Terms } from '@/components/common/Typography'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { Link } from 'react-router-dom'
import { CreateAccountForm } from './CreateAccountForm'

const Container = styled.div`
  height: 100%;
  padding: 60px;
  background: linear-gradient(0deg, rgba(86, 145, 200, 0.6), rgba(86, 145, 200, 0.6)), url(${bg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormContainer = styled(Box)`
  background: #fff;
`

const StyledTerms = styled(Terms)`
  color: #fff;
  margin: 47px auto;
  width: 320px;
  line-height: 14px;
`

const CreateAccount = () => {
  return (
    <Container>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="500" isVisible>
        <Title fontSize={2} bottomGutter={1}>
          Create New User Account
        </Title>
      </Animated>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
        <Box flexDirection="row" marginBottom={2}>
          <FormContainer style={{ width: '420px' }} padding={2} flexCenter>
            <CreateAccountForm />
          </FormContainer>
        </Box>
      </Animated>
      <StyledTerms>
        By clicking &quot;Submit&quot; you are agreeing to the{' '}
        <Link to="/tc" alt="Terms and conditions" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </Link>{' '}
        for using this site, last updated on 05/25/2018
      </StyledTerms>
    </Container>
  )
}

export default CreateAccount
