import { createSlice } from '@reduxjs/toolkit'
import { GRID_VIEW, TABLE_VIEW } from '@/redux/ducks/commonDuck'

const initialState = {
  settings: { accountViewType: GRID_VIEW },
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState.settings,
  reducers: {
    selectGridView: (state) => {
      state.accountViewType = GRID_VIEW
    },
    selectListView: (state) => {
      state.accountViewType = TABLE_VIEW
    },
  },
})

export default settingsSlice
