import React from 'react'
import { MapView } from './MapView'

const Map = () => {
  return (
    <>
      <MapView mapHeight="900px" />
    </>
  )
}

export default Map
