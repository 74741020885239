import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_PAYMENT_HISTORY_BY_PAYMENT_METHOD = `${process.env.REACT_APP_API_BASE_URL}/payment-public-api/public/api/getpayhistorybypaymentmethod`

const fetchRecentTransactions = (paymentMethodId) => {
  const url = `${GET_PAYMENT_HISTORY_BY_PAYMENT_METHOD}?paymentMethodId=${paymentMethodId}&fromCount=0&toCount=10`
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: false,
  })
}

const useRecentTransactions = (paymentMethodId, options) => {
  return useQuery(
    ['recentTransactions', paymentMethodId],
    () => fetchRecentTransactions(paymentMethodId),
    {
      ...options,
    }
  )
}

export { useRecentTransactions }
