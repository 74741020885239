import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2d2d2d;
  color: #ffffff;
  padding-top: 25px;
  padding-bottom: 25px;
`

const TradeMarkText = styled.p`
  position: relative;
  font-size: 14px;
  margin: 0;
`

const Watermark = styled.span``

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <Container>
      <TradeMarkText>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
          <Watermark>&#169;{year} - San Antonio Water System</Watermark>
          <div style={{ position: 'absolute', color: '#2d2d2d' }}>{process.env.NODE_ENV}</div>
        </Animated>
      </TradeMarkText>
    </Container>
  )
}

export default Footer
