import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useSelector } from 'react-redux'
import { getUserId } from '@/redux/ducks/commonDuck'

const GET_NOTIFICATIONS = `${process.env.REACT_APP_API_BASE_URL}/notifications-public-api/public/api/getnotifications`

const getNotifications = (userId) => {
  const url = `${GET_NOTIFICATIONS}/${userId}/${process.env.REACT_APP_APP_ID}`
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: false,
  })
}

const useNotifications = (options) => {
  const userId = useSelector(getUserId)
  return useQuery(['notifications'], () => getNotifications(userId), {
    ...options,
  })
}

export default useNotifications
