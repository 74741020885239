import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import { useQueryClient } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { usePaymentApi } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { parseDollars } from '@/utils/utils'
import { FormError } from '@/components/common/FormError'
import { getSelectedPaymentMethodId } from '@/redux/ducks/paymentDuck'
import { getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { usePayments } from '@/hooks/usePayments.queries'
import { Typography } from '@progress/kendo-react-common'
import { loadFromLocalStorage } from '@//token'
import { PaymentFormComponent } from './PaymentFormComponent'
import { ScheduleComponent } from './ScheduleComponent'
import { AccountTableComponent } from './AccountTableComponent'
import { SubmitPaymentPayButtons } from './SubmitPaymentPayButtons'
import { usePaymentMethods } from '../Wallet/queries/usePaymentMethods.queries'
import { useSubmitPayment } from './useSubmitPayment.queries'

// ------------------------------ Styled Components --------------------------- //

const PaymentContainer = styled.div`
  display: grid;
  grid-template-columns: 366px 0fr;
  grid-template-rows: 1fr;
  justify-content: center;
  height: max-content;
  padding: 0;
`

const FormContainer = styled.div`
  justify-content: center;
`

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TotalDivider = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 50%;
  border: 1px solid #000;
`

const AccountTableContainer = styled.div`
  margin-bottom: 20px;
  max-height: 500px;
  overflow: auto;
  max-width: 960px;
`

const AccountSelectedTitle = styled.h3`
  font-size: 18px;
  margin-top: 20px;
  font-weight: 700;
  color: #4f4f4f;
`

const AccountSelectedHeadings = styled.h4`
  font-weight: 600;
  margin-bottom: 0;
`

const Row = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.text}`};
  display: flex;
  justify-content: space-between;
  font-size: 15px;
`
const Cell = styled.div`
  position: relative;
  text-align: left;
  margin-right: 10px;
  margin-left: 13px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 11px;
  padding-bottom: 9px;
  color: #4f4f4f;
  min-width: 150px;
  width: 100%;
  &:first-of-type {
    flex: 1 0 7%;
  }
  &:nth-of-type(2) {
    flex: 1;
  }
  &:nth-of-type(3) {
    flex: 1;
  }
`

const MycButton = styled(Button)`
  &:hover,
  .btn-warning,
  &:active,
  &:focus {
    background-color: ${({ variant, theme }) => theme[variant]};
    color: #fff;
  }
  border-radius: 4px;
  border-color: transparent;
  background: ${({ variant, theme }) => theme[`${variant}Dark`]};
  color: #fff;
  width: 360px;
  padding: 20px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 100;
  margin: 10px 0;
`
const SelectDateBtn = styled(MycButton)`
  display: block;
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 15px;
`

const CenterContent = styled.div`
  text-align: center;
`

const SelectDateButton = ({ onClick, ...rest }) => {
  return (
    <SelectDateBtn
      class="btn-myc-green"
      type="button"
      variant="success"
      onClick={onClick}
      {...rest}
    >
      Select Date
    </SelectDateBtn>
  )
}

// ------------------------------------------------------------------------- //

const initializePaymentFormData = (accountData, paymentData, selectedAccountKeys) => {
  if (!paymentData || !selectedAccountKeys) return null

  return paymentData
    .map((item) => {
      return {
        accountKey: item.accountKey,
        contactKey: item.contactKey,
        amount: item.finalBalance < 0 ? 0 : item.finalBalance,
        owed: item.finalBalance < 0 ? 0 : item.finalBalance,
      }
    })
    .map((item) => {
      const accountDataByKey = accountData.find((account) => account.accountKey === item.accountKey)
      return {
        ...item,
        ...accountDataByKey,
      }
    })
}

const Payment = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const selectedAccountKeys = useSelector(getSelectedAccountKeys)
  const paymentDataQuery = usePayments(selectedAccountKeys)
  const accountData = queryClient.getQueryData('accounts')
  const { mutate, isSuccess, reset } = useSubmitPayment()
  const methods = useForm()

  const [willSchedule, setWillSchedule] = React.useState(false)
  const [selectedDay, setSelectedDay] = React.useState(new Date())
  React.useEffect(() => {
    dispatch(usePaymentApi.setPaymentDate(selectedDay.toUTCString()))
  }, [selectedDay])

  const total = useSelector((state) => selectors.payment.getTotalAmountToBePaid(state))

  const selectedPaymentMethodId = useSelector(getSelectedPaymentMethodId)
  const selectedPaymentMethodQuery = usePaymentMethods({
    select: (res) => res.find((el) => el.id === selectedPaymentMethodId),
  })
  const selectedPaymentMethod = selectedPaymentMethodQuery.data

  const handleDisplayScheduler = (e) => {
    e.preventDefault()
    setWillSchedule(!willSchedule)
  }

  const onSubmit = () => {
    dispatch(usePaymentApi.savePaymentMethod(selectedPaymentMethod))
    dispatch(usePaymentApi.willSchedulePayment(willSchedule))
    mutate()
  }

  const memoOnSubmit = React.useCallback(methods.handleSubmit(onSubmit), [
    selectedPaymentMethod,
    willSchedule,
  ])

  React.useEffect(() => {
    dispatch(
      usePaymentApi.saveInitData(
        initializePaymentFormData(accountData, paymentDataQuery.data, selectedAccountKeys)
      )
    )
    reset()
  }, [paymentDataQuery.isSuccess])

  React.useEffect(() => {
    if (isSuccess) {
      history.push('/summary')
    }
  }, [isSuccess])

  const [impersonate, setImpersonate] = React.useState(false)
  React.useEffect(() => {
    const { isImpersonating } = loadFromLocalStorage('token') || {}
    setImpersonate(isImpersonating)
  }, [])

  return (
    <div className="row m-4">
      <div className="col-xl-4" style={{ 'max-width': '300px !important' }}>
        <div className="row h-100">
          <div className="col-12">
            <div className="card card-shadow p-4 h-100" style={{ height: '85vh' }}>
              <div className="row">
                <div className="col-lg-12">
                  <Typography.h5 className="text-center">Bank Account Information</Typography.h5>
                  <Typography.h6 className="text-center">(Saved From Wallet)</Typography.h6>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <FormContainer>
                    <PaymentFormComponent />
                  </FormContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-shadow p-4" style={{ width: '600px' }}>
          <PaymentContainer>
            <BalanceContainer>
              <p className="total-title">Total</p>
              <TotalDivider />
              <div className="dollar-total" style={{ fontSize: '4vw', maxWidth: '100%' }}>
                {parseDollars(total)}{' '}
              </div>
              <AccountSelectedTitle>Accounts Selected</AccountSelectedTitle>
              <AccountSelectedHeadings>
                <Row>
                  <Cell>SAWS Account</Cell>
                  <Cell>Amount Due</Cell>
                  <Cell>Amount to be Paid</Cell>
                </Row>
              </AccountSelectedHeadings>
              <FormProvider {...methods}>
                <form>
                  <AccountTableContainer>
                    <AccountTableComponent numRows={selectedAccountKeys.length} />
                  </AccountTableContainer>
                  <CenterContent>
                    {willSchedule && (
                      <ScheduleComponent
                        title="Schedule a Payment"
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        onClick={handleDisplayScheduler}
                        showDayPicker={willSchedule}
                        customInput={<SelectDateButton />}
                      />
                    )}
                    {!selectedPaymentMethod && (
                      <div style={{ textAlign: 'center' }}>
                        <FormError
                          style={{
                            position: 'absolute',
                            background: 'whitesmoke',
                            textAlign: 'left',
                          }}
                        >
                          Please select a payment method. You can add a payment method in the{' '}
                          <a href="/wallet">My Wallet page.</a>
                        </FormError>
                      </div>
                    )}
                    <SubmitPaymentPayButtons
                      selectedDay={selectedDay}
                      isDisabled={
                        !selectedPaymentMethod ||
                        impersonate === true ||
                        selectedAccountKeys.length === 0
                      }
                      willSchedule={willSchedule}
                      onClick={handleDisplayScheduler}
                      onSubmit={memoOnSubmit}
                    />
                  </CenterContent>
                </form>
              </FormProvider>
            </BalanceContainer>
          </PaymentContainer>
        </div>
      </div>
    </div>
  )
}

export { Payment }
