import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { WaterDroplet } from '@/components/animations/waterdroplet/WaterDroplet'
import { BankAccountCard } from '../BankAccountCard'
import { usePaymentMethods } from '../queries/usePaymentMethods.queries'

const NoPaymentMethodsMessage = styled.div`
  text-align: left;
`

const PaymentMethodsList = ({ setSelectedPaymentMethodId }) => {
  const { data } = usePaymentMethods()

  if (!data) {
    return <WaterDroplet />
  }
  if (data.length === 0) {
    return (
      <NoPaymentMethodsMessage>
        No payment methods found, please add a new payment method to the left.
      </NoPaymentMethodsMessage>
    )
  }
  if (data) {
    return data.map((paymentMethod) => {
      return (
        <>
          <div className="card card-shadow mb-3">
            <BankAccountCard
              method={paymentMethod}
              setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            />
          </div>
        </>
      )
    })
  }
  return null
}

PaymentMethodsList.propTypes = {
  setSelectedPaymentMethodId: PropTypes.func.isRequired,
}

export { PaymentMethodsList }
