import dayjs from 'dayjs'

const holidaysObservedAtSaws = [
  dayjs('12/22/2023'),
  dayjs('12/25/2023'),
  dayjs('12/29/2023'),
  dayjs('1/01/2024'),
  dayjs('1/15/2024'),
  dayjs('4/1/2024'),
  dayjs('4/26/2024'),
  dayjs('5/27/2024'),
  dayjs('6/19/2024'),
  dayjs('7/4/2024'),
  dayjs('9/2/2024'),
  dayjs('11/28/2024'),
  dayjs('11/29/2024'),
  dayjs('12/24/2024'),
  dayjs('12/25/2024'),
  dayjs('12/31/2024'),
]

const isHoliday = (date) => holidaysObservedAtSaws.some((el) => dayjs(el).isSame(dayjs(date)))

export { isHoliday }
