import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { getBillPdf } from '@/redux/api'
import { Button } from '@progress/kendo-react-buttons'

const ViewBillComponent = ({ children, accountKey, billKey, ...rest }) => {
  const [loading, setLoading] = React.useState(false)

  const handleViewPdf = () => {
    const shouldFetch = true
    setLoading(true)
    const getBill = () => getBillPdf(accountKey, billKey)
    if (shouldFetch) {
      getBill()
        .then((res) => res.blob())
        .then((data) => {
          const newBlob = new Blob([data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(newBlob)
          window.open(url)
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <Button onClick={() => handleViewPdf()} className="btn-myc-col" {...rest}>
          {children}
        </Button>
      )}
    </>
  )
}

export { ViewBillComponent }
