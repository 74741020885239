import React from 'react'
import './styles.scss'
import { Typography } from '@progress/kendo-react-common'
import { PaymentMethodDetailsView } from './ViewDetails/PaymentMethodDetailsView.component'
import { AddBankForm } from './AddNew/AddBankForm.component'

const Wallet = () => {
  return (
    <>
      <div className="row mt-2">
        <div className="col-xl-4">
          <div className="row">
            <div className="col-12">
              <Typography.h5>Add Bank Account</Typography.h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card card-shadow p-4" style={{ 'max-width': '350px' }}>
                <Typography.h5 className="text-center">Bank Account Information</Typography.h5>
                <AddBankForm />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8" style={{ 'border-left': '1px solid #dcdcdc' }}>
          <div className="row">
            <div className="col-12">
              <Typography.h5>My Wallet</Typography.h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <PaymentMethodDetailsView />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Wallet }
