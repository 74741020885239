import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { OBJECT_VARS } from '@/utils/models'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { getUserId, getSelectedAccountKeys } from '@/redux/ducks/commonDuck'
import { getScheduledPaymentsAsArray, getSynchronizationId } from '@/redux/ducks/paymentDuck'

const SUBMIT_PAYMENTS = `${process.env.REACT_APP_API_BASE_URL}/payment-processing-public-api/public/api/submitpayments`

const submitPaymentToInfor = (req) => {
  return fetchWrapper(SUBMIT_PAYMENTS, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(req),
    requiresInfor: true,
  })
}

const useSubmitPayment = (options) => {
  const queryClient = useQueryClient()
  const userId = useSelector(getUserId)
  const paymentsArray = useSelector(getScheduledPaymentsAsArray)
  const synchronizationId = useSelector(getSynchronizationId)
  const selectedAccountKeys = useSelector(getSelectedAccountKeys)

  return useMutation(
    () => {
      const req = {
        [OBJECT_VARS.createdBy]: 'mycportal',
        [OBJECT_VARS.userId]: userId,
        [OBJECT_VARS.synchronizationId]: synchronizationId,
        payments: paymentsArray,
        [OBJECT_VARS.appId]: 2,
      }
      toast.promise(
        submitPaymentToInfor(req),
        {
          loading: 'Submitting your request. Please do not refresh the page.',
          success: () => (
            <>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <b>Thank You!</b>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  Your payment has been submitted!
                </div>
              </div>
            </>
          ),
          error: 'Error when fetching',
        },
        {
          style: { border: 'green', minWidth: '350px' },
          success: {
            style: { background: 'green', color: '#ffffff', minWidth: '350px' },
            duration: 8000,
          },
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === 'lastpayment' &&
            selectedAccountKeys.indexOf(query.queryKey[1]) >= 0,
        })

        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === 'payments' &&
            selectedAccountKeys.indexOf(query.queryKey[1][0]) >= 0,
        })

        queryClient.invalidateQueries('paymentHistory')
        queryClient.invalidateQueries('recentTransactions')
      },
    }
  )
}

export { useSubmitPayment }
