import { useQuery, useQueries } from 'react-query'
import { fetchWrapper } from '@/redux/api'
import { useGetAccountKeys } from './useAccounts.queries'

const GET_CONSUMPTION_DATA = `${process.env.REACT_APP_API_BASE_URL}/consumption-public-api/public/api/gallonsanalysis/`

const GET_CONSUMPTION_DATA_AMI = `${process.env.REACT_APP_API_BASE_URL}/ami-public-api/public/api/getgroupedconsumptionbymeterid`

const fetchConsumptionData = (accountKey) => {
  const url = GET_CONSUMPTION_DATA + accountKey
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const fetchConsumptionDataAmi = (accountKey, meterId, startDate, endDate, grouping, x52id) => {
  const url = GET_CONSUMPTION_DATA_AMI

  const correlation = crypto.randomUUID()

  const meterReadingRequestData = {
    MeterId: meterId,
    StartDate: startDate.toLocaleDateString('en-US'),
    EndDate: endDate.toLocaleDateString('en-US'),
    GroupBy: 2,
    CorrelationId: correlation,
    x52id,
    appId: 2,
  }

  return fetchWrapper(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(meterReadingRequestData),
    requiresInfor: true,
  })
}

const useConsumption = (accountKey, options) => {
  return useQuery(['consumption', accountKey], () => fetchConsumptionData(accountKey), {
    staleTime: 1000 * 60 * 20,
    ...options,
  })
}

const useConsumptionAmi = (accountKey, meterId, startDate, endDate, grouping, x52id, options) => {
  return useQuery(
    ['consumptionAmi', meterId, startDate, endDate, accountKey, grouping, x52id],
    () => fetchConsumptionDataAmi(accountKey, meterId, startDate, endDate, grouping, x52id),
    {
      ...options,
    }
  )
}

const useConsumptions = () => {
  const accountKeys = useGetAccountKeys() || []

  return useQueries(
    accountKeys.map((key) => {
      return {
        queryKey: ['consumption', key],
        queryFn: () => fetchConsumptionData(key),
        staleTime: 1000 * 60 * 20,
      }
    })
  )
}

export { useConsumption, useConsumptionAmi, useConsumptions }
