import React from 'react'
import { OBJECT_VARS } from '@/utils/models'
import Skeleton from 'react-loading-skeleton'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid'
import { Button } from '@progress/kendo-react-buttons'
import moment from 'moment'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { process } from '@progress/kendo-data-query'
import { usePaymentHistory } from './usePaymentHistory.queries'

const initialDataState = {
  sort: [],
  take: 10,
  skip: 0,
}

const PaymentHistoryComponent = ({ accountKey }) => {
  const _export = React.useRef(null)

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save()
    }
  }

  const [dataState, setDataState] = React.useState(initialDataState)

  const FormatDate = (props) => {
    return <td>{moment(props.dataItem[OBJECT_VARS.date]).format('MMMM Do, YYYY')}</td>
  }

  const query = usePaymentHistory(accountKey)

  return query.isLoading ? (
    <div style={{ height: '200px', width: '600px' }}>
      <Skeleton count={10} />
    </div>
  ) : (
    <>
      <ExcelExport data={query.data} ref={_export}>
        <Grid
          pageable
          sortable
          data={process(query.data, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState)
          }}
        >
          <GridToolbar>
            <Button
              title="Export Excel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
            >
              Export to Excel
            </Button>
          </GridToolbar>
          <GridColumn field="date" title="Date" cell={FormatDate} />
          <GridColumn field="amount" title="Amount" format="{0:c2}" />
          <GridColumn field="displayName" title="Status" />
          <GridColumn field="confirmation" title="Confirmation #" />
        </Grid>
      </ExcelExport>
    </>
  )
}
export { PaymentHistoryComponent }
