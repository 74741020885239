import React from 'react'
import styled from 'styled-components'
import { IconComponent } from '@/components/common/IconComponent'
import { ICONS } from '@/utils/icons'
import { Button } from '@progress/kendo-react-buttons'

const Wrapper = styled.div`
  margin-top: 1rem;
`

const ConfirmDeleteButtonComponent = ({ onClick, isFetching, disabled }) => {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleClick = () => {
    if (!showConfirm) {
      return setShowConfirm(true)
    }
    onClick()
    setShowConfirm(false)
  }

  return (
    <Wrapper>
      {showConfirm && (
        <Button className="btn-myc-col mr-2" onClick={() => setShowConfirm(false)}>
          <IconComponent icon={ICONS.undo} rightMargin={0} />
        </Button>
      )}
      <Button className="btn-myc-red" onClick={handleClick} disabled={disabled}>
        {showConfirm && 'Confirm'}
        {!showConfirm && !isFetching && 'Remove This Account'}
        {isFetching && 'Removing...'}
      </Button>
    </Wrapper>
  )
}

export { ConfirmDeleteButtonComponent }
