import React from 'react'
import { Link } from 'react-router-dom'

const TC = () => {
  return (
    <div style={{ padding: '0 50px', width: '80vw' }}>
      <br />
      <h1>SAWS CUSTOMER PORTAL SERVICE AGREEMENT</h1>

      <p>
        By using San Antonio Water System ('SAWS')'s Customer Portal Service (the 'Service'), you
        are agreeing to the terms and conditions of this Agreement with SAWS. The Service is
        provided in part with assistance from third parties, including but not limited to third
        party payment processors approved by SAWS ('Third Party Payment Processors'). You agree we
        may share information with such third parties and understand that these third parties will
        be allowed to use information collected from you only as necessary to assist SAWS in
        providing the Service according to the terms set forth herein.
      </p>

      <h3>Enrollment</h3>

      <p>
        To enroll in the Service, you'll provide your SAWS account number, service address, user
        name, email address, secret question and password in our new account form. If all the
        information provided validates with our records and security measures you will receive a
        confirmation message and be able to log in using the user name and password entered into the
        new account form.
      </p>

      <h3>The Service, Generally</h3>

      <p>The Service consists of the following features:</p>

      <ol>
        <li style={{ padding: '5px 0' }}>Electronic Bill Presentment;</li>
        <li style={{ padding: '5px 0' }}>Online Bill Payment;</li>
        <li style={{ padding: '5px 0' }}>Service Account Support;</li>
        <li style={{ padding: '5px 0' }}>
          Other features and services SAWS may elect to provide from time to time under this
          Agreement.
        </li>
      </ol>

      <p>
        SAWS reserves the right to modify or discontinue any or all features of the Service at any
        time without notice, or to offer specific features only to qualified customers. You may
        elect to use one or more of the features without using them all. Notwithstanding anything to
        the contrary, you are responsible for ensuring timely payment of all bills.
      </p>

      <h3>Electronic Bill Presentment. ('Customer Portal Mail')</h3>

      <p>
        When you sign up for the Service, you will no longer receive a paper bill and information
        inserts in the mail.&nbsp; Instead, if you select this feature, a notification that your
        monthly SAWS water service bill is available will be sent to your email address, along with
        a link to view the bill online. We will not charge you fees for utilizing the electronic
        bill presentment service. If you would like to continue to receive a paper bill and
        information inserts by mail, you may update your online account profile.
      </p>

      <p>
        In the event you do not receive notification (such as in the case of your providing an
        invalid email address), it is your responsibility to periodically logon to the service and
        check for delivery of new electronic bills. If you do not receive your email notification,
        contact SAWS immediately at Call.CenterWeb@saws.org or 210-704-7297.&nbsp; Failure to
        receive email notification will not extend the due date of your bill and you will still be
        responsible for timely payment of your bill.
      </p>

      <p>Delinquent notices will be sent through postal mail only.</p>

      <p>SAWS reserves the right to cancel the presentment of electronic bills at any time.</p>

      <p>
        You may cancel electronic bill presentment at any time. Cancellation of your electronic bill
        presentment may take up to thirty (30) days.
      </p>

      <h3>Online Bill Payment. ('Customer Portal Bill Pay')</h3>

      <p>
        You may use this feature to pay your SAWS bill online, directly from your bank account and /
        or using specific credit or debit cards accepted by SAWS, subject to the terms and
        restrictions of this Agreement. "Payment Account" refers to the source used for your online
        payment, whether it be your bank account or your credit or debit card account.&nbsp; The
        following conditions apply, depending on the method of payment you elect:
      </p>

      <p>1. Direct Debit From Your Bank Account</p>

      <p>
        Direct Debit (Automatic Payment) is an electronic payment plan designed to make payment of
        your SAWS bill an automatic process. The plan neither increases nor decreases your total
        bill.
      </p>

      <p>
        Direct Debit is available to customers who bank with accredited financial institutions
        capable of accepting automatic withdrawals via the Automatic Clearing House (ACH) Network,
        which includes most San Antonio financial institutions.
      </p>

      <p>
        Any customer on the Direct Debit plan may be withdrawn from the plan for 90 days if
        automatic withdrawal transactions are not honored by the bank for any reason. In addition,
        the customer may be charged a service charge and late payment penalty if applicable.
      </p>

      <p>
        You must notify SAWS immediately if there is a change in your bank or bank account where the
        automatic withdrawal is being administered. Billing disputes should be discussed with a SAWS
        customer service associate, not the bank, at least five business days before the date of the
        automatic withdrawal as shown on your SAWS bill.
      </p>

      <p>
        To discontinue participation in the Direct Debit plan, you must cancel Direct Debit by using
        the SAWS Customer Portal or notifying a SAWS customer service associate by calling 210-
        704-7297 within five business days before the bill due date. Once discontinuation has been
        completed, your payment options will revert to the following: one-time online payment,
        payment by mail, or payment in person.
      </p>

      <p>
        Discontinuation of Direct Debit services may take up to 30 business days once notification
        has been received.
      </p>

      <p>2. Credit or Debit Cards Accepted by SAWS</p>

      <p>
        You may make one-time payments using only those credit and debit cards accepted by
        SAWS.&nbsp;
      </p>

      <p>
        SAWS' Third Party Payment Processor accepts the following cards:&nbsp; MasterCard, VISA,
        Discover, and American Express.
      </p>

      <p>3. Checking and Savings</p>

      <p>You may make one-time payments from your checking or savings account.&nbsp;</p>

      <p>
        Your bank must be an accredited financial instruction capable of accepting automatic
        withdrawals via the Automatic Clearing House (ACH) Network, which includes most San Antonio
        financial institutions.
      </p>

      <h3>Payment Authorization And Payment Remittance</h3>

      <p>
        By initiating an online bill payment, you (i) authorize SAWS to follow the payment
        instructions that we receive from you, (ii) certify that any Payment Account you provide is
        an account from which you are authorized to make payments, and any payment you make using
        the Service will debit a Payment Account that you are legally authorized to use, (iii)
        authorize SAWS or Third Party Payment Processors to debit your Payment Account and remit to
        SAWS funds on your behalf so that the funds can be applied to your SAWS account, (iv) if
        payment is being made through a Third Party Payment Processor, you authorize such Third
        Party Payment Processor to debit your Payment Account and remit on your behalf any
        applicable fees due to such Third Party Payment Processor; and (iv) authorize SAWS to credit
        your Payment Account for payments returned for any reason.
      </p>

      <p>
        In order to process payments more efficiently and effectively, we may edit or alter payment
        data or data formats. However, we shall incur no liability if the Service is unable to
        complete any payments initiated by you because of the existence of any one or more of the
        following circumstances:
      </p>

      <ol>
        <li style={{ padding: '5px 0' }}>
          If your Payment Account does not contain sufficient funds to complete the transaction or
          the transaction would exceed the credit limit of your Payment Account;
        </li>
        <li style={{ padding: '5px 0' }}>
          The payment processing center used by the Service is not working properly and you know or
          have been advised about the malfunction before you execute the transaction;
        </li>
        <li style={{ padding: '5px 0' }}>
          You have not provided SAWS with the correct Payment Account information; and/or,
        </li>
        <li style={{ padding: '5px 0' }}>
          Circumstances beyond our control (such as, but not limited to, fire, flood, or
          interference from an outside force) interfere with the proper execution of the
          transaction.
        </li>
      </ol>

      <p>
        Provided none of the foregoing exceptions are applicable, if the Service causes an incorrect
        amount of funds to be debited from your Payment Account, SAWS will create a credit on your
        SAWS account. We do not accept scheduled payments. All payments are considered received by
        SAWS on the date made until 5 p.m. CT. Payments made after 5 p.m. CT will be considered
        received on the next business day. Payments may take up to 5 business days to process and be
        posted to your SAWS account, however, any such payments will not be considered late if
        received by 5 p.m. CT on the due date.
      </p>

      <h3>Service Account Support (Customer Portal Support)</h3>

      <h3>Subject to the Terms of Service (as defined below), you may use this feature to:</h3>

      <p>
        1) Stop Service ' If you wish to terminate your services from SAWS, SAWS requires at least 5
        business days prior notice.&nbsp; Any deposit on your account will be credited toward your
        final bill balance.&nbsp; Termination of service cannot be processed more than 30 days in
        advance.&nbsp; Termination of services cannot be scheduled for weekends or SAWS
        holidays.&nbsp; &nbsp;
      </p>

      <p>
        2) Transfer Service- To terminate service at one address and start it at a new address
        within the SAWS service area, we require at least 5 business days prior notice.&nbsp; Based
        on service termination date, and the start service date, a new account may be assigned to
        the new service address.&nbsp; Any deposit on the old account will be transferred to the new
        service account.&nbsp; For any new service account (including on service accounts created
        due to a transfer service) for which you wish to utilize the Services, you must add the new
        service account to the Service and make new payment arrangements.
      </p>

      <p>
        SAWS will contact you by telephone or email if additional information is required for your
        request.&nbsp; Otherwise, you may expect an email notification on the day of service
        requested.&nbsp; Transfer requests can be processed no more than 30 days in advance.&nbsp;
        Transfers cannot be scheduled for weekends or SAWS holidays.
      </p>

      <h3>Failed Or Returned Transactions</h3>

      <p>
        In using the online bill payment feature of the Service, you are requesting SAWS (either
        directly or through a Third Party Payment Processor) to make payments for you from your
        Payment Account. If we are unable to complete the transaction for any reason associated with
        your Payment Account (for example, there are insufficient funds in your Payment Account to
        cover the transaction or your credit limit would be exceeded), the transaction will not be
        completed. In some instances, you will receive a return notice from us. In such case, you
        agree that:
      </p>

      <ol>
        <li style={{ padding: '5px 0' }}>
          You will reimburse SAWS immediately upon demand the transaction amount that has been
          returned to SAWS;
        </li>
        <li style={{ padding: '5px 0' }}>
          You will be responsible for any charges due under Chapter 34 of the Code of the City of
          San Antonio (the 'Terms of Service');
        </li>
        <li style={{ padding: '5px 0' }}>
          You will reimburse SAWS for any fees imposed by your financial institution as a result of
          the return;
        </li>
        <li style={{ padding: '5px 0' }}>
          You will reimburse SAWS for any fees it incurs in attempting to collect the amount of the
          returned transaction from you; and,
        </li>
        <li style={{ padding: '5px 0' }}>
          SAWS is authorized to report the facts concerning the returned transaction to a credit
          reporting agency.
        </li>
      </ol>

      <h3>Password Policy</h3>

      <p>
        Use of the Service requires both log-in information and a password. During the registration
        process for the Service, you must choose a unique sign-on user name ("User Name") and
        password ("Password"). Anyone with knowledge of both a User's User I.D. and Password can
        gain access to the Service and the information that is available regarding your account.
        Accordingly, you must keep your Password confidential. By agreeing to the terms and
        conditions of this Agreement, you agree to be solely responsible for the confidentiality and
        use of your Password, as well as for any communications entered on or funds transfers
        authorized through this Service using your Password.
      </p>

      <p>
        As a condition of use, you must tell us at once if you believe your Password has been lost
        or stolen or that someone has transferred or may transfer money from your account without
        your permission. You may contact us 24 hours a day:
      </p>

      <p>• Email: service@saws.org</p>

      <p>• Phone: (210) 704-SAWS (704-7297)</p>

      <p>SAWS reserves the right to reset any Passwords.</p>

      <h3>Address Or Banking Changes</h3>

      <p>
        It is your sole responsibility to ensure that the contact information in your user profile
        is current and accurate. This includes, but is not limited to, name, address, phone number
        and email address. Changes can be made either within the application or by contacting
        Customer Service. Any changes in your Payment Account information should also be made in
        accordance with the procedures outlined within the Service. All changes made are effective
        immediately for scheduled and future payments paid from the updated Payment Account
        information. SAWS is not responsible for any payment processing errors or fees incurred if
        you do not provide accurate Payment Account or contact information.
      </p>

      <h3>Service Termination, Cancellation, Or Suspension</h3>

      <p>
        Any payment(s) already processed before the requested cancellation date will be processed by
        the Service. SAWS may terminate or suspend the Service to you at any time. Neither
        termination nor suspension shall affect your liability or obligations under this Agreement.
      </p>

      <p>
        Cancelling your online account will not cancel any pending payments that are 'Scheduled' or
        'In Process.'&nbsp; Pending payments will still be processed by the date requested.&nbsp; If
        you want to cancel pending payments at the time you cancel your online account enrollment,
        you must first cancel the pending payment(s) and then cancel your online enrollment.&nbsp;
        Payments that are 'In Process' status cannot be cancelled and will be processed by the date
        requested.&nbsp;
      </p>

      <h3>Service Fees And Additional Charges</h3>

      <p>
        Unless SAWS determines that additional fees you owe to third parties resulted from incorrect
        debits by SAWS, you are responsible for any and all third party fees, including but not
        limited to your banking fees, your credit card company fees, Third Party Payment Processor
        fees, telephone access fees and/or Internet service fees that may be assessed by your
        bank(s), credit card company( ies),Third Party Payment Processor(s), your telephone
        provider(s) and/or Internet service provider(s).
      </p>

      <h3>Confidentiality and Security on Internet</h3>

      <p>
        Use of this Service is solely at your own risk and is subject to all applicable local,
        state, national and international laws and regulations. Although SAWS has endeavored to
        create a secure and reliable Service, the confidentiality of any communication transmitted
        over the Internet cannot be guaranteed. Accordingly, SAWS and its affiliates are not
        responsible for the security of any information transmitted via the Internet. Actual or
        attempted unauthorized use of this Service may result in criminal and/or civil prosecution.
        For your protection, SAWS reserves the right to view, monitor and record activity on the
        Service without notice or permission from you.
      </p>

      <p>
        In addition to our statement with respect to Your Account Privacy Options located at
        <a href="https://www.saws.org/privacy/ ">https://www.saws.org/privacy/</a>
        (the 'Privacy Statement'), SAWS will store and protect this information in the same manner
        we store and protect other personally identifying customer information. We may disclose
        information to third parties about your account or the transfers you make as discussed in
        our Privacy Statement, including:
      </p>

      <p>
        (i) Where it is necessary for completing transactions (for example, to a payment processing
        service), or (ii) In order to verify the existence and condition of your account for a third
        party, such as a credit bureau or (iii) To respond to information requests of governmental
        authorities or where required by law or regulation (including the Texas Public Information
        Act), or (iv) If you give us your written permission.
      </p>

      <p>
        We also reserve the right to provide non-personally identifying information such as
        aggregate data to third parties.
      </p>

      <p>
        SAWS is not responsible for any breach of its security or for the actions of any third
        parties that may obtain any personal information. Users of this service may review and
        correct personal information about them maintained by SAWS within the Service. SAWS will not
        be responsible for any loss resulting from a cause over which it does not have direct
        control, including, but not limited to, failure of electronic or mechanical equipment or
        communication lines, telephone or other interconnect problems, computer viruses,
        unauthorized access, theft, operator errors, severe weather, earthquakes, or natural
        disasters, strikes or other labor problems, wars or governmental restrictions.
      </p>

      <h3>Alterations And Amendments</h3>

      <p>
        This Agreement, applicable fees and service charges may be altered or amended by SAWS from
        time to time. Any use of the Service after SAWS provides you a notice of changes will
        constitute your agreement to such change(s). Further, SAWS may from time to time revise or
        update the applications, services, and/or related material, which may render all such prior
        versions obsolete. Consequently, SAWS reserves the right to terminate this Agreement as to
        all such prior versions of the applications, services, and/or related material and limit
        access to only the more recent revisions and updates.
      </p>

      <h3>Exclusions Of Warranties</h3>

      <p>
        THE SERVICE AND RELATED DOCUMENTATION ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND,
        EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. YOU ARE SOLELY RESPONSIBLE FOR TIMELY
        PAYMENT OF ALL WATER AND SEWER SERVICE BILLS.
      </p>

      <h3>Other Terms and Conditions</h3>

      <p>
        The terms and conditions set forth herein are in addition to and are not by way of
        limitation to any Terms of Service or tariffs or other regulations governing the terms and
        conditions pursuant to which SAWS provides you water and related services or the terms and
        conditions pursuant to which SAWS provides you use of the SAWS website.
      </p>

      <h3>Applicable Law &amp; Venue</h3>

      <p>
        This Agreement shall be governed by and construed in accordance with the laws of Texas,
        without regard to the choice of law or conflict of law rules thereof.&nbsp; Venue for any
        action or proceedings arising under or pertaining to this Agreement shall be exclusively in
        Bexar County, Texas.
      </p>

      <h3>Entire Agreement</h3>

      <p>
        In the event of a dispute regarding the service, you and SAWS agree to resolve the dispute
        by looking to this Agreement. You agree that this Agreement is the complete and exclusive
        statement of the agreement between you and SAWS which supersedes any proposal or prior
        agreement, oral or written, and any other communications between you and SAWS relating to
        the subject matter of this Agreement. If there is a conflict between what a representative
        of SAWS or Customer Service Department says and the terms of this Agreement, the terms of
        this Agreement will prevail.
      </p>

      <h3>Assignment</h3>

      <p>
        You may not assign this Agreement to any other party. SAWS may assign this Agreement or
        assign or delegate certain of its rights and responsibilities under this Agreement to
        independent contractors or other third parties.
      </p>

      <h3>No Waiver</h3>

      <p>
        SAWS shall not be deemed to have waived any of its rights or remedies hereunder unless such
        waiver is in writing and signed by SAWS. No delay or omission on the part of SAWS in
        exercising any rights or remedies shall operate as a waiver of such rights or remedies or
        any other rights or remedies. A waiver on any one occasion shall not be construed as a bar
        or waiver of any rights or remedies on future occasions.
      </p>

      <h3>Captions</h3>

      <p>
        The captions of sections hereof are for convenience only and shall not control or affect the
        meaning or construction of any of the provisions of this Agreement.
      </p>

      <h3>Partial Invalidity</h3>

      <p>
        If any provision of this Agreement is found invalid or unenforceable, that provision will be
        enforced to the maximum extent permissible, and the other provisions of this Agreement will
        remain in force.
      </p>

      <h3>Registration; Participation; Limitation</h3>

      <p>
        When registering with SAWS for Online Account Management Service, you represent and warrant
        that: ( i) you are 18 years old or older; (ii) you are using your actual identity and that
        any information you provide to SAWS is accurate and complete; and (iii) your registration
        with SAWS will not violate any local, state, national or international laws or regulations.
      </p>
      <p className="dateline">3/04/04</p>
      <div className="default12bold">
        Back to <Link to="/login">Secure Login Page</Link>
      </div>
    </div>
  )
}

export { TC }
