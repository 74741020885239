import React from 'react'
import styled from 'styled-components'
import './styles.scss'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import selectors from '@/redux/selectors'
import { Link } from 'react-router-dom'
import { HeaderMenuComponent } from './HeaderMenuComponent'
import { UserIconMenu } from './UserIconMenu'

const Container = styled.div`
  display: grid;
  height: 100%;
  padding: 7px 14px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-content: flex-end;
  background: #000;
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Menu = styled.div`
  display: inline;
  align-self: center;
  margin-right: 12px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.25em;
  color: #fff;
  cursor: pointer;
`

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const username = useSelector((state) => selectors.auth.getUsername(state))
  return (
    <Container>
      <FlexWrapper>
        <Menu onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <UserIconMenu username={username} isMenuOpen={isMenuOpen} />
          {isMenuOpen ? <HeaderMenuComponent /> : null}
        </Menu>
        <div>
          <Link to="/contact">
            <Button variant="outline" className="header--help-btn">
              NEED HELP?
            </Button>
          </Link>
        </div>
      </FlexWrapper>
    </Container>
  )
}

export default Header
