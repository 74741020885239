import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  byId: {
    111: { accountKey: '111', icon: 'store' },
    222: { accountKey: '222', icon: 'warehouse' },
    333: { accountKey: '333', icon: 'hospital' },
    444: { accountKey: '444', icon: 'irrigation' },
  },
}

const iconSlice = createSlice({
  name: 'icon',
  initialState,
  reducers: {
    saveIcon: () => {},
  },
})

export default iconSlice
