import React from 'react'
import PropTypes from 'prop-types'
import { AllAccounts, History, Payment, Login, Wallet, PaymentSummary } from '@/components/pages'
import { Switch, Route, Redirect } from 'react-router-dom'
import Map from './components/pages/Map/index'
import MessageCenter from './components/pages/MessageCenter/index'
import Usage from './components/pages/Usage/index'
import AccountManagement from './components/pages/AccountManagement'
import MoveOut from './components/pages/MoveOut/index'
import ContactUs from './components/pages/ContactUs/index'
import PasswordRecovery from './components/pages/PasswordRecovery/index'
import AddWaterAccount from './components/pages/AddWaterAccount/index'
import CreateAccount from './components/pages/CreateAccount/index'
import { TC } from './components/pages/TC'
import { NotFound } from './components/pages/NotFound/index'
import { ImpersonateUser } from './components/pages/impersonateUser'
import UserProfileForm from './components/pages/Profile'
import MyUsagePage from './components/pages/History/MyUsage'

const ProtectedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)}
  />
)

const PageRouter = ({ isAuth }) => {
  return (
    <Switch>
      <Route exact path={['/', '/login']}>
        {isAuth ? <Redirect to="/accounts" /> : <Login />}
      </Route>
      <Route exact path="/account/forgot">
        <PasswordRecovery />
      </Route>
      <Route exact path="/account/signup">
        <CreateAccount />
      </Route>
      <Route exact path="/impersonateuser/:accountKey/:usernamex52Idtoken">
        {' '}
        <ImpersonateUser />{' '}
      </Route>

      <ProtectedRoute
        isLoggedIn={isAuth}
        exact
        path="/accounts/create"
        component={AddWaterAccount}
      />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/map" component={Map} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/accounts" component={AllAccounts} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/history/:key" component={History} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/payment" component={Payment} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/usage" component={Usage} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/messagecenter" component={MessageCenter} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/wallet" component={Wallet} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/summary" component={PaymentSummary} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/manage" component={AccountManagement} />
      <ProtectedRoute isLoggedIn={isAuth} path="/moveout" component={MoveOut} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/contact" component={ContactUs} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/myprofile" component={UserProfileForm} />
      <ProtectedRoute isLoggedIn={isAuth} exact path="/myUsagePage" component={MyUsagePage} />

      {/* <ProtectedRoute isLoggedIn={isAuth} exact path="*" component={NotFound} /> */}
      <Route exact path="*" component={NotFound} />
      <Route exact path="/tc" component={TC} />

      {/* <Redirect from="*" to="/" /> */}
    </Switch>
  )
}

PageRouter.propTypes = {
  isAuth: PropTypes.bool.isRequired,
}

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export { PageRouter }
