import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 50%;
  background: #fff;
  border: 1px solid #3250b3;
  box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.05);
  border-radius: 4px;
  margin: 40px auto;
  padding: 20px;
`
const LoadingText = styled.h1`
  color: ${({ theme }) => theme.primary};
  font-size: 20px;
  float: left;
  clear: both;
  font-family: Roboto;
  text-transform: captialize;
  font-weight: 800;
  animation: color-change 6s infinite;
`

const Text = ({ quote }) => {
  return (
    <div>
      <h1 id="text" className="quote-text button-anim">
        {quote}
      </h1>
    </div>
  )
}

const quotes = [
  { content: 'Connecting Pipes...' },
  { content: 'Getting Things Flowing...' },
  { content: 'Checking Meters...' },
  { content: 'Measuring The Flow...' },
  { content: 'Pouring In Some Data...' },
]

const getQuote = () => {
  const number = Math.floor(Math.random() * 5)
  return quotes[number].content
}

const Quoter = () => {
  const [quote, setQuote] = React.useState()

  React.useEffect(() => {
    setQuote(getQuote())
  }, [])
  return (
    <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
      <div id="quote-box" className="center-div">
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
          <Text quote={quote} />
        </Animated>
      </div>
    </Animated>
  )
}

const LoadingPageComponent = () => {
  return (
    <Wrapper>
      <LoadingText>
        <Quoter />
      </LoadingText>
    </Wrapper>
  )
}

export { LoadingPageComponent }
