import React from 'react'
import styled from 'styled-components'

const StyledFormError = styled.p`
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 0;
  color: #ff5f5f;

  & p {
    margin: 0;
  }
`

const FormError = ({ children, style }) => (
  <StyledFormError style={style}>{children}</StyledFormError>
)

export { FormError }
