import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { useSelector } from 'react-redux'
import selectors from '@/redux/selectors'
import { ViewSettingsComponent } from '@/components/common/ViewSettingsComponent'
import { Typography } from '@progress/kendo-react-common'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { useHistory } from 'react-router-dom'
import { loadFromLocalStorage } from '@//token'
import { CheckoutModal } from './CheckoutModalComponent'
import { GridView } from './GridView'
import { ListGrid } from './ListGrid'

// ----------------- Styled Components ------------------- //

const Checkout = styled.div`
  grid-areas: checkout;
`

// ------------------------------------------------------------- //

const AllAccounts = () => {
  const history = useHistory()
  const viewType = useSelector((state) => state.settings.accountViewType)
  const [impersonate, setImpersonate] = React.useState(false)
  const username = useSelector((state) => selectors.auth.getUsername(state))

  const accountsQuery = useAccounts()
  const accounts = accountsQuery.data
  if (accountsQuery.isSuccess && accounts.length === 0) {
    history.push('/accounts/create')
  }

  React.useEffect(() => {
    const { isImpersonating } = loadFromLocalStorage('token') || {}
    setImpersonate(isImpersonating)
    document.title = isImpersonating
      ? `My Commerical : You are Impersonating ${username}`
      : 'My Account for Business - BETA'
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ViewSettingsComponent pageName="All Accounts" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography.p>
            Click every account you’d like to manage with the same action. For example, if you’d
            like to pay multiple accounts with one single payment for all, select them all here
            first.
          </Typography.p>
          {impersonate ? (
            <Typography.p style={{ textAlign: 'center', color: 'red', fontSize: '25px' }}>
              {' '}
              You are Impersonating as : {username}
            </Typography.p>
          ) : null}
        </div>
        <div className="col-12" style={{ display: 'flex' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              marginRight: '5px',
              backgroundColor: 'rgba(103, 183, 220, 0.2)',
            }}
          />

          <Typography.p>
            - Account tiles highlighted in blue indicate a ConnectH2O account.
          </Typography.p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">{viewType === 'GRID' ? <GridView /> : <ListGrid />}</div>
      </div>
      <div>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="1000" isVisible>
          <Checkout>
            <CheckoutModal />
          </Checkout>
        </Animated>
      </div>
    </>
  )
}

export { AllAccounts }
