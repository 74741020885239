import { ICONS } from './icons'

const LINKS = {
  home: '/',
  moveout: '/moveout',
  accounts: '/accounts',
  signup: '/account/signup',
  wallet: '/wallet',
}
//* Pages added to this sitemap will render as menu items on the side and top navigation menus
const SITEMAP = {
  auth: [
    {
      page: 'Home',
      icon: ICONS.money,
      href: '/accounts',
      alt: 'view accounts',
    },
    { page: 'Water Use', icon: ICONS.chart, href: '/usage', alt: 'Water Use' },
    {
      page: 'Move Out',
      icon: ICONS.truck,
      href: '/moveout',
      alt: 'move out',
    },
    {
      page: 'Map',
      icon: ICONS.map,
      href: '/map',
      alt: 'map',
    },
    {
      page: 'Contact Us',
      icon: ICONS.envelope,
      href: '/contact',
      alt: 'contact us',
    },
    {
      page: 'My Account for Business',
      icon: null,
      href: '/commercial',
      alt: 'My Account for Business',
    },
  ],
  unauth: [
    {
      page: 'Log In',
      icon: ICONS.signIn,
      href: '/login',
      alt: 'login to my commerical account',
    },
    {
      page: 'New Account',
      icon: ICONS.truck,
      href: '/account/signup',
      alt: 'create a new my commerical account',
    },
    {
      page: 'Forgot Password',
      icon: ICONS.lock,
      href: '/account/forgot',
      alt: 'forgot my commerical account password',
    },
  ],
}

export { SITEMAP, LINKS }
