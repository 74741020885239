import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'react-bootstrap/Button'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { PropTypes } from 'prop-types'
import { useBillPdfHook } from '@/hooks/useBillPdfHook'
import { Tooltip } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

// STYLE THEMES

const primaryTheme = css`
  background: ${({ theme }) => theme.info};
  border-color: ${({ theme }) => theme.info};

  &:hover svg {
    fill: ${({ theme }) => theme.infoBright};
  }
`
const renderTooltip = (props) => <Tooltip {...props}>View bill</Tooltip>

const InfoButton = styled(Button)`
  margin-bottom: 5px;
  padding: 0.2rem 0.5rem;
  ${() => primaryTheme}
`

const GridCardPaymentButton = ({ accountStatus, accountKey, billKey, showScheduled, dueDate }) => {
  const [shouldFetch, setShouldFetch] = React.useState(null)
  const { pdfUrl } = useBillPdfHook(accountKey, billKey, shouldFetch)
  const openPdf = () => window.open(pdfUrl, '_blank')
  const handleClick = (e) => {
    e.stopPropagation()
    if (pdfUrl) return openPdf()
    setShouldFetch(true)
  }

  React.useEffect(() => {
    if (pdfUrl) {
      openPdf()
    }
  }, [pdfUrl])
  return (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <InfoButton accountStatus={accountStatus} showScheduled={showScheduled} onClick={handleClick}>
        <FaFileInvoiceDollar className="info-icon" size="1.2em" />
      </InfoButton>
    </OverlayTrigger>
  )
}

GridCardPaymentButton.propTypes = {
  accountStatus: PropTypes.string.isRequired,
  accountKey: PropTypes.number.isRequired,
  showScheduled: PropTypes.bool,
  billKey: PropTypes.number.isRequired,
}

GridCardPaymentButton.defaultProps = {
  showScheduled: null,
}

export { GridCardPaymentButton }
