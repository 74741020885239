import { asArray } from '@/utils/utils'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import { takeLatest, select, put, call, all } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { cleanUpSchedulePayments, selectAccount } from '@/redux/ducks/commonDuck'
import { OBJECT_VARS } from '@/utils/models'

const initialState = {
  synchronizationId: null,
  isDataInitialized: false,
  selectedPaymentMethodId: null,
  paymentMethod: null,
  paymentDate: null,
  paymentFormData: null,
  byKey: {}, // TODO: remove byKey code and replace with paymentFormData
  amountToBePaid: null,
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    saveInitData: (state, action) => {
      state.byKey = action.payload
      state.paymentFormData = action.payload
    },
    savePayment: (state, action) => {
      const { accountKey } = action.payload
      const originalPayment = state.byKey[accountKey]
      state.byKey[accountKey] = {
        ...originalPayment,
        ...action.payload,
      }
    },
    saveSelectedPaymentMethodId: (state, action) => {
      state.selectedPaymentMethodId = action.payload
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    },
    willSchedulePayment: (state, action) => {
      state.willSchedule = action.payload
    },
    setPaymentDate: (state, action) => {
      state.paymentDate = action.payload
    },
    saveAmountToBePaid: (state, action) => {
      const { key, amount } = action.payload
      state.paymentFormData = state.paymentFormData.map((item) => {
        if (item.accountKey === key) {
          return { ...item, amount }
        }
        return item
      })
    },
    saveTotalAmountToBePaid: (state, action) => {
      state.amountToBePaid = action.payload
    },
    trySubmitPayment: (state) => state,
    schedulePayment: (state) => state,
    setSynchronizationId: (state, action) => {
      state.synchronizationId = action.payload
    },
    removePayment: (state) => state,
  },
  extraReducers: {
    [cleanUpSchedulePayments]: (state) => {
      state.byKey = {}
      state.paymentDate = null
      state.paymentMethod = null
      state.isDataInitialized = false
      state.synchronizationId = null
      state.amountToBePaid = null
      state.paymentFormData = null
    },
  },
})

// ------------------ Selectors ----------------- //

const getState = (state) => state

export const getSelectedPaymentMethodId = (state) => state.payment.selectedPaymentMethodId

const getPaymentMethod = createSelector(getState, (state) => state.payment.paymentMethod)

const getIsDataInitialzed = (state) => state.payment.isDataInitialized

const getWillSchedule = createSelector(
  (state) => state.payment.willSchedule,
  (willSchedule) => willSchedule
)

export const getPaymentDate = createSelector(
  (state) => state.payment.paymentDate,
  (paymentDate) => {
    const date = new Date()
    const today = date.toString()
    return paymentDate || today
  }
)

export const getScheduledPaymentsAsArray = (state) => {
  const selectedPaymentMethod = getPaymentMethod(state)
  if (!selectedPaymentMethod) return []
  if (!state.payment.paymentFormData) return []
  return state.payment.paymentFormData.map((payment) => {
    const { accountNumber } = payment
    const paymentDate = getPaymentDate(state)
    return {
      ...payment,
      ...selectedPaymentMethod,
      accountNumber,
      bankAccountNumber: selectedPaymentMethod.accountNumber,
      paymentMethodId: selectedPaymentMethod.id,
      [OBJECT_VARS.scheduledDate]: paymentDate,
      nickName: payment.nickName || payment.serviceAddressLine,
    }
  })
}

const getScheduledPaymentAmountByKey = (state, key) => state.payment.byKey[key]?.amount

export const getTotalAmountToBePaid = (state) => state.payment.amountToBePaid

export const getSynchronizationId = createSelector(
  (state) => state.payment.synchronizationId,
  (synchronizationId) => synchronizationId
)

export const getPaymentFormData = (state) => state.payment.paymentFormData

// ------------------ Saga Helpers ----------------- //
function* calculateTotalAmountToBePaid() {
  const total = yield select((state) =>
    asArray(state.payment.paymentFormData).reduce((sum, el) => sum + parseFloat(el.amount), 0)
  )

  yield put({
    type: paymentSlice.actions.saveTotalAmountToBePaid.type,
    payload: total,
  })
}

export function* initializeSchedulePayment() {
  yield put({ type: paymentSlice.actions.setSynchronizationId.type, payload: uuid() })
  yield call(calculateTotalAmountToBePaid)
}

function* workOnRemovePayment(action) {
  const { key } = action.payload
  yield put({ type: selectAccount.type, payload: key })
  const payments = yield select(getScheduledPaymentsAsArray)
  const newPayments = payments.filter((payment) => payment.accountKey !== key)
  yield put({ type: paymentSlice.actions.saveInitData.type, payload: newPayments })
  yield call(calculateTotalAmountToBePaid)
}

export function* watchInitializeSchedulePayment() {
  yield takeLatest(paymentSlice.actions.saveInitData.type, initializeSchedulePayment)
}

function* watchRemovePayment() {
  yield takeLatest(paymentSlice.actions.removePayment.type, workOnRemovePayment)
}

function* watchSaveAmountToBePaid() {
  yield takeLatest(paymentSlice.actions.savePayment.type, calculateTotalAmountToBePaid)
  yield takeLatest(paymentSlice.actions.saveAmountToBePaid.type, calculateTotalAmountToBePaid)
}

export function* watchPayment() {
  yield all([
    call(watchInitializeSchedulePayment),
    call(watchRemovePayment),
    call(watchSaveAmountToBePaid),
  ])
}

export const paymentSelectors = {
  getWillSchedule,
  getPaymentDate,
  getPaymentMethod,
  getIsDataInitialzed,
  getScheduledPaymentAmountByKey,
  getTotalAmountToBePaid,
}
export default paymentSlice
