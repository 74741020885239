import React from 'react'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { useDispatch } from 'react-redux'
import { deselectAccounts } from '@/redux/ducks/commonDuck'
import { MoveOutGridCard } from './MoveOutGridCard.component'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const MoveOutGrid = () => {
  const { data } = useAccounts()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(deselectAccounts())
  }, [])

  return (
    <Container>
      {data &&
        data.map((account) => {
          const accountKey = account[OBJECT_VARS.accountKey]
          const accountNumber = account[OBJECT_VARS.accountNumber]
          const icon = account[OBJECT_VARS.icon]
          const address = `${account[OBJECT_VARS.serviceAddress]} ${
            account[OBJECT_VARS.subDesignation]
          }`
          const nickname = account[OBJECT_VARS.nickName]
          return (
            <MoveOutGridCard
              key={accountKey}
              accountKey={accountKey}
              accountNumber={accountNumber}
              iconName={icon}
              address={address}
              nickname={nickname}
            />
          )
        })}
    </Container>
  )
}

export { MoveOutGrid }
