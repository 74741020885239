import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { selectAccount } from '@/redux/ducks/commonDuck'
import { LineItem } from '@/components/common/Typography'
import arrowImage from '@/images/Polygon.svg'

const GradientContainer = styled.div`
  padding: 25px 20px;
  background: #0078ae;
  clear: both;
  margin-bottom: 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled && '20%'};
  transition: opacity 1s;
  position: relative;
`

const ImageWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: ${({ selected }) => selected && '0px 0px 7px 5px rgba(75,192,242,1)'};
  transition: box-shadow 0.5s;
`

const TextWrapper = styled.div`
  font-family: Nunito;
  font-size: 20px;
  color: #ffffff;

  & p {
    margin-bottom: 0;
    font-weight: 300;
  }
`

const DeleteButton = styled.div`
  position: absolute;
  color: red;
  text-align: right;
  top: 7px;
  right: 12px;
`
const StyledImg = styled.img`
height: 45px;
width: 32px;
margin: 36px 0px 2px 14px;
`


const renderTooltip = (props) => (
  <Tooltip {...props}>You can enter a different final billing address.</Tooltip>
)

const MoveOutSelectedAddressCard = ({
  accountKey,
  address,
  checkState,
  checkActions,
  onSelect,
  isSelected,
}) => {
  const { setDefault, setCustom } = checkActions
  const dispatch = useDispatch()
  const removeCard = () => dispatch(selectAccount(accountKey))
  const isDisabled = checkState === 'DISABLED'
  const isCustom = checkState === 'CUSTOM'
  const isDefault = checkState === 'DEFAULT'
  return (
    <div style={{display:'flex'}}>
    <ImageWrapper selected={isDisabled ? false : isSelected} onClick={() => onSelect(accountKey)}>
    
      <GradientContainer disabled={false}>
        <TextWrapper>
          <p>
            <input
              type="checkbox"
              label="Use a different address"
              checked={isCustom}
              onClick={() => setCustom(accountKey)}
              disabled={isDisabled || !isSelected}
            />
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <LineItem disabled={isDisabled} size="17px">
                New final billing address
              </LineItem>
            </OverlayTrigger>
          </p>
          <p>
            <input
              type="checkbox"
              label={address}
              checked={isDefault || isDisabled}
              onClick={() => setDefault(accountKey)}
              disabled={isDisabled || !isSelected}
            />
            <LineItem disabled={isDisabled} size="17px">
              {address}
            </LineItem>
          </p>
        </TextWrapper>
      </GradientContainer>
    </ImageWrapper>
    <div style={{display:isSelected ? 'block' : 'none'}}>
    <StyledImg alt="arrow Logo" src={arrowImage} />
    </div>
      </div>
  )
}

export { MoveOutSelectedAddressCard }
