import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MycButton } from '@/components/common/Button'

const PayMyAccountsButton = styled(MycButton)`
  margin-top: 21px;
  width: 258px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
`

const PayMyAccountsButtonComponent = () => {
  return (
    <Link to="/accounts" alt="Pay my accounts">
      <PayMyAccountsButton variant="primary">Pay My Accounts</PayMyAccountsButton>
    </Link>
  )
}

export { PayMyAccountsButtonComponent }
