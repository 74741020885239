import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card } from '@progress/kendo-react-layout'
import styled from 'styled-components'
import { FormError } from '@/components/common/FormError'
import { Typography } from '@progress/kendo-react-common'
import { Switch } from '@progress/kendo-react-inputs'
import { getUserId } from '@/redux/ducks/commonDuck'
import { useSelector } from 'react-redux'
import { useEBill, useSubmitEBill } from './useEBill.queries'

const PaperlessNo = styled.span`
  color: ${({ theme, isEbill }) => (isEbill ? theme.text : theme.primary)};
  padding: 0 30px;
`

const PaperlessYes = styled.span`
  color: ${({ theme, isEbill }) => (isEbill ? theme.primary : theme.text)};
  padding: 0 30px;
`

const EBillComponent = ({ accountKey }) => {
  const x52id = useSelector(getUserId)
  const ebillQuery = useEBill(accountKey)
  const { mutate, isLoading, isError } = useSubmitEBill()
  const [Ebill, SetEBill] = React.useState(false)

  useEffect(() => {
    if (ebillQuery.isSuccess) {
      const res = ebillQuery.data
      if (res === null) {
        SetEBill(false)
      } else if (res.isebills === 'N') {
        SetEBill(false)
      } else {
        SetEBill(true)
      }
    }
  }, [ebillQuery.data])

  const handleEbill = () => {
    const body = { accountKey, modifiedBy: x52id, isebills: !Ebill, appId: 2 }
    mutate(body, {
      onSuccess: (data, variables, context) => {
        SetEBill(!Ebill)
      },
    })
  }

  return (
    <Card className="bg-light text-center p-4 mt-4">
      <Typography.p className="text-orange-col">I WANT PAPERLESS BILLING:</Typography.p>
      {ebillQuery.isLoading || isLoading ? (
        <Skeleton width={225} />
      ) : (
        <>
          <div>
            <PaperlessNo isEbill={Ebill}>No</PaperlessNo>
            <Switch checked={Ebill} onChange={handleEbill} />
            <PaperlessYes isEbill={Ebill}>Yes</PaperlessYes>
          </div>
          <FormError>{(ebillQuery.isError || isError) && 'Error setting ebill'}</FormError>
        </>
      )}
    </Card>
  )
}

export default EBillComponent
