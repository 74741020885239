import React from 'react'
import styled from 'styled-components'
import './styles.scss'
import { useSelector } from 'react-redux'
import { Typography } from '@progress/kendo-react-common'
import { ViewSettingsComponent } from '@/components/common/ViewSettingsComponent'
import { GridView } from './GridView'
import { TableView } from './TableView'

const PageContainer = styled.div`
  min-height: inherit;
  padding: 20px;
  display: grid;
  grid-template-columns: 0fr;
  grid-template-rows: 65px max-content 0fr;
  min-height: inherit;
  padding: 0 20px;
  margin: 0 auto;
  grid-template-areas:
    'header header'
    'accounts accounts';
`

const Header = styled.div`
  grid-area: header;
`

const AccountsContainer = styled.div`
  grid-area: accounts;
`

const Usage = () => {
  const viewType = useSelector((state) => state.settings.accountViewType)

  return (
    <>
      <PageContainer>
        <Header>
          <ViewSettingsComponent style={{ fontSize: '2vh' }} pageName="Water Use" />
          <div className="col-12" style={{ display: 'flex', marginTop: '-10px' }}>
            <div
              style={{
                width: '20px',
                height: '20px',
                marginRight: '5px',
                backgroundColor: 'rgba(103, 183, 220, 0.2)',
              }}
            />
            <Typography.p>
              - Account tiles highlighted in blue indicate a ConnectH2O account.
            </Typography.p>
          </div>
        </Header>
        <AccountsContainer>{viewType === 'GRID' ? <GridView /> : <TableView />}</AccountsContainer>
      </PageContainer>
    </>
  )
}

export default Usage
