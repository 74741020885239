import React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: #279a2c;
  height: 100%;
  width: inherit;
  border-radius: inherit;
  opacity: 0.9;
  cursor: pointer;
`

const OverlayIcon = styled(FaCheck)`
  color: #279a2c;
  border-radius: 100%;
  background: #fff;
  height: 4.5em;
  width: 4.5em;
  border: 13px solid #fff;
`

const OverlayText = styled.h2`
  font-weight: 200;
  font-size: 2em;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
`

const GridCardOverlayComponent = () => {
  return (
    <OverlayContainer>
      <OverlayIcon size="2em" />
      <OverlayText>Selected</OverlayText>
    </OverlayContainer>
  )
}

export { GridCardOverlayComponent }
