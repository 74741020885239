export function saveToLocalStorage(label, state) {
  try {
    const serialisedState = JSON.stringify(state)
    localStorage.setItem(label, serialisedState)
  } catch (e) {
    console.warn(e)
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
export function loadFromLocalStorage(label) {
  try {
    // Check for token before loading state
    const serialisedState = localStorage.getItem(label)
    if (serialisedState === 'undefined') return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}
