import { createSlice, createSelector } from '@reduxjs/toolkit'
import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import { API } from '@/redux/api'
import { getUserId, resetLoaders } from '@/redux/ducks/commonDuck'
import { OBJECT_VARS } from '@/utils/models'
import jwtDecode from 'jwt-decode'
import { saveToLocalStorage } from '../../token'

const initialState = {
  authentication: {
    user: null,
    isAuthenticated: false,
  },
}

// ----------------------------------- STATE ------------------------------------- //
const authSlice = createSlice({
  name: 'authentication',
  initialState: initialState.authentication,
  reducers: {
    fetchLogInRequest: (state) => {
      state.isAuthenticated = false
    },

    fetchImpersonateRequest: (state) => {
      state.isAuthenticated = false
    },

    fetchLogInSuccess: (state) => {
      state.isAuthenticated = true
    },
    fetchLogInFailure: (state) => {
      state.isAuthenticated = false
    },
    fetchLogInReset: (state) => state,
    saveUser: (state, action) => {
      state.user = action.payload
    },
    fetchCreateUserRequest: (state) => state,
    fetchCreateUserSuccess: (state) => state,
    fetchCreateUserFailure: (state) => state,
    fetchResetUserPasswordRequest: (state) => state,
    fetchResetUserPasswordSuccess: (state) => state,
    fetchResetUserPasswordFailure: (state) => state,
    fetchResetUserPasswordReset: (state) => state,
    fetchUpdateUserRequest: (state) => state,
    fetchUpdateUserSuccess: (state) => state,
    fetchUpdateUserFailure: (state) => state,
    create: (state) => state,
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
    },
  },
})

// --------------------------------- SELECTORS --------------------------------- //
const getState = (state) => state
const getUserx52Id = (state) => state.authentication.user.x52Id
const getUsername = createSelector(getState, (state) =>
  state.authentication.user ? state.authentication.user[OBJECT_VARS.username] : null
)
const getLoginFailure = createSelector(getState, (state) => state.authentication.loginFailed)
const getInitIsComplete = createSelector(
  [(state) => state.authentication.isAuthenticated, (state) => state.accounts.accountKeys],
  (isAuth, keys) => {
    if (!isAuth || keys === []) {
      return false
    }
    return true
  }
)
const getUser = createSelector(getState, (state) => state.authentication.user)

// --------------------------------- SAGAS --------------------------------- //
function transformCreateAccountFormData(data) {
  const today = new Date()
  return {
    [OBJECT_VARS.username]: data.username,
    password: data.password,
    [OBJECT_VARS.appId]: process.env.REACT_APP_APP_ID,
    [OBJECT_VARS.email]: data.email,
    [OBJECT_VARS.firstName]: data.firstname,
    [OBJECT_VARS.lastName]: data.lastname,
    [OBJECT_VARS.jobTitle]: data.jobTitle,
    [OBJECT_VARS.companyName]: data.companyName,
    [OBJECT_VARS.taxId]: data.taxid,
    secretQuestion: data.secretquestion,
    secretAnswer: data.secretanswer,
    nickname: '',
    [OBJECT_VARS.alteredBy]: data.username,
    [OBJECT_VARS.alteredDate]: today.toUTCString(),
    [OBJECT_VARS.mobile]: data.mobilenumber,
    [OBJECT_VARS.serviceAgreementFlag]: 1,
  }
}

function* workOnCreateNewUser(action) {
  const formData = yield call(transformCreateAccountFormData, action.payload)
  try {
    const response = yield call(API.createUser, formData)
    yield saveToLocalStorage('token', response)
    const user = yield call(API.getUserIdentity)
    yield put({ type: authSlice.actions.saveUser.type, payload: user })
    yield put({
      type: authSlice.actions.fetchCreateUserSuccess.type,
    })
  } catch (e) {
    yield put({
      type: authSlice.actions.fetchCreateUserFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
  }
}

export function* workOnLogInUser(action) {
  const { username, password } = action.payload
  try {
    const token = yield call(API.login, { userName: username, password })
    yield call(saveToLocalStorage, 'token', token)
    const user = yield call(API.getUserIdentity)
    yield put({
      type: authSlice.actions.saveUser.type,
      payload: user,
    })
    yield put({ type: authSlice.actions.fetchLogInSuccess.type })
  } catch (e) {
    yield put({
      type: authSlice.actions.fetchLogInFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
    return false
  }

  return null
}

export function* workOnImpersonateUser(action) {
  const data = action.payload
  const jwt = jwtDecode(data)
  try {
    const token = {
      accessToken: data,
      isImpersonating: true,
      x52Id: jwt.x52id,
      tokenExpiration: new Date(jwt.exp * 1000),
    }
    yield call(saveToLocalStorage, 'token', token)
    const user = yield call(API.getUserIdentity)
    yield put({
      type: authSlice.actions.saveUser.type,
      payload: user,
    })
    yield put({ type: authSlice.actions.fetchLogInSuccess.type })
  } catch (e) {
    yield put({
      type: authSlice.actions.fetchLogInFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
    return false
  }

  return null
}

function* workOnLogOutUser() {
  yield call(saveToLocalStorage, 'token', undefined)
  yield put({ type: resetLoaders.type })
}

function* workOnResetUserPassword(action) {
  try {
    yield call(API.resetUserPassword, action.payload)
    yield put({
      type: authSlice.actions.fetchResetUserPasswordSuccess.type,
    })
  } catch (e) {
    yield put({
      type: authSlice.actions.fetchResetUserPasswordFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
  }
}

function* workOnUpdateUser(action) {
  const {
    email,
    firstName,
    lastName,
    taxId,
    mobileNumber,
    newPassword,
    updatePassword,
    jobTitle,
    companyName,
  } = action.payload
  const x52Id = yield select(getUserId)
  const username = yield select(getUsername)
  const body = {
    email,
    firstName,
    lastName,
    jobTitle,
    companyName,
    taxId,
    alteredBy: username,
    alteredDate: new Date(),
    mobileNumber,
    serviceAgreementFlag: 1,
    x52Id,
    newPassword,
    updatePassword,
    nickname: '',
  }
  try {
    const updatedUser = yield call(API.updateUser, body)
    yield put({ type: authSlice.actions.saveUser.type, payload: updatedUser })
    yield put({
      type: authSlice.actions.fetchUpdateUserSuccess.type,
    })
  } catch (e) {
    yield put({
      type: authSlice.actions.fetchUpdateUserFailure.type,
      payload: { message: e.message, statusCode: e.statusCode },
    })
  }
}

function* watchResetUserPasswordRequest() {
  yield takeLatest(authSlice.actions.fetchResetUserPasswordRequest.type, workOnResetUserPassword)
}

export function* watchCreateNewUser() {
  yield takeLatest(authSlice.actions.fetchCreateUserRequest.type, workOnCreateNewUser)
}

function* watchUpdateUserRequest() {
  yield takeLatest(authSlice.actions.fetchUpdateUserRequest.type, workOnUpdateUser)
}

export function* watchLogInUser() {
  yield takeLatest(authSlice.actions.fetchLogInRequest.type, workOnLogInUser)
}

export function* watchImpersonateUser() {
  yield takeLatest(authSlice.actions.fetchImpersonateRequest.type, workOnImpersonateUser)
}

function* watchLogOutUser() {
  yield takeLatest(authSlice.actions.logout.type, workOnLogOutUser)
}

export function* watchAuth() {
  yield all([
    call(watchLogInUser),
    call(watchImpersonateUser),
    call(watchUpdateUserRequest),
    call(watchCreateNewUser),
    call(watchResetUserPasswordRequest),
    call(watchLogOutUser),
  ])
}

const authSelectors = {
  getUserx52Id,
  getUserId,
  getInitIsComplete,
  getLoginFailure,
  getUsername,
  getUser,
}

export { authSelectors }
export { authSlice }
