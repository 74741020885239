import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSettingsApi as useSettings } from '@/redux/reducers'
import Button from 'react-bootstrap/Button'
import { TABLE_VIEW } from '@/redux/ducks/commonDuck'

const ViewSettingsComponent = ({ pageName }) => {
  const dispatch = useDispatch()
  const selectedOption = useSelector((state) => state.settings.accountViewType)
  const isList = selectedOption === TABLE_VIEW
  const renderSelectedOption = () => (isList ? 'Table View' : 'Grid View')
  const renderAlternateOption = () => (isList ? 'Grid View' : 'Table View')
  const bindClickHandler = () =>
    !isList ? dispatch(useSettings.selectListView()) : dispatch(useSettings.selectGridView())

  return (
    <h2 style={{ fontSize: '3v', margin: '0.4rem 0' }}>
      {pageName} ({renderSelectedOption()}) -
      <Button variant="link" onClick={() => bindClickHandler()}>
        {renderAlternateOption()}
      </Button>
    </h2>
  )
}

export { ViewSettingsComponent }
