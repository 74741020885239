import { useQuery, useMutation } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_EBILL_FLAG = `${process.env.REACT_APP_API_BASE_URL}/billing-public-api/public/api/getebillflag?accountKey=`
const UPDATE_EBILL_FLAG = `${process.env.REACT_APP_API_BASE_URL}/billing-public-api/public/api/updateebillflag`

const getEBillFlag = (accountKey) => {
  const url = GET_EBILL_FLAG + accountKey
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const updateEBillFlag = (req) => {
  const url = UPDATE_EBILL_FLAG

  return fetchWrapper(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(req),
    requiresInfor: true,
  })
}

const useEBill = (key) => {
  return useQuery(['ebill', key], () => getEBillFlag(key))
}

const useSubmitEBill = () => {
  return useMutation(['ebillSubmit'], (req) => updateEBillFlag(req))
}

export { useEBill, useSubmitEBill }
