import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { rootSaga } from '@/redux/rootSaga'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './redux/reducers'
import { loadFromLocalStorage } from './token'

const devMode = process.env.NODE_ENV === 'development'
const sagaMiddleware = createSagaMiddleware()
const configuredMiddleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware]

if (devMode) {
  // configuredMiddleware.push(logger)
}

const getPreloadedState = () => {
  if (loadFromLocalStorage('appState') !== null) {
    return loadFromLocalStorage('appState')
  }
  return undefined
}

// create our store from our rootReducers and use loadFromLocalStorage
// to overwrite any values that we already have saved
const store = configureStore({
  reducer: rootReducer,
  devTools: devMode,
  middleware: configuredMiddleware,
  preloadedState: getPreloadedState(),
})

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => localStorage.setItem('appState', JSON.stringify(store.getState())))

sagaMiddleware.run(rootSaga)

export { store }
