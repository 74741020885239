import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Input, Label, MaskInput } from '@/components/common/Input'
import Form from 'react-bootstrap/Form'
import { FormError } from '@/components/common/FormError'
import { getFlexibleUnit } from '@/utils/flexUnit'

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const StyledSelect = styled(Form.Control)`
  padding: ${({ padding }) => padding};
  margin-bottom: ${({ bottomGutter }) => getFlexibleUnit(bottomGutter)};
  color: #8f8f8f;
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  font-size: smaller;
  width: 100%;
`

const Legend = styled.div`
  font-size: 12px;
  font-style: italic;
`

const BaseFormComponent = ({
  style,
  onSubmit,
  children,
  variant,
  data,
  handleSubmit,
  control,
  errors,
  forceReset,
  readOnly,
  bottomGutter,
  register,
}) => {
  React.useEffect(() => {
    // Updates default values when payment method is selected
    if (!forceReset) return
    forceReset()
  }, [forceReset])
  return (
    <>
      <FormWrapper>
        <Form style={style} onSubmit={handleSubmit(onSubmit)}>
          {data.map((field) => {
            if (field.isHidden) return null
            if (field.isMask) {
              return (
                <>
                  <Label label={field.label} fontSize="12px" variant={variant} />
                  <Controller
                    render={({ onChange, onBlur, name, ref }) => (
                      <MaskInput
                        mask={field.mask}
                        maskPlaceholder={field.maskPlaceholder}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        inputRef={ref}
                        rules={field.rules}
                        defaultValue={field.defaultValue}
                        isInvalid={errors[field.name] && true}
                        alwaysShowMask
                      >
                        {(inputProps) => (
                          <Input style={{ height: '2.9em' }} {...inputProps} type="text" />
                        )}
                      </MaskInput>
                    )}
                    mask={field.mask}
                    name={field.name}
                    control={control}
                    defaultValue={field.defaultValue}
                    rules={field.rules}
                    isInvalid={errors[field.name] && true}
                    type={field.type || 'text'}
                    placeholder="Type here..."
                    bottomGutter={bottomGutter}
                    padding="1.4em"
                    disabled={readOnly || field.readOnly}
                  />
                  <FormError>{errors[field.name] && errors[field.name].message}</FormError>
                  {field.legend ? <Legend>{field.legend}</Legend> : ''}
                </>
              )
            }
            if (field.input?.select) {
              return (
                <>
                  <Label label={field.label} fontSize="12px" variant={variant} />
                  <Controller
                    as={<StyledSelect as="select" />}
                    name={field.name}
                    control={control}
                    defaultValue={field.defaultValue}
                    rules={field.rules}
                    bottomGutter={bottomGutter}
                    padding="12px"
                    disabled={readOnly || field.readOnly}
                    ref={register}
                  >
                    <FormError>{errors[field.name] && errors[field.name].message}</FormError>
                    {field.input.select?.options.map((option) => {
                      return (
                        <option key={option.key} value={option.value}>
                          {option.displayLabel}
                        </option>
                      )
                    })}
                  </Controller>
                </>
              )
            }
            return (
              <>
                <Label label={field.label} fontSize="12px" variant={variant} />
                <Controller
                  as={Input}
                  name={field.name}
                  control={control}
                  defaultValue={field.defaultValue}
                  autocomplete={!field.autoComplete ? 'off' : 'on'}
                  rules={field.rules}
                  isInvalid={errors[field.name] && true}
                  type={field.type || 'text'}
                  placeholder="Type here..."
                  bottomGutter={bottomGutter}
                  padding="1.4em"
                  disabled={readOnly || field.readOnly}
                  ref={register}
                >
                  <FormError style={{ color: variant === 'dark' ? '#0078ae' : '#ffffff' }}>
                    {field.showAsyncMessage && field.asyncMessage}
                  </FormError>
                  <FormError>{errors[field.name] && errors[field.name].message}</FormError>
                </Controller>
              </>
            )
          })}
          {children}
        </Form>
      </FormWrapper>
    </>
  )
}

BaseFormComponent.defaultProps = {
  children: null,
  variant: 'dark',
  style: {},
  errors: {},
  isSubmitSuccessful: null,
  forceReset: null,
  readOnly: false,
  bottomGutter: 0,
}
BaseFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  variant: PropTypes.string,
  style: PropTypes.shape({}),
  data: PropTypes.arrayOf({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  isSubmitSuccessful: PropTypes.bool,
  forceReset: PropTypes.func,
  readOnly: PropTypes.bool,
  bottomGutter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export { BaseFormComponent }
