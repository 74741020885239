import styled from 'styled-components'
import { getFlexibleUnit } from '@/utils/flexUnit'

const Typography = styled.h1`
  margin: ${({ margin = 0 }) => getFlexibleUnit(margin)};
  margin-bottom: ${({ bottomGutter }) => getFlexibleUnit(bottomGutter)};
  padding: ${({ padding = 0 }) => getFlexibleUnit(padding)};
  font-size: ${({ size }) => (size ? getFlexibleUnit(size) : '3em')};
  font-weight: ${({ fontWeight }) => fontWeight || 500}};
`

const Title = styled(Typography)`
  color: #fff;
  text-align: center;
`

const Terms = styled(Typography)`
  font-size: 10px;
  font-weight: 100;
  line-height: 13px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
`

const LineItem = styled(Typography)`
  color: ${({ disabled }) => (disabled ? '#c7c7c7' : '#fff')};
  margin-left: 5px;
  display: inline;
`

export { Title, Terms, LineItem }
