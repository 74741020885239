import React from 'react'
import { asArray } from '@/utils/utils'
import { useQueryClient } from 'react-query'
import { OBJECT_VARS } from '@/utils/models'
import { useSelector } from 'react-redux'
import { getSummary } from '@/redux/ducks/serviceRequestDuck'

const buildSummaryData = (addresses, moveOutDate, accounts, client) =>
  asArray(addresses).map((address) => {
    const key = address[OBJECT_VARS.accountKey]
    const payment = client.getQueryData(['payments', [key]])
    const account = accounts.find((el) => el[OBJECT_VARS.accountKey] === key)
    return {
      ...addresses[key],
      moveOutDate,
      nickName: account.nickName,
      accountNumber: account.accountNumber,
      currentBalance: payment[0][OBJECT_VARS.finalBalance],
      finalBalance: payment[0][OBJECT_VARS.finalBalance],
    }
  })

const useMoveOutSummary = (addresses, moveOutDate) => {
  const queryClient = useQueryClient()
  const cachedSummary = useSelector(getSummary)
  const accounts = queryClient.getQueryData(['accounts'])
  const data = buildSummaryData(addresses, moveOutDate, accounts, queryClient)
  const summary = { data, moveOutDate }

  return { summary: cachedSummary || summary }
}

export { useMoveOutSummary }
