import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import dayjs from 'dayjs'
import { OBJECT_VARS } from '@/utils/models'
import { parseDollars } from '@/utils/utils'
import { useRecentTransactions } from '../queries/useRecentTransactions.queries'

const RecentTransWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const RecentTransItem = styled.div`
  padding: 2px 0;
`

const TransactionsList = ({ transactions }) => {
  return transactions.map((transaction) => {
    return (
      <RecentTransItem>
        Water Bill: {dayjs(transaction[OBJECT_VARS.createdDate]).format('MM/DD/YYYY')} -{' '}
        {parseDollars(transaction[OBJECT_VARS.amount])}
      </RecentTransItem>
    )
  })
}

TransactionsList.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const RecentTransactions = ({ id }) => {
  const { data, isLoading } = useRecentTransactions(id)
  if (isLoading) {
    return <Skeleton count={5} />
  }
  if (!data) {
    return 'Error'
  }
  if (data.length === 0) {
    return 'No recent transactions'
  }
  if (data.length > 0) {
    return (
      <RecentTransWrapper>
        <div>
          <TransactionsList transactions={data} />
        </div>
      </RecentTransWrapper>
    )
  }
  return null
}

RecentTransactions.propTypes = {
  id: PropTypes.string.isRequired,
}

export { RecentTransactions }
