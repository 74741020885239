import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import './styles.css'

const UsageBarChart = ({
  data,
  dateInfo,
  meterId,
  address,
  grouping,
  setGrouping,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  currentDate,
  setCurrentDate,
  defaultGroupingUpdate,
  setDefaultGroupingUpdate,
}) => {
  const [chartData, setChartData] = useState([])

  const chartContainerRef = useRef(null)

  useEffect(() => {
    const chart = am4core.create(chartContainerRef.current, am4charts.XYChart)
    chart.logo.disabled = true

    if (!data || data.length === 0 || typeof data[0].Date !== 'string') {
      chart.openModal('No Usage Data for the selected range. Please select a different date range.')
      return
    }

    setChartData(data)

    chart.data = chartData

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'Date'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 35
    categoryAxis.numberFormatter.numberFormat = '#'

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = 'Gallons'
    series.dataFields.categoryX = 'Date'
    series.name = 'Gallons'
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/] Gallons'
    series.columns.template.fillOpacity = 0.8
    series.columns.template.propertyFields.url = 'url'

    const columnTemplate = series.columns.template
    columnTemplate.strokeWidth = 2
    columnTemplate.strokeOpacity = 1

    series.columns.template.events.on('hit', function (ev) {
      const { dataItem } = ev.target

      const clickedDateValue = dataItem.dataContext.Date
      let newStartDate
      let newEndDate

      switch (grouping) {
        case '2':
          {
            const [monthName, year] = clickedDateValue.split(' ')
            const monthIndex = new Date(`${monthName} 01, ${year}`)
            setDefaultGroupingUpdate(false)
            newStartDate = new Date(monthIndex.getFullYear(), monthIndex.getMonth(), 1)
            newEndDate = new Date(monthIndex.getFullYear(), monthIndex.getMonth() + 1, 0)
            setCurrentDate(new Date(monthIndex))
            setGrouping('3')
          }
          break
        case '3': {
          setDefaultGroupingUpdate(false)
          const day = parseInt(clickedDateValue.match(/\d+/)[0], 10)
          setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, day))
          newStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
          newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth(), day + 1)
          setGrouping('4')
          setCurrentDate(newStartDate)
          break
        }
        default:
          break
      }

      if (newStartDate && newEndDate) {
        setStartDate(newStartDate)
        setEndDate(newEndDate)
      }
    })

    const title = chart.titles.create()
    title.text = `Meter Number: ${meterId} - ${address}`

    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.menu.items = [
      {
        label: 'Download',
        menu: [
          { type: 'csv', label: 'Export to CSV' },
          { type: 'xlsx', label: 'Export to Excel' },
        ],
      },
    ]
    chart.exporting.menu.align = 'right'
    chart.exporting.menu.verticalAlign = 'top'
    chart.exporting.title = `Meter Number: ${meterId} - ${address}`
    chart.exporting.filePrefix =
      grouping === '4'
        ? `Meter Number: ${meterId} - ${address} - ${
            dateInfo.getMonth() + 1
          }/${dateInfo.getDate()}/${dateInfo.getFullYear()}`
        : `Meter Number: ${meterId} - ${address} - ${
            dateInfo.getMonth() + 1
          }/${dateInfo.getFullYear()}`

    return () => {
      chart.dispose()
    }
  }, [chartData, data])

  return (
    <div>
      <div ref={chartContainerRef} style={{ width: '100%', height: '500px' }} />
      <div style={{ textAlign: 'center' }}>
        <p>
          This display shows your water use in monthly, daily, and hourly intervals. Any disruptions
          in transmission of data may be reported in a later interval once transmission resumes.
        </p>
      </div>
    </div>
  )
}

UsageBarChart.propTypes = {
  data: PropTypes.any.isRequired,
}

export { UsageBarChart }
