import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import './styles.scss'
import L from 'leaflet'
import { UsageGridCardComponent } from '@/components/common/UsageGridCardComponent'
import { useAccounts } from '@/hooks/useAccounts.queries'
import { usePayments } from '@/hooks/usePayments.queries'

const markerBlue = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

const markerRed = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

const markerGrey = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

const getMarker = (data) => {
  const { accountStatus } = data
  if (accountStatus === 'Current' || accountStatus === 'Pending') return markerBlue
  if (accountStatus === 'Past Due' || accountStatus === 'Delinquent') return markerRed
  if (!data?.status) return markerGrey
}

const MapMarker = ({ accountKey, position }) => {
  const { data } = usePayments([accountKey])
  if (!data) return null
  return (
    <Marker key={accountKey} position={position} icon={getMarker(data[0])}>
      <Popup>
        <UsageGridCardComponent accountKey={accountKey} />
      </Popup>
    </Marker>
  )
}
const RenderAddresses = () => {
  const { data } = useAccounts()
  if (!data) return null
  return data.map(({ accountKey, longitude, latitude }) => {
    return <MapMarker key={accountKey} accountKey={accountKey} position={[latitude, longitude]} />
  })
}
const positionMap = [29.4241, -98.4936]
const MapView = ({ mapHeight }) => {
  return (
    <>
      <MapContainer center={positionMap} zoom={12} style={{ height: mapHeight }}>
        <TileLayer
          url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=9346c9d1-5179-4782-921a-b6dcc6a2ce0d"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <RenderAddresses />
      </MapContainer>
    </>
  )
}

export { MapView }
