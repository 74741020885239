import React from 'react'
import { useQuery } from 'react-query'
import { fetchWrapper } from '@/redux/api'

const GET_ACCOUNT_ACTIVITY = `${process.env.REACT_APP_API_BASE_URL}/servicerequest-public-api/public/api/getaccountstatus/`

const fetchAccountActivity = (accountKey) => {
  const url = GET_ACCOUNT_ACTIVITY + accountKey
  return fetchWrapper(url, {
    method: 'GET',
    mode: 'cors',
    requiresInfor: true,
  })
}

const useAccountActivity = (accountKey, options) => {
  return useQuery(['accountActivity', accountKey], () => fetchAccountActivity(accountKey), {
    staleTime: 1000 * 60 * 20,
    ...options,
  })
}

export { useAccountActivity }
