import React, { useState } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import 'hammerjs'
import { DateRange } from 'react-date-range'
import { Button, Tab, Tabs, Typography } from '@material-ui/core'
import { Card } from '@progress/kendo-react-layout'
import { Grid, Popover } from '@mui/material'
import connectH2oLogo from '@/images/SAWS-ConnectH2O_800x145.png'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons'
import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { UsageBarChartControls } from './UsageBarChartControls'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const UsageContainer = styled.div`
  width: 100%;
  margin: auto;

  @media (min-width: 1500px) {
    width: 80%;
  }

  @media (max-width: 1499px) {
    width: 100%;
  }
`

const MyUsagePage = () => {
  const styles = {
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      color: 'white',
    },
    button: {
      backgroundColor: 'white',
      color: 'blue',
      textTransform: 'none',
      margin: '10px 0',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  }

  const container = {
    show: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  }

  const [tabValue, setTabValue] = useState('myUsage')

  const AccountTitle = ({ nickName, address }) => {
    return (
      <>
        <p style={{ marginBottom: 0, lineHeight: '35px' }}>{nickName}</p>
        <p style={{ marginBottom: 0, fontSize: '20px' }}>{address}</p>
      </>
    )
  }

  const history = useHistory()

  const location = useLocation()
  const {
    accountKey,
    meterId,
    x52id,
    billFromDate,
    billToDate,
    accountQuerySuccess,
    accountNickname,
    serviceAddress,
  } = location.state || {}
  const usageSinceDate = new Date(billToDate)
  usageSinceDate.setDate(usageSinceDate.getDate() + 1)

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const baseDate = new Date()
  baseDate.setUTCHours(0, 0, 0, 0)

  const [startDate, setStartDate] = React.useState(new Date(baseDate.getFullYear(), 0, 1))

  const [endDate, setEndDate] = React.useState(baseDate)

  const [currentDate, setCurrentDate] = useState(new Date())

  const [anchorEl, setAnchorEl] = useState(false)

  const open = Boolean(anchorEl)

  const id = open ? 'date-range-popover' : undefined

  const handleTabSelect = (e, value) => {
    setTabValue(value)
    if (value === 'myUsage') {
      history.push('/myUsagePage', {
        accountKey,
        meterId,
        x52id,
        billFromDate,
        billToDate,
        accountQuerySuccess,
        accountNickname,
        serviceAddress,
      })
    }
  }

  const handleLastBillClick = () => {
    setStartDate(billFromDate)
    setEndDate(billToDate)
    setCurrentDate(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))
  }

  const handleUsageSinceLastBillClick = () => {
    setStartDate(usageSinceDate)
    setEndDate(new Date())
    setCurrentDate(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))
  }

  const handleRangeClick = () => {
    setAnchorEl(true)
  }

  const handleClose = () => {
    setAnchorEl(false)
  }

  const handleSelect = (ranges) => {
    if (
      !ranges.selection.endDate ||
      ranges.selection.startDate.getTime() === ranges.selection.endDate.getTime()
    ) {
      const updatedStartDate = new Date(ranges.selection.startDate)
      updatedStartDate.setDate(updatedStartDate.getDate() - 1)

      setSelectionRange({
        ...ranges.selection,
        startDate: updatedStartDate,
        endDate: ranges.selection.startDate,
      })
      setCurrentDate(ranges.selection.endDate)
      setStartDate(updatedStartDate)
      setEndDate(ranges.selection.endDate)
    } else {
      setSelectionRange(ranges.selection)
      setCurrentDate(ranges.selection.endDate)
      setStartDate(ranges.selection.startDate)
      setEndDate(ranges.selection.endDate)
    }
  }

  const UsageDateRangePicker = () => {
    return (
      <>
        <Button style={styles.button} aria-describedby={id} onClick={handleRangeClick}>
          <Typography variant="subtitle2">{`${
            startDate.getMonth() + 1
          }${'/'}${startDate.getDate()}${'/'}${startDate.getFullYear()} - ${
            endDate.getMonth() + 1
          }${'/'}${endDate.getDate()}${'/'}${endDate.getFullYear()}`}</Typography>{' '}
        </Button>
        {open && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', hoirzontal: 'left' }}
            onClose={handleClose}
          >
            <DateRange
              ranges={[selectionRange]}
              onChange={handleSelect}
              className="calendarElement"
              moveRangeOnFirstSelection={false}
              maxDate={new Date()}
            />
          </Popover>
        )}

        <Typography>Specific Date</Typography>
      </>
    )
  }

  return (
    <UsageContainer>
      <div className="row mt-3">
        <div className="col-12" style={{ marginBottom: '20px' }}>
          <Typography variant="h4">
            <AccountTitle nickName={accountNickname} address={serviceAddress} />
          </Typography>
        </div>
        <div className="row" style={{ paddingLeft: '11px' }}>
          <Tabs
            style={{ marginBottom: '5px' }}
            TabIndicatorProps={{ style: { background: 'blue' } }}
            value={tabValue}
            onChange={handleTabSelect}
          >
            <Tab
              style={{
                backgroundColor: 'rgba(103, 183, 220, 0.2)',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
                marginRight: '4px',
              }}
              value="myBill"
              label={<Link to={`/history/${accountKey}`}>My Bill</Link>}
            />
            <Tab
              style={{
                backgroundColor: 'rgba(103, 183, 220, 0.2)',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
              }}
              value="myUsage"
              label={<Link to="/myUsagePage">My Water Use</Link>}
            />
          </Tabs>
        </div>
      </div>
      <div className="row" style={{ width: '100%' }}>
        <div className="col-md-3">
          <motion.div
            initial="hidden"
            animate="show"
            variants={container}
            style={{ height: '200%', paddingLeft: '10px' }}
          >
            <Card
              style={{
                background: 'linear-gradient(180deg, #0279af 0%, #5591c8 100%)',
                display: 'flex',

                textAlign: 'center',

                width: '100%',
              }}
            >
              <Grid
                container
                xs={12}
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <Grid item style={styles.gridItem}>
                  <FontAwesomeIcon size="5x" icon={faCalendarAlt} style={{ border: 'none' }} />
                  <UsageDateRangePicker />
                </Grid>
                <hr style={{ border: '1px solid white', width: '80%' }} />

                <Grid item style={styles.gridItem}>
                  <FontAwesomeIcon size="5x" icon={faFileInvoiceDollar} />

                  <Button style={styles.button} onClick={handleLastBillClick}>
                    <Typography variant="subtitle2">Last Bill Water Use</Typography>
                  </Button>

                  <Typography>{`${
                    billFromDate.getMonth() + 1
                  }${'/'}${billFromDate.getDate()}${'/'}${billFromDate.getFullYear()} - ${
                    billToDate.getMonth() + 1
                  }${'/'}${billToDate.getDate()}${'/'}${billToDate.getFullYear()}`}</Typography>
                </Grid>
                <hr style={{ border: '1px solid white', width: '80%' }} />
                <Grid item style={styles.gridItem}>
                  <FontAwesomeIcon size="5x" icon={faChartColumn} />

                  <Button style={styles.button} onClick={handleUsageSinceLastBillClick}>
                    <Typography variant="subtitle2">Water Use Since Last Bill</Typography>
                  </Button>

                  <Typography>{`${
                    usageSinceDate.getMonth() + 1
                  }${'/'}${usageSinceDate.getDate()}${'/'}${usageSinceDate.getFullYear()} - ${
                    new Date().getMonth() + 1
                  }${'/'}${new Date().getDate()}${'/'}${new Date().getFullYear()}`}</Typography>
                </Grid>
              </Grid>
            </Card>
            <Grid container xs={12} justifyContent="center" direction="column" alignItems="center">
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <img
                  src={connectH2oLogo}
                  alt="SAWS ConnectH2O Logo"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '80%',
                    height: 'auto',
                    marginLeft: '40px',
                  }}
                />
                <Grid item xs={12}>
                  <Link to='"https://www.saws.org/connecth2o/'>
                    <Button
                      style={{
                        background: '#0078ae',
                        border: 'none',
                        color: '#fff',
                        minWidth: '196px',
                        fontWeight: '500',
                        justifySelf: 'left',
                        width: '75%',
                        textAlign: 'center',
                        margin: '20px auto',
                        display: 'flex',
                        fontSize: '14px',
                      }}
                    >
                      Find Out More
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </motion.div>
        </div>
        <div className="col-md-9" style={{ paddingLeft: '50px' }}>
          <UsageBarChartControls
            meterId={meterId}
            x52id={x52id}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            serviceAddress={serviceAddress}
          />
        </div>
      </div>
    </UsageContainer>
  )
}

export default MyUsagePage
