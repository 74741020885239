import React from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import dayjsBusinessDays from 'dayjs-business-days'
import { isHoliday } from '@/utils/holidays'
import selectors from '@/redux/selectors'
import { Input, Label } from '@/components/common/Input'
import { ICONS } from '@/utils/icons'
import { ScheduleComponent } from '../../Payment/ScheduleComponent'
import { useServiceRequestCount } from '../useServiceRequestCount'

const DateSelectInput = ({ onClick, ...rest }) => {
  return (
    <>
      <Label label="move out date" variant="light" fontSize="12px" />
      <Input icon={ICONS.user} bottomGutter={0} onClick={onClick} {...rest} />
    </>
  )
}

const MoveOutScheduleComponent = ({ moveOutDate, setMoveOutDate }) => {
  dayjs.extend(dayjsBusinessDays)
  const today = dayjs()
  const setSelectedMoveOutDate = (date) => setMoveOutDate(dayjs(date).format('YYYY-MM-DD'))
  const isWeekdayAndNotHoliday = (date) => {
    return dayjs(date).isBusinessDay() && !isHoliday(date)
  }
  const numAccountsToMoveOut = useSelector(
    (state) => selectors.account.getSelectedAccounts(state).length
  )
  const serviceRequestCountQuery = useServiceRequestCount()
  const serviceRequestCount = !serviceRequestCountQuery.isLoading && serviceRequestCountQuery.data

  const getExcludedDays = () => {
    if (serviceRequestCount.length > 0) {
      const viables = serviceRequestCount.filter((day) => day.count + numAccountsToMoveOut > 500)
      return viables.map((el) => Date.parse(el.day))
    }
    return []
  }

  const selectedMoveOutDate =
    moveOutDate ||
    (serviceRequestCount && getExcludedDays[0]) ||
    dayjs(dayjs(today).businessDaysAdd(5).toDate()).format()

  React.useEffect(() => {
    if (moveOutDate !== null) return undefined
    setMoveOutDate(dayjs(selectedMoveOutDate).format('YYYY-MM-DD'))
  }, [])

  return (
    <ScheduleComponent
      title="Schedule a Move Out"
      popperPlacement="bottom"
      filterDate={isWeekdayAndNotHoliday}
      excludeDates={getExcludedDays()}
      selectedDay={dayjs(selectedMoveOutDate).toDate()}
      minDate={dayjs(today).businessDaysAdd(5).toDate()}
      maxDate={dayjs(today).businessDaysAdd(30).toDate()}
      setSelectedDay={setSelectedMoveOutDate}
      customInput={<DateSelectInput />}
    />
  )
}

export { MoveOutScheduleComponent }
