import React from 'react'

const DEFAULT = 'DEFAULT'
const CUSTOM = 'CUSTOM'
const DISABLED = 'DISABLED'

const initializeState = (keys, value) =>
  keys.length === 0
    ? null
    : keys.reduce(
        (res, key) => ({
          ...res,
          [key]: value,
        }),
        {}
      )

const useCheck = (keys = []) => {
  const [state, setState] = React.useState(() => initializeState(keys, DEFAULT))
  const [isUsingSingleAddress, setIsUsingSingleAddress] = React.useState(false)
  const setDefault = (key) => setState((prevState) => ({ ...prevState, [key]: DEFAULT }))
  const setCustom = (key) => setState((prevState) => ({ ...prevState, [key]: CUSTOM }))
  const setAllDefault = () => setState(initializeState(keys, DEFAULT))
  const setAllDisabled = () => setState(initializeState(keys, DISABLED))
  const isDirty = Object.values(state).includes(CUSTOM)
  const setUseSingleAddress = () => {
    if (isUsingSingleAddress) {
      setIsUsingSingleAddress(false)
      return setAllDefault()
    }
    setIsUsingSingleAddress(true)
    return setAllDisabled()
  }

  return {
    checkState: state,
    isUsingSingleAddress,
    isDirty,
    checkActions: { setDefault, setAllDefault, setCustom, setAllDisabled, setUseSingleAddress },
  }
}

export { useCheck }
