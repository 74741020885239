import { all } from 'redux-saga/effects'
import { watchResetLoaders } from '@/redux/ducks/uiDuck'
import { watchPayment } from '@/redux/ducks/paymentDuck'
import { watchAccounts } from '@/redux/ducks/accountDuck'
import { watchAuth } from '@/redux/ducks/authDuck'
import { watchInitialization } from './sagas/initializationSaga'

function* rootSaga() {
  try {
    yield all([
      watchAuth(),
      watchInitialization(),
      watchAccounts(),
      watchPayment(),
      watchResetLoaders(),
    ])
  } catch (e) {
    yield console.log(e)
  }
}

export { rootSaga }
