import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import TagManager from 'react-gtm-module'
import { useIdleTimer } from 'react-idle-timer'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@progress/kendo-theme-material/dist/all.css'
import './styles/master.scss'
import { useSelector, useDispatch } from 'react-redux'
import { Footer, Header, Logo, Menu } from '@/components/layout'
import selectors from '@/redux/selectors'
import { useAuthApi, useUiApi } from '@/redux/reducers'
import { getUserId } from '@/redux/ducks/commonDuck'
import { Toaster } from 'react-hot-toast'
import { loadFromLocalStorage, saveToLocalStorage } from '@/token'
import { useQueryClient } from 'react-query'
import { PageRouter } from './PageRouter'
import { fetchRefreshToken } from './redux/api'
import SessionTimeout from './SetTimeout'
import 'react-loading-skeleton/dist/skeleton.css'

const tagManagerArgs = {
  gtmId: 'G-0W71GGQQ0X',
}
TagManager.initialize(tagManagerArgs)

const authLayout = () => `
'nav header header'
'nav page page'
'nav page page'
'footer footer footer'
`

const noAuthLayout = () => `
'nav page page'
'nav page page'
'nav page page'
'footer footer footer'
`
const ParentContainer = styled.div`
  display: grid;
  grid-template-columns: 209px 0fr;
  grid-template-rows: ${({ isAuth }) => (isAuth ? '65px 1fr 250px' : '1fr 250px')};
  min-height: 100vh;
  min-width: max-content;
  width: 100%;
  grid-template-areas: ${({ isAuth }) => (isAuth ? authLayout : noAuthLayout)};
`
const NavContainer = styled.div`
  grid-area: nav;
  display: flex;
  flex-direction: column;
  min-height: 600px;
`

const HeaderContainer = styled.div`
  display: ${({ isAuth }) => !isAuth && 'none'};
  grid-area: header;
`

const PageContainer = styled.div`
  grid-area: page;
  background: #f4f4f4;
  min-height: 100%;
`
const FooterContainer = styled.div`
  grid-area: footer;
`

const App = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const isAuth = useSelector((state) => selectors.auth.getInitIsComplete(state))
  const x52Id = useSelector(getUserId)
  const getToken = () => {
    const token = loadFromLocalStorage('token')
    if (!token) return null
    return token
  }

  const suggestedRefreshTime = () => isAuth && dayjs(getToken().tokenExpiration).subtract(5, 'm')
  const expirationTime = () => isAuth && getToken() && dayjs(getToken().tokenExpiration).valueOf()
  const username = useSelector((state) => selectors.auth.getUsername(state))
  const RemoveQueries = () => {
    queryClient.removeQueries()
  }

  const userLoggedIn = isAuth && !dayjs().isAfter(dayjs(expirationTime()))

  const handleOnIdle = () => {
    if (!isAuth) {
      RemoveQueries()
      return undefined
    }
    if (isAuth && dayjs().isAfter(dayjs(expirationTime()))) {
      dispatch(useAuthApi.logout())
      RemoveQueries()
    }
  }

  const handleOnActive = () => {
    if (!isAuth || getToken().isImpersonating) return undefined
    if (dayjs().isAfter(dayjs(suggestedRefreshTime()))) {
      fetchRefreshToken(x52Id).then((newToken) => {
        saveToLocalStorage('token', newToken)
      })
    }
  }

  const handleClick = () => {
    dispatch(useAuthApi.logout())
    RemoveQueries()
    // setAuth(!isAuthenticated);
  }

  useIdleTimer({
    timeout: 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  })

  React.useEffect(() => {
    dispatch(useUiApi.resetLoaders())
  }, [])

  window.dataLayer.push({
    event: 'pageview',
  })

  return (
    <ParentContainer isAuth={userLoggedIn}>
      <SessionTimeout isAuthenticated={userLoggedIn} logOut={handleClick} />
      <div>
        <Toaster />
      </div>
      <NavContainer>
        <Logo />
        <Menu type="desktop" isAuth={userLoggedIn} />
      </NavContainer>
      <HeaderContainer isAuth={userLoggedIn}>
        {userLoggedIn && <Header isAuth={userLoggedIn} />}
      </HeaderContainer>
      <PageContainer isAuth={userLoggedIn}>
        <PageRouter isAuth={userLoggedIn} />
      </PageContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </ParentContainer>
  )
}

export { App }
