import React from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'
import { MoveOutOverwriteAlert } from '../base/alert/MoveOutOverwriteAlert.component'

const SameAddressCheckboxWrapper = styled.div`
  background: #3250b3;
  min-height: 55px;
  margin-bottom: 10px;
  padding: 13px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;

  & label {
    margin-left: 5px;
  }

  & input[type='checkbox'] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    height: 18px;
  }
`

const MoveOutSameAddressCheckbox = ({ useCheck }) => {
  const { isUsingSingleAddress, isDirty, checkActions } = useCheck
  const [overwriteAlertTriggered, setOverwriteAlertTriggered] = React.useState(false)
  const [willShowOverwriteAlert, setWillShowOverwriteAlert] = React.useState(false)

  const proceedWithCheckAll = () => {
    setOverwriteAlertTriggered(false)
    setWillShowOverwriteAlert(false)
    checkActions.setUseSingleAddress()
  }

  const handleShowOverwriteAlert = () => {
    if (willShowOverwriteAlert) return setOverwriteAlertTriggered(true)
    proceedWithCheckAll()
  }

  React.useEffect(() => {
    // If any check value is 'CUSTOM', the alert will trigger
    setWillShowOverwriteAlert(isDirty)
  }, [isDirty])

  return (
    <SameAddressCheckboxWrapper>
      <Form.Check
        type="checkbox"
        label="Use same final billing address for all accounts?"
        checked={isUsingSingleAddress}
        onClick={handleShowOverwriteAlert}
      />
      {overwriteAlertTriggered && (
        <MoveOutOverwriteAlert
          cancel={() => setOverwriteAlertTriggered(false)}
          proceed={proceedWithCheckAll}
        />
      )}
    </SameAddressCheckboxWrapper>
  )
}

export { MoveOutSameAddressCheckbox }
