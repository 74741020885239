import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import Skeleton from 'react-loading-skeleton'
import { parseDollars } from '@/utils/utils'
import { getBillLineItems } from '@/redux/api'

const BillHighlightsContainer = styled.div`
  padding: 15px;
  margin: 15px 0 0 0;
`

const BillHighlightsHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
`

const BillHighlightsList = styled.div`
  margin-top: 15px;
  font-size: 14px;
`

const BillHighlightsItemWrapper = styled.div`
  border-bottom: 1px solid darkgrey;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const BillHighlightsItemText = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
`

const BillHighlightsItemAmount = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

const BillHighlightsTotalWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
`

const BillHighlightsTotalText = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
`

const BillHighlightsTotalAmount = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`
const BillHighlightsNote = styled.div`
  font-size: 12px;
`

const BillHighlightsItem = ({ text, amount }) => {
  return (
    <BillHighlightsItemWrapper>
      <BillHighlightsItemText>{text}</BillHighlightsItemText>
      <BillHighlightsItemAmount>{parseDollars(amount)}</BillHighlightsItemAmount>
    </BillHighlightsItemWrapper>
  )
}

const BillHighlightsComponent = ({ accountKey, billKey }) => {
  const [Items, SetItems] = useState(null)

  useEffect(() => {
    if (accountKey === null || billKey === null) return undefined
    getBillLineItems(accountKey, billKey)
      .then((res) => {
        SetItems(res)
      })
      .catch((ex) => {
        console.warn(ex)
        SetItems([])
      })
  }, [billKey])

  const [billTotalAmount, SetBillTotalAmount] = useState(null)

  useEffect(() => {
    if (Items === null) return undefined
    const total = Items.reduce((t, item) => t + item[OBJECT_VARS.chargeAmount], 0)
    const dol = parseDollars(total)
    SetBillTotalAmount(dol)
  }, [Items])

  return (
    <BillHighlightsContainer>
      <BillHighlightsHeader>Current Bill Highlights</BillHighlightsHeader>
      <BillHighlightsList>
        {Items ? (
          Items.map((item) => {
            return (
              <BillHighlightsItem
                key={item[OBJECT_VARS.billTextLineItem]}
                text={item[OBJECT_VARS.billTextLineItem]}
                amount={item[OBJECT_VARS.chargeAmount]}
              />
            )
          })
        ) : (
          <Skeleton count={6} />
        )}
      </BillHighlightsList>
      <BillHighlightsTotalWrapper>
        <BillHighlightsTotalText>Total</BillHighlightsTotalText>
        <BillHighlightsTotalAmount>{billTotalAmount || <Skeleton />}</BillHighlightsTotalAmount>
      </BillHighlightsTotalWrapper>
      <BillHighlightsNote>
        *Any unpaid previous balance is due NOW. View current bill for details. For complete list of
        charges and credits, view your current bill.
      </BillHighlightsNote>
    </BillHighlightsContainer>
  )
}

export { BillHighlightsComponent }
