import React from 'react'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'

import Skeleton from 'react-loading-skeleton'
import { PropTypes } from 'prop-types'
import { useQueryClient } from 'react-query'
import { Button } from '@progress/kendo-react-buttons'
import { useExpireNotification } from './useExpireNotification.queries'

const Gradient = styled.div`
  border-radius: 5px;
  height: inherit;
`

const MessageContentWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
`

const MessageTitle = styled.div`
  font-size: 22px;
`

const MessageDate = styled.div``

const MessageText = styled.div`
  background-color: white;
  color: black;
  max-width: 700px;
  margin-top: 5px;

  margin: 5px auto 0 auto;
`

const MCButtonsCont = styled.div`
  margin: 20px auto 20px auto;
  text-align: center;
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
`

const SkeletonMessage = () => {
  return (
    <div className="card card-shadow">
      <MessageTitle>
        <Skeleton />
      </MessageTitle>
      <MessageDate>
        <Skeleton width={120} />
      </MessageDate>
      <MessageText>
        <Skeleton count={3} />
      </MessageText>
    </div>
  )
}

const MessageDisplay = ({ title, date, content, bodycontent }) => {
  return (
    <div id="mc_bodycontent">
      <div dangerouslySetInnerHTML={{ __html: bodycontent }} />
    </div>
  )
}

const NotificationContentComponent = ({
  isLoading,
  notification,
  animateOut,
  selectNotification,
}) => {
  const queryClient = useQueryClient()
  const expireMutation = useExpireNotification({
    onMutate: async () => {
      await queryClient.cancelQueries('notifications')
      const previousNotifications = queryClient.getQueryData('notifications')
      queryClient.setQueryData(
        'notifications',
        previousNotifications.filter(
          (el) => el[OBJECT_VARS.messageId] !== notification[OBJECT_VARS.messageId]
        )
      )
      animateOut(true)
      selectNotification(null)
      return { previousNotifications }
    },
    onError: (err, variables, previousValue) => {
      queryClient.setQueryData('notifications', previousValue)
      animateOut(false)
      selectNotification(notification[OBJECT_VARS.messageId])
    },
    onSettled: () => {
      queryClient.invalidateQueries('notifications')
    },
  })

  const id = React.useMemo(
    () => notification && notification[OBJECT_VARS.messageId],
    [notification]
  )
  const title = notification && notification[OBJECT_VARS.title]
  const date = notification && notification[OBJECT_VARS.sentDate]
  const content = notification && notification[OBJECT_VARS.preface]
  const bodycontent = notification && notification[OBJECT_VARS.body]
  const isExpired = notification && notification[OBJECT_VARS.expirationDate] !== null

  return (
    <div className="card card-shadow p-3 mt-5 mb-5">
      <MessageContentWrapper>
        <Gradient>
          {isLoading ? (
            <SkeletonMessage />
          ) : (
            <MessageDisplay title={title} date={date} content={content} bodycontent={bodycontent} />
          )}
        </Gradient>
      </MessageContentWrapper>
      <MCButtonsCont>
        {!isExpired ? (
          <Button className="btn-myc-red" onClick={() => expireMutation.mutate(id)}>
            {expireMutation.isLoading ? 'deleting' : 'delete'}
          </Button>
        ) : null}
      </MCButtonsCont>
    </div>
  )
}

NotificationContentComponent.defaultProps = {
  isLoading: false,
}

MessageDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  bodycontent: PropTypes.string.isRequired,
}

NotificationContentComponent.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  animateOut: PropTypes.func.isRequired,
  selectNotification: PropTypes.func.isRequired,
}

export { NotificationContentComponent }
