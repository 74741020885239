import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import { parseDollars } from '@/utils/utils'
import { useAccounts } from '@/hooks/useAccounts.queries'

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #000;
`

const Details = styled.div`
  font-family: Roboto;
  font-size: 23px;
  padding: 25px;
`

const Value = styled.p`
  font-weight: 100;
  margin: 0;
`

const Subtitle = styled.p`
  margin: 0 0 -6px 0;
  font-weight: 500;
  font-size: 22px;
  color: #4f4f4f;
`

const AccountRow = ({ paymentSummary }) => {
  const accountKey = paymentSummary[OBJECT_VARS.accountKey]
  const accountQuery = useAccounts({
    select: (res) => res.find((el) => el[OBJECT_VARS.accountKey] === accountKey),
  })
  const account = accountQuery.isSuccess && accountQuery.data
  const address = `${account[OBJECT_VARS.serviceAddress]} ${account[OBJECT_VARS.subDesignation]}`
  const accountNumber = paymentSummary[OBJECT_VARS.accountNumber]
  const amountDue = paymentSummary.owed
  const nickname = account[OBJECT_VARS.nickName]
  const amountPaid = paymentSummary.amount

  return (
    <Row>
      <Details>
        <Subtitle>Account Number:</Subtitle>
        <Value
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '150px',
            overflow: 'hidden',
          }}
        >
          {accountNumber}
        </Value>
      </Details>
      <Details>
        <Subtitle>Nickname:</Subtitle>
        <Value>{nickname}</Value>
      </Details>
      <Details>
        <Subtitle>Address:</Subtitle>
        <Value>{address}</Value>
      </Details>
      <Details>
        <Subtitle>Amount Owed:</Subtitle>
        <Value style={{ width: 'fit-content' }}>{parseDollars(amountDue)}</Value>
      </Details>
      <Details>
        <Subtitle>Amount Paid:</Subtitle>
        <Value>{parseDollars(amountPaid)}</Value>
      </Details>
    </Row>
  )
}

AccountRow.propTypes = {
  account: PropTypes.shape({}).isRequired,
}
export { AccountRow }
