import React from 'react'
import styled from 'styled-components'
import { ICONS } from '@/utils/icons'
import { IconComponent } from '@/components/common/IconComponent'

const StyledBAIcon = styled(IconComponent)`
  color: #5691c8;
  font-size: 80px;
  margin: 0 0 10px 0;
`

const BAIconComponent = ({ type }) => {
  return type === 'C' ? (
    <StyledBAIcon icon={ICONS.creditCard} />
  ) : (
    <StyledBAIcon icon={ICONS.piggyBank} />
  )
}

export { BAIconComponent }
