import React from 'react'
import _ from 'lodash'

const checkIfTouched = (form, defaultValues) => {
  return !_.isEqual(form, defaultValues)
}

const autoCheck = (isTouched, key, setCustom, setDefault) =>
  isTouched() ? setCustom(key) : setDefault(key)

const useCheckboxFormConnector = (selected, useBillingAddresses, useCheck) => {
  const { addresses, defaultValues, resetToDefaultAddress } = useBillingAddresses
  const { isUsingSingleAddress, checkActions, checkState } = useCheck
  const { setCustom, setDefault } = checkActions
  const isTouched = () => checkIfTouched(addresses[selected], defaultValues[selected])

  // isUsingSingleAddress sets all checkState to DISABLED
  React.useEffect(() => {
    if (isUsingSingleAddress || !addresses || !defaultValues) return undefined
    autoCheck(isTouched, selected, setCustom, setDefault)
  }, [addresses])

  React.useEffect(() => {
    if (isUsingSingleAddress || !addresses || !defaultValues) return undefined
    if (checkState[selected] === 'DEFAULT' && isTouched()) {
      resetToDefaultAddress(selected)
    }
  }, [checkState])
}

export { useCheckboxFormConnector }
