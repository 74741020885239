import React from 'react'
import styled from 'styled-components'
import contactBG from '@/images/Background-4.jpg'
import { ICONS } from '@/utils/icons'
import { IconComponent } from '@/components/common/IconComponent'
import DropdownContact from './DropdownContact'

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background: url(${contactBG});
  background-size: cover;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  min-height: 470px;
  position: relative;
  font-weight: 600;
  overflow: hidden;
  background-position: 50% 0;
  margin: 20px;
  min-height: 495px;
`

const ContactHeaderContainer = styled.div`
  background-color: rgba(36, 110, 185, 0.75);
  padding: 25px;
  font-weight: 600;
  font-size: 25px;
`

const ContactHeaderText = styled.div``

const ContactFooterButton = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  padding: 15px 0;
  cursor: pointer;
  z-index: 3;
  width: 100%;
  color: #fff;
  text-align: center;
  background: linear-gradient(to bottom, #0078ae, #0070a3);
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  text-transform: capitalize;
`

const StyledContactIcon = styled(IconComponent)`
  font-size: 60px;
  margin: 0 auto;
`
const ContactIcon = () => {
  return <StyledContactIcon icon={ICONS.phoneAlt} />
}

const ContactInfoDisplay = () => {
  const [isOpenContact, setIsOpenContact] = React.useState(false)
  return (
    <ContactBox>
      <ContactHeaderContainer>
        <ContactIcon />
        <ContactHeaderText>Contact Us</ContactHeaderText>
      </ContactHeaderContainer>
      <DropdownContact open={isOpenContact} />
      <ContactFooterButton onClick={() => setIsOpenContact(!isOpenContact)}>
        VIEW OUR MAILING ADDRESS AND PHONE NUMBER
      </ContactFooterButton>
    </ContactBox>
  )
}

export default ContactInfoDisplay
