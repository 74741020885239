import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { OBJECT_VARS } from '@/utils/models'
import { BAIconComponent } from '../BAIconComponent'
import { DeletePaymentMethodButton } from './DeletePaymentMethod.component'
import { RecentTransactions } from './RecentTransactions.component'
import { usePaymentMethods } from '../queries/usePaymentMethods.queries'

const BADetailsIcon = styled.div``
const BADetailsType = styled.div``
const BADetailsAccountNum = styled.div``
const AccountInfoHeader = styled.div`
  font-size: 18px;
  margin: 25px 0 5px 0;
  font-weight: bold;
`
const BAMainInfoWrapper = styled.div``
const NameOnAccount = styled.div``
const AccountNumber = styled.div``
const RoutingNumber = styled.div``
const Address = styled.div``
const Divider = styled.div``
const RecentTransHeader = styled.div`
  font-size: 18px;
  margin: 25px 0 5px 0;
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin: 0 auto;
`

const Clear = styled.div`
  clear: both;
`

const RecentTransList = styled.div`
  overflow: auto;
  max-height: 370px;
`

const BankAccountDetailsComponent = ({ selectedPaymentMethodId }) => {
  const selectedPaymentMethodQuery = usePaymentMethods({
    select: (methods) => methods.find((method) => method.id === selectedPaymentMethodId),
  })

  const selectedPaymentMethod = selectedPaymentMethodQuery.data

  return selectedPaymentMethod ? (
    <div className="row">
      <div className="col-12 text-center card card-shadow p-4">
        <BADetailsIcon>
          <BAIconComponent type={selectedPaymentMethod[OBJECT_VARS.accountType]} />
        </BADetailsIcon>
        <BADetailsType>
          (
          {selectedPaymentMethod[OBJECT_VARS.nickName] === null
            ? selectedPaymentMethod[OBJECT_VARS.issuingBank]
            : selectedPaymentMethod[OBJECT_VARS.nickName]}
          )
        </BADetailsType>
        <BADetailsAccountNum>
          (************{selectedPaymentMethod[OBJECT_VARS.last4digits]})
        </BADetailsAccountNum>
        <AccountInfoHeader>Bank Account Info</AccountInfoHeader>
        <BAMainInfoWrapper>
          <NameOnAccount>
            Name on Account: {selectedPaymentMethod[OBJECT_VARS.nameOnAccount]}
          </NameOnAccount>
          <AccountNumber>
            Account Number: ************{selectedPaymentMethod[OBJECT_VARS.last4digits]}
          </AccountNumber>
          <RoutingNumber>
            Routing Number: {selectedPaymentMethod[OBJECT_VARS.routingNumber]}
          </RoutingNumber>
          <Address>Address: {selectedPaymentMethod[OBJECT_VARS.address] || 'N/A'}</Address>
        </BAMainInfoWrapper>
        <Divider />
        <RecentTransHeader>Recent Transactions</RecentTransHeader>
        <RecentTransList>
          <RecentTransactions id={selectedPaymentMethodId} />
        </RecentTransList>
        <ButtonsWrapper>
          <DeletePaymentMethodButton id={selectedPaymentMethodId} />
          <Clear />
        </ButtonsWrapper>
      </div>
    </div>
  ) : null
}

BankAccountDetailsComponent.propTypes = {
  selectedPaymentMethodId: PropTypes.string.isRequired,
}

export { BankAccountDetailsComponent }
