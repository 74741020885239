import React from 'react'
import styled from 'styled-components'
import { OBJECT_VARS } from '@/utils/models'
import { PropTypes } from 'prop-types'
import { ExpiredNotificationsList } from './ExpiredNotificationsList.component'
import { LoadingAnimation } from './LoadingAnimation'
import { InboxNotificationsList } from './InboxNotificationsList.component'

const Wrapper = styled.div`
  width: 100%;
  float: left;
  height: 68vh;
  overflow-y: scroll;
`

const NotificationsView = ({ view, select, selected, data, isLoading, isError, animate }) => {
  const expiredNotifications = data?.filter(
    (notification) => notification[OBJECT_VARS.expirationDate] !== null
  )
  const freshNotifications = data?.filter(
    (notification) => notification[OBJECT_VARS.expirationDate] === null
  )

  return (
    <Wrapper>
      {isLoading && <LoadingAnimation />}
      {isError && 'An error has occured'}
      {data && view === 'DELETED' && (
        <ExpiredNotificationsList
          select={select}
          selected={selected}
          notifications={expiredNotifications}
          animate={animate}
        />
      )}
      {data && view === 'INBOX' && (
        <InboxNotificationsList
          select={select}
          selected={selected}
          notifications={freshNotifications}
          animate={animate}
        />
      )}
    </Wrapper>
  )
}

NotificationsView.defaultProps = {
  selected: null,
  data: null,
  isLoading: true,
  isError: false,
}
NotificationsView.propTypes = {
  view: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  selected: PropTypes.object,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  animate: PropTypes.shape({
    inValue: PropTypes.bool,
    setInValue: PropTypes.func,
  }).isRequired,
}

export { NotificationsView }
