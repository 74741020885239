import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import ContactUsForm from './ContactUsForm.component'
import ChatBox from './ChatBox.component'
import ContactInfoDisplay from './ContactInfoDisplay.component'

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const ContactUs = () => {
  return (
    <>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay="500" isVisible>
        <ContactWrapper>
          <ChatBox />
          <ContactInfoDisplay />
        </ContactWrapper>
        <ContactUsForm />
      </Animated>
    </>
  )
}

export default ContactUs
