import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper, validateRoutingNumber } from '@/redux/api'
import { OBJECT_VARS } from '@/utils/models'
import { useSelector } from 'react-redux'
import { getUserId, getUsername } from '@/redux/ducks/commonDuck'
import { toast } from 'react-hot-toast'

const transformPaymentMethod = (paymentMethod) => {
  dayjs.extend(utc)
  const today = dayjs.utc().format()

  return {
    nameOnAccount: paymentMethod[OBJECT_VARS.nameOnAccount],
    nickName: paymentMethod[OBJECT_VARS.nickName],
    address: paymentMethod[OBJECT_VARS.address],
    routingNumber: paymentMethod[OBJECT_VARS.routingNumber],
    accountNumber: paymentMethod[OBJECT_VARS.accountNumber],
    bankAccountNumber: paymentMethod[OBJECT_VARS.accountNumber],
    accountType: paymentMethod[OBJECT_VARS.accountType],
    appId: Number.parseInt(process.env.REACT_APP_APP_ID),
    x52Id: paymentMethod.x52Id,
    addedDate: today,
    addedBy: paymentMethod.username,
    last4Digits: paymentMethod[OBJECT_VARS.accountNumber].slice(-4),
    issuingBank: paymentMethod[OBJECT_VARS.issuingBank],
  }
}

const ADD_PAYMENT_METHOD = `${process.env.REACT_APP_API_BASE_URL}/wallet-public-api/public/api/addpaymentmethod`

const addPaymentMethod = async (req) => {
  return fetchWrapper(ADD_PAYMENT_METHOD, {
    method: 'POST',
    mode: 'cors',
    requiresInfor: false,
    body: JSON.stringify(req),
  })
}

const useAddPaymentMethod = (options) => {
  const queryClient = useQueryClient()
  const x52Id = useSelector(getUserId)
  const username = useSelector(getUsername)

  return useMutation(
    async (newPaymentMethod) => {
      const res = await validateRoutingNumber(newPaymentMethod.routingNumber)
      const result = await res.json()
      if (!result) throw new Error('Routing number not found.')
      const reqBody = transformPaymentMethod({
        ...newPaymentMethod,
        x52Id,
        username,
        issuingBank: result.bankName,
      })
      return toast.promise(
        addPaymentMethod(reqBody),
        {
          loading: 'One moment. Please do not refresh the page.',
          success: () => (
            <>
              <div style={{ textAlign: 'center' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <b>Success!</b>
                </div>
              </div>
            </>
          ),
          error: (error) => error.resultDescription,
        },
        {
          style: { border: 'black', minWidth: '350px' },
          success: {
            style: { background: 'green', color: '#ffffff', minWidth: '350px' },
            duration: 8000,
          },
          error: {
            style: { background: 'red', color: '#ffffff', minWidth: '350px' },
            duration: 8000,
          },
        }
      )
    },
    {
      ...options,
      onSettled: () => {
        queryClient.refetchQueries('paymentMethods')
      },
    }
  )
}

export { useAddPaymentMethod }
